// src/components/ProfilePage.js

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../context/AuthContext';
import { getUserDetails, updateUser, deleteUser } from '../services/UserService';
import { useNavigate } from 'react-router-dom';
import '../CSS/ProfilePage.css'; // Create and style as needed

const ProfilePage = () => {
  const { auth, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const [user, setUser] = useState({ name: '', email: '' });
  const [editMode, setEditMode] = useState(false);
  const [updatedName, setUpdatedName] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  // Fetch user details on component mount
  useEffect(() => {
    const fetchUser = async () => {
      if (auth.userId && auth.token) {
        try {
          const userData = await getUserDetails(auth.userId, auth.token);
          setUser(userData);
          setUpdatedName(userData.name);
        } catch (error) {
          console.error('Error fetching user details:', error);
          setMessage('Failed to fetch user details.');
        }
      }
    };

    fetchUser();
  }, [auth.userId, auth.token]);

  // Handle name update
  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');

    try {
      const updatedData = { name: updatedName };
      const response = await updateUser(auth.userId, updatedData, auth.token);
      setUser(response.user);
      setEditMode(false);
      setMessage('Profile updated successfully.');
    } catch (error) {
      console.error('Error updating profile:', error);
      setMessage(error.message || 'Failed to update profile.');
    } finally {
      setLoading(false);
    }
  };

  // Handle account deletion
  const handleDeleteAccount = async () => {
    const confirmDeletion = window.confirm(
      'Are you sure you want to delete your account? This action cannot be undone.'
    );

    if (!confirmDeletion) return;

    setLoading(true);
    setMessage('');

    try {
      await deleteUser(auth.userId, auth.token);
      setMessage('Account deleted successfully.');
      logout(); // Clear auth state and redirect to login
      navigate('/login');
    } catch (error) {
      console.error('Error deleting account:', error);
      setMessage(error.message || 'Failed to delete account.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-container">
      <h2>Profile</h2>
      
      {message && <p className="profile-message">{message}</p>}

      {!editMode ? (
        <div className="profile-details">
          <p><strong>Name:</strong> {user.name}</p>
          <p><strong>Email:</strong> {user.email}</p>
          <button onClick={() => setEditMode(true)} className="edit-button">Edit Profile</button>
        </div>
      ) : (
        <form onSubmit={handleUpdate} className="profile-form">
          <label htmlFor="name">Name:</label>
          <input
            id="name"
            type="text"
            value={updatedName}
            onChange={(e) => setUpdatedName(e.target.value)}
            required
          />
          <button type="submit" disabled={loading} className="save-button">
            {loading ? 'Saving...' : 'Save Changes'}
          </button>
          <button type="button" onClick={() => setEditMode(false)} className="cancel-button">Cancel</button>
        </form>
      )}

      <hr />

      <button onClick={handleDeleteAccount} disabled={loading} className="delete-button">
        {loading ? 'Deleting...' : 'Delete Account'}
      </button>
    </div>
  );
};

export default ProfilePage;
