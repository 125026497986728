// src/services/dashboardService.js
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all canvases (projects) for a specific user
export const fetchCanvasesByUserId = async (userId) => {
  try {
    const response = await fetch(`${BASE_URL}/canvas/${userId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch canvases');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching canvases:', error);
    throw error;
  }
};

// Fetch a specific canvas by its ID for a specific user
export const fetchCanvasById = async (userId, canvasId) => {
  try {
    const response = await fetch(`${BASE_URL}/canvas/${userId}/${canvasId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch canvas');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching canvas:', error);
    throw error;
  }
};

// Save or update a canvas
export const saveCanvas = async (userId, canvasId, flowName, state) => {
  try {
    const response = await fetch(`${BASE_URL}/canvas/${userId}/${canvasId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ flowName, state }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error saving canvas:', error);
    throw error;
  }
};
// Fetch user data by userId with token
export const fetchUserById = async (userId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/user/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // Include the token for authentication
        'Content-Type': 'application/json',  // Good practice
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || 'Failed to fetch user data');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};
export const fetchProjectsByUserId = async (userId) => {
  try {
    const token = localStorage.getItem('token'); // Ensure you have the token stored
    if (!token) {
      throw new Error('No token found. Please log in again.');
    }

    const response = await fetch(`${BASE_URL}/projects/${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch projects: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching projects:', error.message);
    throw error;
  }
};

  export const fetchProjectById = async (projectId, token) => {
    try {
      const response = await fetch(`${BASE_URL}/project/${projectId}`, {
        headers: {
          'Authorization': `Bearer ${token}` // Include the JWT token in the Authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch project details');
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching project:', error);
      throw error;
    }
  };
  
  export const createCanvas = async (userId, projectId) => {
    try {
      const response = await fetch(`${BASE_URL}/canvas`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, projectId }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error creating canvas:', error);
      throw error;
    }
  };

// Service to delete a project by ID and its related canvases
export const deleteProjectById = async (projectId, token) => {
  const response = await fetch(`${BASE_URL}/project/${projectId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include the token for authentication
    },
  });

  if (!response.ok) {
    throw new Error('Failed to delete project');
  }

  return await response.json();
};
// Service to create a new project
export const createNewProject = async (newProject, token) => {
  try {
    const response = await fetch(`${BASE_URL}/projects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include the JWT token in the Authorization header
      },
      body: JSON.stringify(newProject),
    });

    if (!response.ok) {
      throw new Error('Failed to create project');
    }

    const data = await response.json();
    return data; // Return the newly created project
  } catch (error) {
    console.error('Error creating project:', error);
    throw error;
  }
};

export const toggleProjectStatus = async (projectId, token) => {
  try {
    const response = await fetch(`${BASE_URL}/project/${projectId}/toggle-status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`, // Include the token for authentication
      },
    });

    if (!response.ok) {
      throw new Error('Failed to toggle project status');
    }

    const data = await response.json();
    return data; // Return the updated project
  } catch (error) {
    console.error('Error toggling project status:', error);
    throw error;
  }
};
export const updateProject = async (projectId, updatedData, token) => {
  const response = await fetch(`${BASE_URL}/project/${projectId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(updatedData),
  });

  if (!response.ok) {
    throw new Error('Failed to update project');
  }

  return await response.json();
};

