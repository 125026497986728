// DataMappingComponent.jsx

// Import necessary modules and libraries
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDropzone } from 'react-dropzone';
import { parseString } from 'xml2js';
import { Tooltip } from 'react-tooltip';
import { useToast } from '@chakra-ui/react';

// Import your mapping service functions
import {
  saveMapping,
  loadMappingByCanvasId,
} from '../../services/mappingService';

// Import your CSS files
import '../../CSS/DataMappingComponent.css';
import '../../App.css';

// Utility function to download a file
const downloadFile = (content, filename, type) => {
  const blob = new Blob([content], { type });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = filename;

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

// Draggable/Droppable Field Component
const Field = ({
  id,
  name,
  type,
  onDrop,
  mappedField,
  value,
  style,
  onClick,
  hasOperations,
  operationNames,
  onDelete,
  onRightClick, // New prop to handle right-click
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type,
    item: { id, name, type },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: ['input-field', 'output-field', 'operation-field'],
    drop: (item) => onDrop(item, { id, name, type }),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;
  let backgroundColor = '#fff';
  if (isActive) {
    backgroundColor = '#d4edda'; // Light green
  } else if (canDrop) {
    backgroundColor = '#fff3cd'; // Light yellow
  }

  // Determine border color based on type
  const borderColors = {
    'input-field': '#007bff', // Blue
    'output-field': '#28a745', // Green
    'operation-field': '#6c757d', // Gray
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
    if (onRightClick) {
      onRightClick(e, { id, name, type });
    }
  };

  return (
    <>
      <div
        ref={(node) => drag(drop(node))}
        id={`${type}-${id}`} // Ensure uniqueness
        className="field"
        onClick={onClick}
        onContextMenu={handleContextMenu} // Add the context menu handler
        data-tooltip-id={hasOperations ? `tooltip-${id}` : undefined} // Add tooltip id if hasOperations
        style={{
          ...style,
          backgroundColor,
          opacity: isDragging ? 0.6 : 1,
          borderLeft: `5px solid ${borderColors[type] || '#000'}`,
          boxSizing: 'border-box', // Ensure padding and border are included in width and height
          borderRadius: '8px',
          padding: '10px',
          margin: '5px 0',
          cursor: 'move',
          transition: 'background-color 0.3s, box-shadow 0.3s',
          position: 'relative', // Added for indicator positioning
          whiteSpace: 'nowrap', // Prevent text from wrapping
          overflow: 'hidden',
          textOverflow: 'ellipsis', // Add ellipsis for overflowed text
        }}
      >
        <span
          style={{
            marginRight: '10px',
            flex: 1,
            fontWeight: 'bold',
            color: '#343a40',
          }}
        >
          {name}
        </span>
        {mappedField && (
          <span style={{ color: '#17a2b8', marginLeft: '10px' }}>{mappedField}</span>
        )}
        {value && (
          <span style={{ color: '#6c757d', marginLeft: '10px' }}>{value}</span>
        )}
        {hasOperations && (
          <span
            style={{
              position: 'absolute',
              top: '8px',
              right: '8px',
              width: '10px',
              height: '10px',
              backgroundColor: 'red',
              borderRadius: '50%',
            }}
          ></span>
        )}
        {onDelete && (
          <button
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering other click events
              onDelete(id);
            }}
            style={{
              position: 'absolute',
              top: '5px',
              right: '5px',
              background: 'transparent',
              border: 'none',
              color: '#dc3545', // Bootstrap danger color
              fontSize: '16px',
              cursor: 'pointer',
            }}
          >
            &times;
          </button>
        )}
      </div>
      {/* Tooltip Component */}
      {hasOperations && operationNames && (
        <Tooltip id={`tooltip-${id}`} place="top" effect="solid">
          {operationNames.join(', ')}
        </Tooltip>
      )}
    </>
  );
};

// Utility function to recursively render fields
const displayFields = (
  obj,
  depth,
  parentKey,
  onDrop,
  mappedFields,
  type,
  handleFieldRightClick // Pass the handler down
) => {
  let fields = [];
  const indentStyle = { marginLeft: `${depth * 20}px` };

  const processField = (key, value, fieldKey) => {
    if (Array.isArray(value) && value.length > 0) {
      const arrayFieldKey = `${parentKey ? parentKey + '.' : ''}${key}`;
      processField(key, value[0], arrayFieldKey);
    } else if (typeof value === 'object' && value !== null) {
      fields.push(
        <div
          key={fieldKey}
          style={{ marginLeft: `${depth * 20}px`, marginTop: '10px' }}
        >
          <strong style={{ color: '#6c757d' }}>{key}:</strong>
          {displayFields(
            value,
            depth + 1,
            fieldKey,
            onDrop,
            mappedFields,
            type,
            handleFieldRightClick // Pass it recursively
          )}
        </div>
      );
    } else {
      // Determine if the field has any connections
      const hasConnections =
        mappedFields[fieldKey] !== undefined ||
        mappedFields[fieldKey] !== null;

      fields.push(
        <Field
          key={fieldKey} // Unique key for React
          id={fieldKey} // Unique ID for the field
          name={key} // Field name
          type={type} // Field type
          onDrop={onDrop} // Drop handler
          mappedField={mappedFields[fieldKey]} // Mapped field
          value={value} // Field value
          style={indentStyle} // Indentation
          onClick={() => {}}
          hasOperations={false}
          onDelete={null}
          onRightClick={handleFieldRightClick} // Pass the handler
        />
      );
    }
  };

  Object.entries(obj).forEach(([key, value]) => {
    const fieldKey = `${parentKey ? parentKey + '.' : ''}${key}`;
    processField(key, value, fieldKey);
  });

  return fields;
};

// InputComponent
const InputComponent = ({
  fileContent,
  onInputChange,
  onDrop,
  mappedFields,
  fileName,
  setFieldsRendered,
  handleFieldRightClick, // Add this
}) => {
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (fileContent) {
      try {
        // Try parsing JSON
        const jsonObject = JSON.parse(fileContent);
        setFields(jsonObject);
      } catch (e) {
        // If not JSON, try parsing as XML
        parseString(
          fileContent,
          { trim: true, explicitArray: false },
          (err, result) => {
            if (!err) {
              setFields(result);
            }
          }
        );
      }
    }
  }, [fileContent]);

  useEffect(() => {
    // Indicate that input fields have been rendered
    if (Object.keys(fields).length > 0) {
      setFieldsRendered((prev) => ({ ...prev, input: true }));
    }
  }, [fields, setFieldsRendered]);

  const onDropFile = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            if (file.name.endsWith('.xml')) {
              parseString(
                reader.result,
                { trim: true, explicitArray: false },
                (err, result) => {
                  if (err) {
                    console.error('Invalid XML file', err);
                  } else {
                    onInputChange(reader.result);
                    setFields((prev) => ({ ...prev, [file.name]: result }));
                  }
                }
              );
            } else if (file.name.endsWith('.csv')) {
              const csvData = csvToJson(reader.result);
              onInputChange(reader.result);
              setFields((prev) => ({ ...prev, [file.name]: csvData }));
            } else {
              const jsonObject = JSON.parse(reader.result);
              onInputChange(reader.result);
              setFields((prev) => ({ ...prev, [file.name]: jsonObject }));
            }
          } catch (e) {
            console.error('Invalid file', e);
          }
        };
        reader.readAsText(file);
      });
    },
    [onInputChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    accept: ['.json', '.xml', '.csv'],
    multiple: true,
  });

  // Handle file download
  const handleDownload = () => {
    // Determine the file type based on content
    let fileType = 'text/plain';
    if (fileName.endsWith('.json')) fileType = 'application/json';
    else if (fileName.endsWith('.xml')) fileType = 'application/xml';
    else if (fileName.endsWith('.csv')) fileType = 'text/csv';

    downloadFile(fileContent, fileName, fileType);
  };

  return (
    <div id="inputComponent">
      <div className="dropzone">
        <div {...getRootProps()} className="dropzone-inner">
          <input {...getInputProps()} />
          <p>Drag and drop files here, or click to select files</p>
        </div>
      </div>
      <div className="file-actions">
        <button className="btn" onClick={handleDownload}>
          Download Input File
        </button>
      </div>
      <div className="field-display">
        {Object.keys(fields).map((fileNameKey) => (
          <div key={fileNameKey} className="file-section">
            <h3 className="file-title">{fileNameKey}</h3>
            {displayFields(
              fields[fileNameKey],
              0,
              '',
              onDrop,
              mappedFields,
              'input-field',
              handleFieldRightClick // Pass the handler
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// OutputComponent
const OutputComponent = ({
  fileContent,
  onOutputChange,
  onDrop,
  mappedFields,
  fileName,
  setFieldsRendered,
  handleFieldRightClick, // Add this
}) => {
  const [fields, setFields] = useState({});

  useEffect(() => {
    if (fileContent) {
      try {
        // Try parsing JSON
        const jsonObject = JSON.parse(fileContent);
        setFields(jsonObject);
      } catch (e) {
        // If not JSON, try parsing as XML
        parseString(
          fileContent,
          { trim: true, explicitArray: false },
          (err, result) => {
            if (!err) {
              setFields(result);
            }
          }
        );
      }
    }
  }, [fileContent]);

  useEffect(() => {
    // Indicate that output fields have been rendered
    if (Object.keys(fields).length > 0) {
      setFieldsRendered((prev) => ({ ...prev, output: true }));
    }
  }, [fields, setFieldsRendered]);

  const onDropFile = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = () => {
          try {
            let parsedData;

            if (file.name.endsWith('.json')) {
              parsedData = JSON.parse(reader.result);
            } else if (file.name.endsWith('.xml')) {
              parseString(
                reader.result,
                { trim: true, explicitArray: false },
                (err, result) => {
                  if (err) {
                    throw new Error('Invalid XML file');
                  } else {
                    parsedData = result;
                  }
                }
              );
            } else if (file.name.endsWith('.csv')) {
              parsedData = csvToJson(reader.result);
            } else {
              throw new Error('Unsupported file format');
            }

            onOutputChange(reader.result);
            setFields(parsedData);
          } catch (e) {
            console.error('Invalid file format', e);
          }
        };
        reader.readAsText(file);
      });
    },
    [onOutputChange]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropFile,
    accept: ['.json', '.xml', '.csv'],
    multiple: false,
  });

  // Handle file download
  const handleDownload = () => {
    // Determine the file type based on content
    let fileType = 'text/plain';
    if (fileName.endsWith('.json')) fileType = 'application/json';
    else if (fileName.endsWith('.xml')) fileType = 'application/xml';
    else if (fileName.endsWith('.csv')) fileType = 'text/csv';

    downloadFile(fileContent, fileName, fileType);
  };

  return (
    <div id="outputComponent">
      <div className="dropzone">
        <div {...getRootProps()} className="dropzone-inner">
          <input {...getInputProps()} />
          <p>Drag and drop a file here, or click to select one</p>
        </div>
      </div>
      <div className="file-actions">
        <button className="btn" onClick={handleDownload}>
          Download Output File
        </button>
      </div>
      <div className="field-display">
        {Object.keys(fields).length > 0 ? (
          Object.keys(fields).map((fileNameKey) => (
            <div key={fileNameKey} className="file-section">
              <h4 className="file-title">{fileNameKey}</h4>
              {/* Use displayFields to recursively render the structured fields */}
              {displayFields(
                fields[fileNameKey],
                0,
                '',
                onDrop,
                mappedFields,
                'output-field',
                handleFieldRightClick // Pass the handler
              )}
            </div>
          ))
        ) : (
          <p className="no-fields">
            No structured fields available. The file might be empty or unstructured.
          </p>
        )}
      </div>
    </div>
  );
};

// OperationBuilder Component
const OperationBuilder = ({ onAddOperation }) => {
  const commonOperations = {
    'Text Operation': [
      { name: 'Uppercase', id: 'uppercase' },
      { name: 'Lowercase', id: 'lowercase' },
      { name: 'Concat', id: 'concat' },
      // Add more text operations as needed
    ],
    'Numeric Operation': [
      { name: 'Add', id: 'add' },
      { name: 'Subtract', id: 'subtract' },
      { name: 'Multiply', id: 'multiply' },
      { name: 'Divide', id: 'divide' },
      // Add more numeric operations as needed
    ],
    // Add other operation types if needed
  };

  const [customOperationName, setCustomOperationName] = useState('');

  const handleAddOperation = (operation) => {
    const newOperation = {
      operationId: operation.id,
      name: operation.name,
      parameters: {}, // Initialize with empty parameters
    };
    onAddOperation(newOperation);
  };

  const handleAddCustomOperation = () => {
    if (customOperationName.trim() === '') return;

    // Generate a unique ID for the custom operation
    const operationId = customOperationName.toLowerCase().replace(/\s+/g, '_');

    const newOperation = {
      operationId: operationId,
      name: customOperationName,
      parameters: {}, // Initialize with empty parameters
    };
    onAddOperation(newOperation);
    setCustomOperationName('');
  };

  return (
    <div className="operation-builder">
      <h3>Select Operations</h3>
      <div className="common-operations">
        {Object.keys(commonOperations).map((operationType) => (
          <div key={operationType} className="operation-category">
            <h4>{operationType}</h4>
            {commonOperations[operationType].map((op) => (
              <button
                key={op.id}
                onClick={() => handleAddOperation(op)}
                className="common-operation-button"
              >
                {op.name}
              </button>
            ))}
          </div>
        ))}
      </div>
      <div className="custom-operation">
        <input
          type="text"
          placeholder="Custom Operation"
          value={customOperationName}
          onChange={(e) => setCustomOperationName(e.target.value)}
        />
        <button onClick={handleAddCustomOperation}>Add Custom Operation</button>
      </div>
    </div>
  );
};

// OperationModal Component
const OperationModal = ({ onClose, onAddOperation }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <OperationBuilder onAddOperation={onAddOperation} />
      </div>
    </div>
  );
};

// OperationEditor Component
const OperationEditor = ({ mapping, onClose, onUpdateOperations }) => {
  const [operations, setOperations] = useState(mapping.operations || []);

  const handleAddOperation = (operation) => {
    setOperations((prevOperations) => [...prevOperations, operation]);
  };

  const handleDeleteOperation = (index) => {
    setOperations((prevOperations) => prevOperations.filter((_, i) => i !== index));
  };

  const handleDeleteMapping = () => {
    onUpdateOperations(null); // Signal that the mapping should be deleted
  };

  const handleSave = () => {
    onUpdateOperations(operations);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <h3>Edit Operations</h3>
        <div>
          {operations.map((op, index) => (
            <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ flex: 1 }}>{op.name}</span>
              <button
                onClick={() => handleDeleteOperation(index)}
                style={{
                  marginLeft: '10px',
                  color: 'red',
                  border: 'none',
                  background: 'transparent',
                  cursor: 'pointer',
                }}
              >
                &times;
              </button>
            </div>
          ))}
        </div>
        <OperationBuilder onAddOperation={handleAddOperation} />
        <button onClick={handleSave} style={{ marginTop: '10px' }}>
          Save
        </button>
        <button
          onClick={handleDeleteMapping}
          style={{ marginTop: '10px', color: 'red' }}
        >
          Delete Mapping
        </button>
      </div>
    </div>
  );
};

// Utility function to convert CSV to JSON (basic implementation)
const csvToJson = (csvString) => {
  const rows = csvString.split('\n').filter((row) => row.trim() !== '');
  if (rows.length === 0) return [];
  const headers = rows[0].split(',').map((header) => header.trim());
  return rows.slice(1).map((row) => {
    const values = row.split(',').map((value) => value.trim());
    return headers.reduce((object, header, index) => {
      object[header] = values[index];
      return object;
    }, {});
  });
};

// DataMappingComponent
const DataMappingComponent = ({
  canvasId,
  onSave,
  userId,
  flowName,
  uniqueId,
}) => {
  const [inputFiles, setInputFiles] = useState([{ id: 1, content: '{}', name: 'input1.json' }]);
  const [outputFiles, setOutputFiles] = useState([
    { id: 1, content: '{}', name: 'output1.json' },
  ]);
  const [operations, setOperations] = useState([]); // List of operation fields
  const [mappings, setMappings] = useState([]);
  const [lines, setLines] = useState([]);
  const [history, setHistory] = useState([[]]); // Initialize with an empty mappings array
  const [redoStack, setRedoStack] = useState([]);
  const [mappingId, setMappingId] = useState(null); // Store the mappingId here
  const [fieldsRendered, setFieldsRendered] = useState({
    input: false,
    output: false,
  });
  const toast = useToast(); // Initialize useToast

  const [editingMappingIndex, setEditingMappingIndex] = useState(null);

  const svgRef = useRef(null);
  const mappingContainerRef = useRef(null);

  // New state for context menu
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    field: null,
  });

  // Function to derive mappedFields from mappings
  const deriveMappedFields = useCallback((mappings) => {
    const mapped = {};
    mappings.forEach((mapping) => {
      mapped[mapping.source.id] = mapping.target.name;
    });
    return mapped;
  }, []);

  const mappedFields = deriveMappedFields(mappings);

  // Function to update the connecting lines between fields
  const updateLines = useCallback(() => {
    if (!mappingContainerRef.current) {
      console.error('mappingContainerRef.current is null');
      return;
    }

    const updatedLines = mappings
      .map((mapping, mappingIndex) => {
        const sourceElement = document.getElementById(
          `${mapping.source.type}-${mapping.source.id}`
        );
        const targetElement = document.getElementById(
          `${mapping.target.type}-${mapping.target.id}`
        );

        if (!sourceElement || !targetElement) {
          console.error(
            `Element not found for mapping: ${mapping.source.id} or ${mapping.target.id}`
          );
          return null;
        }

        const getElementPosition = (element) => {
          let x = 0;
          let y = 0;
          let currentElement = element;

          while (currentElement && currentElement !== mappingContainerRef.current) {
            x +=
              currentElement.offsetLeft -
              currentElement.scrollLeft +
              currentElement.clientLeft;
            y +=
              currentElement.offsetTop -
              currentElement.scrollTop +
              currentElement.clientTop;
            currentElement = currentElement.offsetParent;
          }

          return { x, y };
        };

        const sourcePos = getElementPosition(sourceElement);
        const targetPos = getElementPosition(targetElement);

        const x1 = sourcePos.x + sourceElement.offsetWidth;
        const y1 = sourcePos.y + sourceElement.offsetHeight / 2;
        const x2 = targetPos.x;
        const y2 = targetPos.y + targetElement.offsetHeight / 2;

        // Concatenate operation names, separated by commas
        const operationNames = mapping.operations
          ? mapping.operations.map((op) => op.name).join(', ')
          : '';

        return {
          x1,
          y1,
          x2,
          y2,
          type: mapping.source.type,
          operationNames, // Include operation names
          mappingIndex, // Include the index of the mapping
        };
      })
      .filter((line) => line !== null);

    setLines(updatedLines);
  }, [mappings]);

  // Ensure lines are updated after DOM elements are rendered
  useEffect(() => {
    if (fieldsRendered.input && fieldsRendered.output) {
      setTimeout(() => {
        updateLines();
      }, 0); // Delay can be adjusted if necessary
    }
  }, [mappings, fieldsRendered, updateLines]);

  // Handle window resize to update lines
  useEffect(() => {
    const handleResize = () => {
      if (fieldsRendered.input && fieldsRendered.output) {
        updateLines();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [fieldsRendered, updateLines]);

  // Handle field drop to create new mapping
  const handleDrop = (sourceField, targetField) => {
    // Prevent duplicate mappings
    const exists = mappings.some(
      (mapping) =>
        mapping.source.id === sourceField.id &&
        mapping.target.id === targetField.id
    );
    if (exists) return;

    // Update the mappings state
    setMappings((prevMappings) => {
      const newMapping = {
        source: sourceField,
        target: targetField,
        operations: [], // Initialize with an empty operations array
      };
      const newMappings = [...prevMappings, newMapping];

      // Update the history and redo stack with a deep copy
      setHistory((prevHistory) => [
        ...prevHistory,
        JSON.parse(JSON.stringify(newMappings)),
      ]);
      setRedoStack([]);

      return newMappings;
    });
  };

  const undo = () => {
    if (history.length > 1) {
      setRedoStack((prevRedoStack) => [
        history[history.length - 1],
        ...prevRedoStack,
      ]);
      const previous = history[history.length - 2];
      setMappings(JSON.parse(JSON.stringify(previous)));
      setHistory((prevHistory) => prevHistory.slice(0, -1));
    }
  };

  const redo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack[0];
      setHistory((prevHistory) => [
        ...prevHistory,
        JSON.parse(JSON.stringify(nextState)),
      ]);
      setMappings(JSON.parse(JSON.stringify(nextState)));
      setRedoStack((prevRedoStack) => prevRedoStack.slice(1));
    }
  };

  const handleSaveMapping = async () => {
    try {
      const dataToSave = {
        canvasId,
        userId,
        flowName,
        mappings, // Each mapping includes operations
        inputFileContent: inputFiles.map((file) => file.content),
        outputFileContent: outputFiles.map((file) => file.content),
        uniqueId,
        operations, // Save operations
        history, // Save history stack
        redoStack, // Save redo stack
      };

      // Only include mappingId if it is not null or undefined
      if (mappingId) {
        dataToSave.mappingId = mappingId;
      }


      const response = await saveMapping(dataToSave);

      if (response && response.mappingId) {
        setMappingId(response.mappingId); // Store the mappingId in state
        onSave({ ...dataToSave, mappingId: response.mappingId });

        // Display success message using toast
        toast({
          title: 'Mappings Saved',
          description: 'Your mappings and files have been saved successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } else {
        // Display error message if mappingId is not returned
        toast({
          title: 'Save Failed',
          description: 'Failed to save mappings and files.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Error saving mapping and files:', error);
      // Display error message with error details
      toast({
        title: 'Save Failed',
        description: `Error: ${error.message}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Load the mappings, input, and output fields from the backend
  useEffect(() => {
    const loadMapping = async () => {
      if (canvasId && uniqueId) {
        try {
          const response = await loadMappingByCanvasId(canvasId, uniqueId); // Pass uniqueId
          console.log('Mapping data received from backend:', response);
          console.log('Mapping ID:', response.mappingId);

          // Ensure that each mapping has an operations array
          const loadedMappings = (response.mappings || []).map((mapping) => ({
            ...mapping,
            operations: mapping.operations || [],
          }));

          setMappings(loadedMappings);
          setInputFiles(
            (response.inputFileContent || []).map((content, index) => ({
              id: index + 1,
              content,
              name: `input${index + 1}.json`,
            }))
          );
          setOutputFiles(
            (response.outputFileContent || []).map((content, index) => ({
              id: index + 1,
              content,
              name: `output${index + 1}.json`,
            }))
          );
          setMappingId(response.mappingId);

          // Load operations, history, and redoStack
          setOperations(response.operations || []);
          setHistory(response.history || [[]]);
          setRedoStack(response.redoStack || []);

          // Update the history with the loaded mappings if history is empty
          if (!response.history || response.history.length === 0) {
            setHistory([[], JSON.parse(JSON.stringify(loadedMappings))]);
          }
        } catch (error) {
          console.error('Error loading mapping:', error);
        }
      }
    };

    loadMapping();
  }, [canvasId, uniqueId]); // Add uniqueId as a dependency

  const handleReset = () => {
    setHistory([[]]);
    setMappings([]);
    setLines([]);
    setInputFiles([{ id: 1, content: '{}', name: 'input1.json' }]);
    setOutputFiles([{ id: 1, content: '{}', name: 'output1.json' }]);
    setRedoStack([]);
    setOperations([]);
  };

  // Handle adding a new operation
  const [showModal, setShowModal] = useState(false);

  const handleAddOperationClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleAddOperation = (operation) => {
    setOperations((prevOperations) => [...prevOperations, operation]);
    setShowModal(false);
  };

  const handleEditOperations = (mappingIndex) => {
    setEditingMappingIndex(mappingIndex);
  };

  const handleDeleteOperation = (operationId) => {
    setOperations((prevOperations) =>
      prevOperations.filter((op) => op.operationId !== operationId)
    );
  };

  // Handle right-click on a field
  const handleFieldRightClick = (event, field) => {
    // Check if the field has any connections
    const hasConnections = mappings.some(
      (mapping) =>
        (mapping.source.id === field.id && mapping.source.type === field.type) ||
        (mapping.target.id === field.id && mapping.target.type === field.type)
    );

    if (!hasConnections) {
      // Do not show context menu if there are no connections
      return;
    }

    event.preventDefault(); // Prevent the default context menu

    const containerRect = mappingContainerRef.current.getBoundingClientRect();

    setContextMenu({
      visible: true,
      x: event.clientX - containerRect.left,
      y: event.clientY - containerRect.top,
      field: field,
    });
  };

  // Handle clicking outside to close the context menu
  useEffect(() => {
    const handleClick = () => {
      if (contextMenu.visible) {
        setContextMenu({ ...contextMenu, visible: false });
      }
    };
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [contextMenu]);

  // Function to unconnect mappings related to a field
  const handleUnconnect = () => {
    if (!contextMenu.field) return;

    const { id, type } = contextMenu.field;

    setMappings((prevMappings) => {
      const newMappings = prevMappings.filter(
        (mapping) =>
          !(
            (mapping.source.id === id && mapping.source.type === type) ||
            (mapping.target.id === id && mapping.target.type === type)
          )
      );

      // Update history
      setHistory((prevHistory) => [
        ...prevHistory,
        JSON.parse(JSON.stringify(newMappings)),
      ]);
      setRedoStack([]);

      return newMappings;
    });

    // Close the context menu
    setContextMenu({ ...contextMenu, visible: false, field: null });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="app"
        ref={mappingContainerRef}
        style={{
          
          position: 'relative',
          padding: '20px',
          backgroundColor: '#f8f9fa',
          borderRadius: '8px',
          overflow: 'auto', // Add overflow if scrolling is possible
        }}
      >
        {/* Toolbar with Save, Undo, Redo, Reset buttons */}
        <div
          className="toolbar"
          style={{
            marginBottom: '20px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <button className="btn" onClick={handleSaveMapping}>
            Save Mapping
          </button>
          <button className="btn" onClick={undo} disabled={history.length <= 1}>
            Undo
          </button>
          <button className="btn" onClick={redo} disabled={redoStack.length === 0}>
            Redo
          </button>
          <button className="btn" onClick={handleReset}>
            Reset Mappings
          </button>
        </div>

        {/* Main Section with Inputs, Operations, Outputs */}
        <div
          id="inputOutputSection"
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
            gap: '20px',
          }}
        >
          {/* Input Section */}
          <div
            id="inputSection"
            style={{
              flex: '0 0 25%',              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            }}
          >
            {inputFiles.map((file, index) => (
              <div
                key={index}
                id={`input-${file.id}`}
                style={{ marginBottom: '20px' }}
              >
                <div
                  className="file-label"
                  style={{
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                >
                  Input File {index + 1}: {file.name}
                </div>

                <InputComponent
                  fileContent={file.content} // Ensure this is the actual content
                  onInputChange={(content) =>
                    setInputFiles((prevFiles) => {
                      const newFiles = [...prevFiles];
                      newFiles[index].content = content;
                      return newFiles;
                    })
                  }
                  onDrop={handleDrop}
                  mappedFields={mappedFields} // Pass derived mappedFields
                  fileName={file.name}
                  setFieldsRendered={setFieldsRendered}
                  handleFieldRightClick={handleFieldRightClick} // Pass the handler
                />
              </div>
            ))}
          </div>

          {/* Operations Section */}
          <div
            className="operations-section"
            style={{
              padding: '20px',
              flex: 'none',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              minWidth: '250px',
              maxHeight: '80vh',
              overflowY: 'auto',
            }}
          >
            <h4
              style={{
                textAlign: 'center',
                marginBottom: '20px',
                color: '#343a40',
              }}
            >
              Operations
            </h4>
            {/* Button to Add Operations */}
            <button
              className="btn add-operation-btn"
              onClick={handleAddOperationClick}
              style={{ marginBottom: '10px' }}
            >
              Add Operation
            </button>

            {/* List of Operation Fields */}
            {operations.map((operation) => (
              <Field
                key={operation.operationId}
                id={operation.operationId}
                name={operation.name}
                type="operation-field"
                onDrop={handleDrop}
                style={{ cursor: 'move' }}
                hasOperations={false}
                onClick={() => {}}
                onDelete={() => handleDeleteOperation(operation.operationId)}
                onRightClick={handleFieldRightClick} // Pass the handler if needed
              />
            ))}

            {/* If no operations, display a message */}
            {operations.length === 0 && (
              <p style={{ textAlign: 'center', color: '#6c757d' }}>
                No operations added. Click "Add Operation" to start.
              </p>
            )}
          </div>

          {/* Output Section */}
          <div
            id="outputSection"
            style={{
              flex: '0 0 25%',  // Adjusted to take up 25% of the width
              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
            }}
          >
            {outputFiles.map((file, index) => (
              <div
                key={index}
                id={`output-${file.id}`}
                style={{ marginBottom: '20px' }}
              >
                <div
                  className="file-label"
                  style={{
                    fontWeight: 'bold',
                    marginBottom: '10px',
                  }}
                >
                  Output File {index + 1}: {file.name}
                </div>
                <OutputComponent
                  fileContent={file.content}
                  onOutputChange={(content) =>
                    setOutputFiles((prevFiles) => {
                      const newFiles = [...prevFiles];
                      newFiles[index].content = content;
                      return newFiles;
                    })
                  }
                  onDrop={handleDrop}
                  mappedFields={mappedFields} // Pass derived mappedFields
                  fileName={file.name}
                  setFieldsRendered={setFieldsRendered}
                  handleFieldRightClick={handleFieldRightClick} // Pass the handler
                />
              </div>
            ))}
          </div>
        </div>

        {/* SVG for connecting lines */}
        <svg
          ref={svgRef}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            pointerEvents: 'none',
          }}
        >
          <defs>
            <marker
              id="arrow"
              markerWidth="10"
              markerHeight="10"
              refX="10"
              refY="3"
              orient="auto"
              markerUnits="strokeWidth"
            >
              <path d="M0,0 L0,6 L9,3 z" fill="#000" />
            </marker>
          </defs>
          {lines.map((line, index) => {
            // Calculate the control point for the curve (midpoint between source and target)
            const controlX = (line.x1 + line.x2) / 2;
            const controlY = Math.min(line.y1, line.y2) - 50; // Adjust the controlY for the curve height

            // Determine line color based on type
            const lineColors = {
              'input-field': '#007bff', // Blue
              'output-field': '#28a745', // Green
              'operation-field': '#6c757d', // Gray
            };
            const strokeColor = lineColors[line.type] || '#000';

            return (
              <g key={index}>
                <path
                  d={`M${line.x1},${line.y1} Q${controlX},${controlY} ${line.x2},${line.y2}`}
                  stroke={strokeColor}
                  strokeWidth={2}
                  fill="none"
                  markerEnd="url(#arrow)"
                  style={{ transition: 'stroke 0.3s', cursor: 'pointer' }}
                  onClick={() => handleEditOperations(line.mappingIndex)}
                />
                {line.operationNames && line.operationNames.trim() !== '' && (
                  <text
                    x={controlX}
                    y={controlY}
                    textAnchor="middle"
                    fill="black"
                    fontSize="12"
                    fontFamily="Arial"
                    dy="-5" // Adjust vertical position
                    onClick={() => handleEditOperations(line.mappingIndex)}
                    style={{ cursor: 'pointer' }}
                  >
                    {line.operationNames}
                  </text>
                )}
              </g>
            );
          })}
        </svg>

        {/* Context Menu */}
        {contextMenu.visible && (
          <div
            className="context-menu"
            style={{
              position: 'absolute',
              top: contextMenu.y,
              left: contextMenu.x,
              backgroundColor: '#ffffff',
              border: '1px solid #ccc',
              borderRadius: '4px',
              boxShadow: '0 2px 10px rgba(0,0,0,0.2)',
              zIndex: 1000,
            }}
          >
            <div
              className="context-menu-item"
              onClick={handleUnconnect}
              style={{
                padding: '8px 12px',
                cursor: 'pointer',
                whiteSpace: 'nowrap',
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#f1f1f1')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#ffffff')}
            >
              Unconnect
            </div>
          </div>
        )}

        {/* Render OperationModal */}
        {showModal && (
          <OperationModal onClose={handleModalClose} onAddOperation={handleAddOperation} />
        )}

        {/* Render OperationEditor */}
        {editingMappingIndex !== null && (
          <OperationEditor
            mapping={mappings[editingMappingIndex]}
            onClose={() => setEditingMappingIndex(null)}
            onUpdateOperations={(updatedOperations) => {
              if (updatedOperations === null) {
                // Delete the mapping
                setMappings((prevMappings) => {
                  const newMappings = prevMappings.filter((_, i) => i !== editingMappingIndex);
                  // Update history
                  setHistory((prevHistory) => [
                    ...prevHistory,
                    JSON.parse(JSON.stringify(newMappings)),
                  ]);
                  return newMappings;
                });
              } else {
                // Update the operations for the mapping
                setMappings((prevMappings) => {
                  const newMappings = [...prevMappings];
                  newMappings[editingMappingIndex].operations = updatedOperations;
                  // Update history
                  setHistory((prevHistory) => [
                    ...prevHistory,
                    JSON.parse(JSON.stringify(newMappings)),
                  ]);
                  return newMappings;
                });
              }
              setEditingMappingIndex(null);
            }}
          />
        )}
      </div>
    </DndProvider>
  );
};

export default DataMappingComponent;
