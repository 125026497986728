import React, { useState, useEffect, useCallback } from 'react';
import { parseString, Builder } from 'xml2js';
import {
  ChakraProvider,
  Box,
  Flex,
  Text,
  Button,
  IconButton,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  HStack,
  Spacer,
  useToast,
  extendTheme,
} from '@chakra-ui/react';
import {
  DeleteIcon,
  DownloadIcon,
  ArrowUpDownIcon,
  AttachmentIcon,
} from '@chakra-ui/icons';
import _ from 'lodash';

// Custom Chakra UI theme
const customTheme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
});

/**
 * Helper Functions
 */

// Converts a path array to access nested properties
const getNested = (obj, path) => {
  return path.reduce((acc, key) => (acc ? acc[key] : undefined), obj);
};

// Sets a value at the specified path immutably
const setNested = (obj, path, value) => {
  if (path.length === 0) return value;
  const [first, ...rest] = path;
  return {
    ...obj,
    [first]: setNested(obj[first] || (typeof rest[0] === 'number' ? [] : {}), rest, value),
  };
};

// Deletes a key at the specified path immutably
const deleteNested = (obj, path) => {
  if (path.length === 0) return obj;
  const [first, ...rest] = path;
  if (rest.length === 0) {
    if (Array.isArray(obj)) {
      return obj.filter((_, index) => index !== first);
    } else {
      const { [first]: deleted, ...restObj } = obj;
      return restObj;
    }
  }
  if (obj[first] === undefined) return obj;
  return {
    ...obj,
    [first]: deleteNested(obj[first], rest),
  };
};

// Renames a key at the specified path immutably
const renameNestedKey = (obj, path, oldKey, newKey) => {
  if (path.length === 0) return obj;
  const [first, ...rest] = path;
  if (rest.length === 0) {
    if (Array.isArray(obj)) {
      // Renaming in arrays isn't typical; skip or handle as needed
      return obj;
    } else {
      if (!obj.hasOwnProperty(oldKey)) return obj;
      if (obj.hasOwnProperty(newKey)) return obj; // Avoid duplicate keys
      const { [oldKey]: value, ...restObj } = obj;
      return {
        ...restObj,
        [newKey]: value,
      };
    }
  }
  return {
    ...obj,
    [first]: renameNestedKey(obj[first], rest, oldKey, newKey),
  };
};

/**
 * EditableKey Component
 * Allows inline editing of a key name.
 */
const EditableKey = ({
  currentKey,
  path,
  handleRenameField,
  isArrayItem = false,
  parentArrayPath = [],
}) => {
  const [keyName, setKeyName] = useState(currentKey);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = React.useRef(null);
  const toast = useToast();

  // Synchronize keyName state with currentKey prop
  useEffect(() => {
    setKeyName(currentKey);
  }, [currentKey]);

  // Focus on the input when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  // Handle key events for the input
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitRename();
    } else if (e.key === 'Escape') {
      cancelRename();
    }
  };

  // Submit the rename action
  const submitRename = () => {
    if (keyName.trim() === '') {
      toast({
        title: 'Field name cannot be empty.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      setKeyName(currentKey);
      setIsEditing(false);
      return;
    }

    if (keyName !== currentKey) {
      handleRenameField(path, currentKey, keyName, isArrayItem, parentArrayPath);
    }
    setIsEditing(false);
  };

  // Cancel the rename action
  const cancelRename = () => {
    setKeyName(currentKey);
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <Input
          ref={inputRef}
          value={keyName}
          onChange={(e) => setKeyName(e.target.value)}
          onBlur={submitRename}
          onKeyDown={handleKeyDown}
          variant="outline"
          color="black"
          size="sm"
          maxWidth="200px"
        />
      ) : (
        <Text
          onClick={() => setIsEditing(true)}
          cursor="pointer"
          _hover={{ bg: 'gray.200' }}
          px={2}
          py={1}
          borderRadius="md"
          maxWidth="200px"
          isTruncated
        >
          {currentKey}
        </Text>
      )}
    </>
  );
};

/**
 * RenderData Component
 * Recursively renders the data structure allowing editing.
 */
const RenderData = ({
  data,
  path = [],
  isRoot = false,
  handleRenameField,
  handleEditValue,
  handleDeleteField,
  handleMoveField,
  isArrayItem = false,
  parentArrayPath = [],
}) => {
  // Determine if data is an object
  const isObject =
    typeof data === 'object' && data !== null && !Array.isArray(data);

  // Handle array data
  if (Array.isArray(data)) {
    return (
      <Accordion allowMultiple defaultIndex={isRoot ? [0] : []} mb={4}>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              <Text fontWeight="bold" color="black">
                Array of {data.length} items
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {data.map((item, index) => (
              <Box key={index} mb={4} pl={4} borderLeft="2px solid #e2e8f0">
                <Heading size="sm" mb={2}>
                  Item {index + 1}
                </Heading>
                <RenderData
                  data={item}
                  path={[...path, index]}
                  handleRenameField={handleRenameField}
                  handleEditValue={handleEditValue}
                  handleDeleteField={handleDeleteField}
                  handleMoveField={handleMoveField}
                  isArrayItem={true}
                  parentArrayPath={path}
                />
              </Box>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }

  // Handle object data
  if (isObject) {
    return (
      <Accordion allowMultiple defaultIndex={isRoot ? [0] : []} mb={4}>
        {Object.entries(data).map(([key, value]) => (
          <AccordionItem key={key}>
            <AccordionButton>
              <HStack w="100%">
                <EditableKey
                  currentKey={key}
                  path={path}
                  handleRenameField={handleRenameField}
                  isArrayItem={isArrayItem}
                  parentArrayPath={parentArrayPath}
                />
                <Spacer />
                <IconButton
                  icon={<DeleteIcon />}
                  onClick={() =>
                    handleDeleteField([...path, key], null, isArrayItem, parentArrayPath)
                  }
                  aria-label="Delete"
                  colorScheme="red"
                  size="sm"
                />
                <IconButton
                  icon={<ArrowUpDownIcon />}
                  onClick={() => handleMoveField([...path, key])}
                  aria-label="Move"
                  size="sm"
                />
                <AccordionIcon />
              </HStack>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <RenderData
                data={value}
                path={[...path, key]}
                handleRenameField={handleRenameField}
                handleEditValue={handleEditValue}
                handleDeleteField={handleDeleteField}
                handleMoveField={handleMoveField}
                isArrayItem={isArrayItem}
                parentArrayPath={parentArrayPath}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    );
  }

  // Handle primitive data types
  return (
    <Flex alignItems="center" mb={2} pl={4}>
      <Input
        value={data}
        onChange={(e) =>
          handleEditValue(path, e.target.value, isArrayItem, parentArrayPath)
        }
        variant="outline"
        mr={2}
        color="black"
        size="sm"
        flex="1"
      />
      <IconButton
        icon={<DeleteIcon />}
        onClick={() =>
          handleDeleteField(path, null, isArrayItem, parentArrayPath)
        }
        aria-label="Delete"
        colorScheme="red"
        size="sm"
      />
    </Flex>
  );
};

/**
 * DataEditorComponent
 * Main component for uploading, editing, and downloading files.
 */
const DataEditorComponent = () => {
  const [fileContent, setFileContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [dataStructure, setDataStructure] = useState({}); // Initialize with empty object
  const toast = useToast();

  /**
   * Handle file upload
   */
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      const fileExt = file.name.split('.').pop().toLowerCase();
      setFileType(fileExt);

      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        setFileContent(content);
      };
      reader.readAsText(file);
    }
  };

  /**
   * Parse file content whenever it changes
   */
  useEffect(() => {
    if (fileContent) {
      if (fileType === 'json') {
        try {
          const jsonData = JSON.parse(fileContent);
          setDataStructure(jsonData);
        } catch (error) {
          console.error('Error parsing JSON:', error);
          toast({
            title: 'Error parsing JSON file.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          setDataStructure({});
        }
      } else if (fileType === 'xml') {
        parseString(fileContent, { explicitArray: false }, (err, result) => {
          if (err) {
            console.error('Error parsing XML:', err);
            toast({
              title: 'Error parsing XML file.',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            setDataStructure({});
          } else {
            setDataStructure(result);
          }
        });
      } else if (fileType === 'csv') {
        const csvData = csvToJson(fileContent);
        setDataStructure(csvData);
      } else {
        console.error('Unsupported file type');
        toast({
          title: 'Unsupported file type.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
        setDataStructure({});
      }
    }
  }, [fileContent, fileType, toast]);

  /**
   * Convert CSV to JSON
   */
  const csvToJson = (csv) => {
    const lines = csv.split('\n').filter((line) => line.trim() !== '');
    const headers = lines[0].split(',').map((header) => header.trim());
    const jsonData = lines.slice(1).map((line) => {
      const values = line.split(',').map((value) => value.trim());
      const obj = {};
      headers.forEach((header, index) => {
        obj[header] = values[index] || '';
      });
      return obj;
    });
    return jsonData;
  };

  /**
   * Convert JSON to CSV
   */
  const jsonToCsv = (json) => {
    if (!Array.isArray(json)) {
      json = [json];
    }
    const headers = Object.keys(json[0]);
    const csvRows = [];
    csvRows.push(headers.join(','));
    json.forEach((obj) => {
      const row = headers.map((header) => {
        const value = obj[header];
        if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
          return `"${value.replace(/"/g, '""')}"`;
        }
        return value;
      });
      csvRows.push(row.join(','));
    });
    return csvRows.join('\n');
  };

  /**
   * Handle renaming a field
   */
  const handleRenameField = useCallback(
    (path, oldKey, newKey, isArrayItem = false, parentArrayPath = []) => {
      if (!newKey) {
        toast({
          title: 'Field name cannot be empty.',
          status: 'warning',
          duration: 3000,
          isClosable: true,
        });
        return;
      }

      if (oldKey === newKey) {
        return;
      }

      const newDataStructure = _.cloneDeep(dataStructure);

      // Rename the key in the data structure
      const updatedData = renameNestedKey(newDataStructure, parentArrayPath, oldKey, newKey);

      if (updatedData !== newDataStructure) {
        setDataStructure(updatedData);
      } else {
        toast({
          title: `Key "${newKey}" already exists or does not exist.`,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [dataStructure, toast]
  );

  /**
   * Handle editing a value
   */
  const handleEditValue = useCallback(
    (path, newValue, isArrayItem = false, parentArrayPath = []) => {
      const newDataStructure = _.cloneDeep(dataStructure);

      // Set the new value at the specified path
      const updatedData = setNested(newDataStructure, path, newValue);

      setDataStructure(updatedData);
    },
    [dataStructure]
  );

  /**
   * Handle deleting a field or value
   */
  const handleDeleteField = useCallback(
    (path, key = null, isArrayItem = false, parentArrayPath = []) => {
      const newDataStructure = _.cloneDeep(dataStructure);

      // Delete the key or value at the specified path
      const updatedData = deleteNested(newDataStructure, path);

      setDataStructure(updatedData);
    },
    [dataStructure]
  );

  /**
   * Handle moving a field (functionality not implemented)
   */
  const handleMoveField = useCallback(
    (path) => {
      toast({
        title: 'Move functionality is not implemented in this example.',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    },
    [toast]
  );

  /**
   * Handle downloading the modified data
   */
  const handleDownload = () => {
    let content = '';
    let mimeType = '';
    if (fileType === 'json') {
      content = JSON.stringify(dataStructure, null, 2);
      mimeType = 'application/json';
    } else if (fileType === 'xml') {
      const builder = new Builder();
      content = builder.buildObject(dataStructure);
      mimeType = 'application/xml';
    } else if (fileType === 'csv') {
      content = jsonToCsv(dataStructure);
      mimeType = 'text/csv';
    } else {
      console.error('Unsupported file type for download');
      toast({
        title: 'Unsupported file type for download.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const blob = new Blob([content], { type: mimeType });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = `modified_${fileName}`;
    document.body.appendChild(a); // Append to body to ensure it works in Firefox
    a.click();
    a.remove(); // Remove the element after clicking
    URL.revokeObjectURL(url);
  };

  /**
   * Debugging: Log dataStructure whenever it changes
   */
  useEffect(() => {
    console.log('Updated dataStructure:', dataStructure);
  }, [dataStructure]);

  return (
    <ChakraProvider theme={customTheme}>
      <Box bg="gray.50" minH="100vh">
        {/* Header */}
        <Flex
          bg="teal.500"
          color="white"
          alignItems="center"
          px={4}
          py={2}
          mb={4}
          justifyContent="space-between"
        >
          <Heading size="md">Data Editor Tool</Heading>
          <Button
            leftIcon={<AttachmentIcon />}
            as="label"
            colorScheme="teal"
            variant="solid"
            cursor="pointer"
          >
            Upload File
            <Input type="file" hidden onChange={handleFileUpload} accept=".json,.xml,.csv" />
          </Button>
        </Flex>

        {/* Main Content */}
        <Box px={4}>
          <Box>
            <Heading size="lg" mb={4} color="black">
              {fileName ? `Editing: ${fileName}` : 'Data Structure'}
            </Heading>
            <Box borderWidth="1px" borderRadius="lg" p={4} bg="white">
              <RenderData
                data={dataStructure}
                path={[]}
                isRoot
                handleRenameField={handleRenameField}
                handleEditValue={handleEditValue}
                handleDeleteField={handleDeleteField}
                handleMoveField={handleMoveField}
              />
            </Box>
            {fileName && (
              <Button
                leftIcon={<DownloadIcon />}
                colorScheme="teal"
                mt={4}
                onClick={handleDownload}
              >
                Download Modified File
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default DataEditorComponent;
