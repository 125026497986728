// src/components/Dashboard.js

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  fetchProjectsByUserId,
  fetchUserById,
  deleteProjectById,
  toggleProjectStatus,
  updateProject,
} from '../services/dashboardService';
import {
  fetchCanvasesByUserId,
  fetchCanvasesByProjectId,
} from '../services/canvasService';
import CreateProject from './CreateProject';
// Remove the ReadMore import since we'll implement it directly
import '../CSS/Dashboard.css';
import { saveAs } from 'file-saver';
import { FaPlus, FaDownload, FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import Footer from './Footer';
import { AuthContext } from '../context/AuthContext';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';

const Dashboard = () => {
  const { auth, logout } = useContext(AuthContext);
  const userId = auth.userId;

  const [projects, setProjects] = useState([]);
  const [canvases, setCanvases] = useState([]); // All canvases (owned and shared)
  const [ownedCanvases, setOwnedCanvases] = useState([]);
  const [sharedCanvases, setSharedCanvases] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('name-asc');
  const [selectedProject, setSelectedProject] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editProjectData, setEditProjectData] = useState(null);

  const navigate = useNavigate();

  // Modal state variables
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  // State Variables for Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 10;

  // State Variables for Additional Filters
  const [filterPriority, setFilterPriority] = useState('All');

  // State variables for charts
  const [activationData, setActivationData] = useState([]);
  const [priorityData, setPriorityData] = useState([]);

  // State variable for expanded projects and their canvases
  const [projectCanvases, setProjectCanvases] = useState({});

  // State to track expanded descriptions
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  // Fetch user's own projects
  const getProjects = async () => {
    const token = auth.token;
    if (!token) {
      setError('User is not authenticated');
      setLoading(false);
      return;
    }

    try {
      const data = await fetchProjectsByUserId(userId, token);
      if (Array.isArray(data)) {
        setProjects(data);
      } else {
        console.error('Expected an array but got:', data);
        setProjects([]);
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching projects:', err);
      setError(err.message);
      setLoading(false);
    }
  };

  // Fetch all canvases (owned and shared)
  const getCanvases = async () => {
    const token = auth.token;
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    try {
      const fetchedCanvases = await fetchCanvasesByUserId(userId, token);
      if (Array.isArray(fetchedCanvases)) {
        setCanvases(fetchedCanvases);

        // Separate owned and shared canvases
        const owned = fetchedCanvases.filter(
          (canvas) => canvas.userId._id === userId
        );

        const shared = fetchedCanvases.filter(
          (canvas) => canvas.sharedWith && canvas.sharedWith.includes(userId)
        );

        setOwnedCanvases(owned);
        setSharedCanvases(shared);
      } else {
        setCanvases([]);
        setOwnedCanvases([]);
        setSharedCanvases([]);
      }
    } catch (err) {
      console.error('Error fetching canvases:', err);
      setError(err.message);
      setCanvases([]);
      setOwnedCanvases([]);
      setSharedCanvases([]);
    }
  };

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        await fetchUserById(userId, auth.token);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch user information');
      }
    };

    if (auth.isAuthenticated) {
      getUserInfo();
      getProjects(); // Fetch user's own projects
      getCanvases(); // Fetch all canvases (owned and shared)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, auth.isAuthenticated, auth.token]);

  // Prepare data for charts
  useEffect(() => {
    const prepareChartData = () => {
      const activationCounts = { Active: 0, Inactive: 0 };
      const priorityCounts = {};

      projects.forEach((project) => {
        // Count activation status
        if (project.isActive) {
          activationCounts['Active'] += 1;
        } else {
          activationCounts['Inactive'] += 1;
        }

        // Count priorities
        const priority = project.priority || 'Normal';
        priorityCounts[priority] = (priorityCounts[priority] || 0) + 1;
      });

      // Convert to array format for Recharts
      const activationDataArray = Object.keys(activationCounts).map((key) => ({
        name: key,
        value: activationCounts[key],
      }));

      const priorityDataArray = Object.keys(priorityCounts).map((key) => ({
        name: key,
        value: priorityCounts[key],
      }));

      setActivationData(activationDataArray);
      setPriorityData(priorityDataArray);
    };

    if (projects.length > 0) {
      prepareChartData();
    }
  }, [projects]);

  // Combine search, sort, and filters into a single useEffect
  useEffect(() => {
    let updatedProjects = [...projects];

    // Apply search filtering
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      updatedProjects = updatedProjects.filter(
        (project) =>
          (project.name && project.name.toLowerCase().includes(query)) ||
          (project.description && project.description.toLowerCase().includes(query))
      );
    }

    // Apply additional filters
    if (filterPriority !== 'All') {
      updatedProjects = updatedProjects.filter(
        (project) => project.priority === filterPriority
      );
    }

    // Apply sorting
    switch (sortOption) {
      case 'name-asc':
        updatedProjects.sort((a, b) => (a.name || '').localeCompare(b.name || ''));
        break;
      case 'name-desc':
        updatedProjects.sort((a, b) => (b.name || '').localeCompare(a.name || ''));
        break;
      case 'date-asc':
        updatedProjects.sort(
          (a, b) => new Date(a.createdAt || 0) - new Date(b.createdAt || 0)
        );
        break;
      case 'date-desc':
        updatedProjects.sort(
          (a, b) => new Date(b.createdAt || 0) - new Date(a.createdAt || 0)
        );
        break;
      case 'priority-asc':
        updatedProjects.sort((a, b) =>
          (a.priority || 'Normal').localeCompare(b.priority || 'Normal')
        );
        break;
      case 'priority-desc':
        updatedProjects.sort((a, b) =>
          (b.priority || 'Normal').localeCompare(a.priority || 'Normal')
        );
        break;
      default:
        break;
    }

    setFilteredProjects(updatedProjects);
    setCurrentPage(1);
  }, [projects, searchQuery, sortOption, filterPriority]);

  // Handle Logout
  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  // Open the Create Project modal
  const openCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  // Close the Create Project modal
  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleProjectClick = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  const handleEditProject = (project) => {
    setEditProjectData(project);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditProjectData(null);
  };

  const handleSaveEdit = async () => {
    const token = auth.token;
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    try {
      // Save the updated project to the backend
      await updateProject(editProjectData._id, editProjectData, token);

      // Update the projects state locally
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project._id === editProjectData._id ? editProjectData : project
        )
      );

      closeEditModal(); // Close the modal after a successful update
    } catch (err) {
      console.error('Error updating project:', err);
      alert(err.message || 'Failed to update project.');
    }
  };

  const handleDeleteProject = async () => {
    const token = auth.token;
    if (!token) {
      setError('User is not authenticated');
      closeModal();
      return;
    }

    try {
      await deleteProjectById(selectedProject._id, token);
      setProjects(projects.filter((p) => p._id !== selectedProject._id));
      alert(`Project "${selectedProject.name}" has been deleted.`);
      closeModal();
    } catch (err) {
      console.error('Error deleting project:', err);
      alert(err.message || 'An error occurred.');
      closeModal();
    }
  };

  const openModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };

  // Handle the creation of a new project
  const handleCreateProject = async (newProject) => {
    const token = auth.token;
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    try {
      closeCreateModal();
      await getProjects(); // Refetch the projects from the backend
    } catch (err) {
      console.error('Error creating project:', err);
      alert(err.message || 'Failed to create project.');
    }
  };

  // Utility function to escape CSV fields
  const escapeCSVField = (field) => {
    if (typeof field === 'string') {
      const escapedField = field.replace(/"/g, '""');
      if (escapedField.search(/("|,|\n)/g) >= 0) {
        return `"${escapedField}"`;
      }
      return escapedField;
    }
    return field;
  };

  // Handle CSV Export
  const exportToCSV = () => {
    if (filteredProjects.length === 0) {
      alert('No projects to export.');
      return;
    }

    const headers = [
      'Name',
      'Description',
      'Start Date',
      'End Date',
      'Priority',
      'Created At',
    ];

    const rows = filteredProjects.map((project) => [
      escapeCSVField(project.name || ''),
      escapeCSVField(project.description || ''),
      project.startDate ? new Date(project.startDate).toLocaleDateString() : '',
      project.endDate ? new Date(project.endDate).toLocaleDateString() : '',
      escapeCSVField(project.priority || 'Normal'),
      project.createdAt ? new Date(project.createdAt).toLocaleDateString() : '',
    ]);

    const csvContent = [headers, ...rows].map((e) => e.join(',')).join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'projects.csv');
  };

  // Calculate pagination details
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Toggle Project Active Status
  const toggleProjectActiveStatus = async (projectId) => {
    const token = auth.token;
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    try {
      await toggleProjectStatus(projectId, token);

      // Update the project's isActive status locally
      setProjects((prevProjects) =>
        prevProjects.map((project) =>
          project._id === projectId
            ? { ...project, isActive: !project.isActive }
            : project
        )
      );
    } catch (err) {
      console.error('Error toggling project status:', err);
      alert(err.message || 'Failed to update project status.');
    }
  };

  // Define colors for the charts
  const COLORS = ['#2f80ed', '#eb5757', '#f2994a', '#9b51e0', '#27ae60'];

  // Handle expanding/collapsing projects to show/hide canvases
  const toggleProjectExpansion = async (projectId) => {
    if (projectCanvases[projectId]) {
      // Collapse the project
      setProjectCanvases((prevCanvases) => {
        const newCanvases = { ...prevCanvases };
        delete newCanvases[projectId];
        return newCanvases;
      });
    } else {
      // Expand the project and fetch canvases
      try {
        const canvases = await fetchCanvasesByProjectId(projectId, auth.token);
        setProjectCanvases((prevCanvases) => ({
          ...prevCanvases,
          [projectId]: canvases,
        }));
      } catch (err) {
        console.error('Error fetching canvases:', err);
        alert('Failed to fetch canvases for this project.');
      }
    }
  };

  // Handle clicking on a canvas
  const handleCanvasClick = (canvasId, ownerUserId) => {
    navigate(`/canvas/${ownerUserId}/${canvasId}`);
  };

  // Toggle the expanded state of a project's description
  const toggleDescription = (projectId) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [projectId]: !prevState[projectId],
    }));
  };

  // Helper function to render truncated or full description
  const renderDescription = (description, projectId) => {
    const maxLength = 100; // Adjust as needed
    if (!description) return 'N/A';

    const isExpanded = expandedDescriptions[projectId];
    if (description.length <= maxLength) {
      return description;
    }

    const displayedText = isExpanded
      ? description
      : `${description.substring(0, maxLength)}...`;

    return (
      <>
        {displayedText}{' '}
        <span
          className="read-more-toggle"
          onClick={() => toggleDescription(projectId)}
        >
          {isExpanded ? 'Show Less' : 'Read More'}
        </span>
      </>
    );
  };

  if (loading) {
    return (
      <div className="dashboard-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="dashboard-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <div className="dashboard-container">
      {/* Main Content */}
      <main className="dashboard-main">
        <h1>Integration Projects</h1>

        {/* Charts */}
        <div className="charts-container">
          <div className="chart-card">
            <h3>Projects by Activation</h3>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={activationData}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={80}
                  labelLine={false}
                  label={({ name, percent }) =>
                    `${name}: ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {activationData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>

          <div className="chart-card">
            <h3>Projects by Priority</h3>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                data={priorityData}
                margin={{ top: 10, right: 10, left: -20, bottom: 0 }}
              >
                <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                <YAxis allowDecimals={false} tick={{ fontSize: 12 }} />
                <Tooltip />
                <Legend wrapperStyle={{ fontSize: '12px' }} />
                <Bar dataKey="value" fill="#2f80ed" barSize={20} />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Controls */}
        <div className="controls">
          <input
            type="text"
            placeholder="Search projects..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
          <select
            value={filterPriority}
            onChange={(e) => setFilterPriority(e.target.value)}
            className="filter-select"
          >
            <option value="All">All Priorities</option>
            <option value="High">High</option>
            <option value="Normal">Normal</option>
            <option value="Low">Low</option>
          </select>
          <select
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
            className="sort-select"
          >
            <option value="name-asc">Name ↑</option>
            <option value="name-desc">Name ↓</option>
            <option value="date-asc">Date ↑</option>
            <option value="date-desc">Date ↓</option>
            <option value="priority-asc">Priority ↑</option>
            <option value="priority-desc">Priority ↓</option>
          </select>
          <button className="new-project-button" onClick={openCreateModal}>
            <FaPlus /> New Project
          </button>
          <button className="export-button" onClick={exportToCSV}>
            <FaDownload /> Export CSV
          </button>
        </div>

        {filteredProjects.length === 0 ? (
          <p className="no-projects-message">
            No projects found. Please create a new project.
          </p>
        ) : (
          <>
            {/* Projects Table */}
            <div className="projects-table">
              <table>
                <thead>
                  <tr>
                    <th></th> {/* Column for expand/collapse icon */}
                    <th>Active</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Priority</th>
                    <th>Created At</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentProjects.map((project) => (
                    <React.Fragment key={project._id}>
                      <tr>
                        <td>
                          {/* Expand/Collapse Icon */}
                          <button
                            className="expand-button"
                            onClick={() => toggleProjectExpansion(project._id)}
                          >
                            {projectCanvases[project._id] ? '-' : '+'}
                          </button>
                        </td>
                        <td>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={project.isActive}
                              onChange={() => toggleProjectActiveStatus(project._id)}
                            />
                            <span className="slider round"></span>
                          </label>
                        </td>
                        <td>
                          <span
                            className="project-name-link"
                            onClick={() => handleProjectClick(project._id)}
                          >
                            {project.name || 'Unnamed Project'}
                          </span>
                        </td>
                        <td>
                          {/* Implement Read More functionality directly */}
                          {renderDescription(project.description, project._id)}
                        </td>
                        <td>{project.priority || 'N/A'}</td>
                        <td>
                          {project.createdAt
                            ? new Date(project.createdAt).toLocaleDateString()
                            : 'N/A'}
                        </td>
                        <td>
                          {project.startDate
                            ? new Date(project.startDate).toLocaleDateString()
                            : 'Not set'}
                        </td>
                        <td>
                          {project.endDate
                            ? new Date(project.endDate).toLocaleDateString()
                            : 'Not set'}
                        </td>
                        <td>
                          {/* Wrapped Action Buttons */}
                          <div className="action-buttons">
                            <button
                              className="action-button view"
                              onClick={() => handleProjectClick(project._id)}
                              title="View Project"
                            >
                              <FaEye />
                            </button>
                            <button
                              className="action-button edit"
                              onClick={() => handleEditProject(project)}
                              title="Edit Project"
                            >
                              <FaEdit />
                            </button>
                            <button
                              className="action-button delete"
                              onClick={() => openModal(project)}
                              title="Delete Project"
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </td>
                      </tr>
                      {/* Render canvases if the project is expanded */}
                      {projectCanvases[project._id] && (
                        <tr>
                          <td colSpan="9">
                            {/* Adjust colSpan to match the number of columns */}
                            <table className="canvases-table">
                              <thead>
                                <tr>
                                  <th>Canvas Name</th>
                                  <th>Platform</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                {projectCanvases[project._id].map((canvas) => (
                                  <tr key={canvas._id}>
                                    <td>
                                      <span
                                        className="project-name-link"
                                        onClick={() =>
                                          handleCanvasClick(canvas._id, canvas.userId._id)
                                        }
                                      >
                                        {canvas.flowName || 'Unnamed Canvas'}
                                      </span>
                                    </td>
                                    <td>{canvas.platform || 'N/A'}</td>
                                    <td>
                                      <button
                                        className="action-button view"
                                        onClick={() =>
                                          handleCanvasClick(canvas._id, canvas.userId._id)
                                        }
                                        title="View Canvas"
                                      >
                                        <FaEye />
                                      </button>
                                      {/* Add other canvas actions if needed */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>

            {/* Pagination Controls */}
            <div className="pagination">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                Previous
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button
                  key={index + 1}
                  onClick={() => paginate(index + 1)}
                  className={`pagination-button ${currentPage === index + 1 ? 'active' : ''
                    }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                Next
              </button>
            </div>
          </>
        )}

        {/* Shared with Me Section */}
        <div className="shared-canvases-section">
          <h2>Shared with Me</h2>
          {sharedCanvases.length === 0 ? (
            <p>No canvases have been shared with you.</p>
          ) : (
            <div className="shared-canvases-table">
              <table>
                <thead>
                  <tr>
                    <th>Canvas Name</th>
                    <th>Owner</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sharedCanvases.map((canvas) => (
                    <tr key={canvas._id}>
                      <td>
                        <span
                          className="project-name-link"
                          onClick={() =>
                            handleCanvasClick(
                              canvas._id,
                              canvas.userId ? canvas.userId._id : ''
                            )
                          }
                        >
                          {canvas.flowName || 'Unnamed Canvas'}
                        </span>
                      </td>
                      <td>
                        {canvas.userId
                          ? canvas.userId.name || canvas.userId.email || 'Unknown'
                          : 'Unknown Owner'}
                      </td>
                      <td>
                        <button
                          className="action-button view"
                          onClick={() =>
                            handleCanvasClick(
                              canvas._id,
                              canvas.userId ? canvas.userId._id : ''
                            )
                          }
                          title="View Canvas"
                        >
                          <FaEye />
                        </button>
                        {/* Additional actions if needed */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>

      {/* Footer */}
      <Footer />

      {/* Delete Confirmation Modal */}
      {isModalOpen && selectedProject && (
        <div className="modal-overlay" onClick={closeModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
          >
            <h2 id="modal-title">Delete Project</h2>
            <p>
              Are you sure you want to delete the project "
              <strong>{selectedProject.name || 'Unnamed Project'}</strong>"? This
              action cannot be undone.
            </p>
            <div className="modal-actions">
              <button className="cancel-button" onClick={closeModal}>
                Cancel
              </button>
              <button className="confirm-button" onClick={handleDeleteProject}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Edit Project Modal */}
      {isEditModalOpen && editProjectData && (
        <div className="modal-overlay" onClick={closeEditModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="edit-modal-title"
          >
            <h2 id="edit-modal-title">Edit Project</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveEdit();
              }}
            >
              <label>
                Name:
                <input
                  type="text"
                  value={editProjectData.name}
                  onChange={(e) =>
                    setEditProjectData({ ...editProjectData, name: e.target.value })
                  }
                  required
                />
              </label>
              <label>
                Description:
                <textarea
                  value={editProjectData.description}
                  onChange={(e) =>
                    setEditProjectData({
                      ...editProjectData,
                      description: e.target.value,
                    })
                  }
                />
              </label>
              <label>
                Priority:
                <select
                  value={editProjectData.priority}
                  onChange={(e) =>
                    setEditProjectData({ ...editProjectData, priority: e.target.value })
                  }
                >
                  <option value="High">High</option>
                  <option value="Normal">Normal</option>
                  <option value="Low">Low</option>
                </select>
              </label>
              {/* Added Start Date Field */}
              <label>
                Start Date:
                <input
                  type="date"
                  value={
                    editProjectData.startDate
                      ? new Date(editProjectData.startDate)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  onChange={(e) =>
                    setEditProjectData({ ...editProjectData, startDate: e.target.value })
                  }
                />
              </label>
              {/* Added End Date Field */}
              <label>
                End Date:
                <input
                  type="date"
                  value={
                    editProjectData.endDate
                      ? new Date(editProjectData.endDate)
                          .toISOString()
                          .split('T')[0]
                      : ''
                  }
                  onChange={(e) =>
                    setEditProjectData({ ...editProjectData, endDate: e.target.value })
                  }
                />
              </label>
              <div className="modal-actions">
                <button type="button" className="cancel-button" onClick={closeEditModal}>
                  Cancel
                </button>
                <button type="submit" className="confirm-button">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Create Project Modal */}
      {isCreateModalOpen && (
        <div className="modal-overlay" onClick={closeCreateModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="create-modal-title"
          >
            <CreateProject
              userId={userId}
              onClose={closeCreateModal}
              onCreate={handleCreateProject}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
