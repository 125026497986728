import {
    AiFillDatabase,
    AiOutlineApi,
    AiOutlineBranches,
    AiOutlineCloudServer,
} from 'react-icons/ai';
import {
    FaFileAlt,
    FaCode,
    FaEnvelope,
    FaSms,
    FaStickyNote,
    FaBoxOpen, // Boomi Icon
    FaCube, // MuleSoft Icon
    FaMagic, // Make.com Icon
    FaExchangeAlt, // For Data Routing
    FaSyncAlt, // For Data Synchronization
    FaTasks,
    FaStop, // Replacing GiParallelTasks
} from 'react-icons/fa';
import { MdTransform, MdDataUsage, MdLoop, MdError } from 'react-icons/md';
import { RiFlowChart } from 'react-icons/ri';
// Removed GiParallelTasks as it does not exist
// import { GiParallelTasks } from 'react-icons/gi'; // This line is removed

// Define unique numeric IDs for all tools with distinct tools per iPaaS
export const toolCategories = {
    Boomi: [
        {
            category: 'Connectors',
            tools: [
                // Existing s
                {
                    id: 6,
                    name: 'Salesforce',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 100, name: 'Salesforce Create a Record' },
                        { id: 101, name: 'Salesforce Update a Record' },
                        { id: 102, name: 'Salesforce Delete a Record' },
                    ],
                },
                {
                    id: 2,
                    name: 'SAP',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 103, name: 'SAP Create a Record' },
                        { id: 104, name: 'SAP Update a Record' },
                        { id: 105, name: 'SAP Delete a Record' },
                    ],
                },
                {
                    id: 3,
                    name: 'Database',
                    type: 'connector',
                    icon: AiFillDatabase,
                    operations: [
                        { id: 106, name: 'Database Create a Record' },
                        { id: 107, name: 'Database Update a Record' },
                        { id: 108, name: 'Database Delete a Record' },
                    ],
                },
                {
                    id: 4,
                    name: 'HTTP Client',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 109, name: 'HTTP Send Request' },
                        { id: 110, name: 'HTTP Receive Response' },
                    ],
                },
                {
                    id: 5,
                    name: 'FTP Client',
                    type: 'connector',
                    icon: FaFileAlt,
                    operations: [
                        { id: 111, name: 'FTP Upload File' },
                        { id: 112, name: 'FTP Download File' },
                    ],
                },
                // CRM s
                {
                    id: 46,
                    name: 'Microsoft Dynamics 365 ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 139, name: 'Dynamics 365 Create a Record' },
                        { id: 140, name: 'Dynamics 365 Update a Record' },
                        { id: 141, name: 'Dynamics 365 Delete a Record' },
                    ],
                },
                {
                    id: 47,
                    name: 'Zoho CRM ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 142, name: 'Zoho CRM Create a Record' },
                        { id: 143, name: 'Zoho CRM Update a Record' },
                        { id: 144, name: 'Zoho CRM Delete a Record' },
                    ],
                },
                // ERP s
                {
                    id: 48,
                    name: 'Oracle ERP Cloud',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 145, name: 'Oracle ERP Create a Record' },
                        { id: 146, name: 'Oracle ERP Update a Record' },
                        { id: 147, name: 'Oracle ERP Delete a Record' },
                    ],
                },
                {
                    id: 49,
                    name: 'NetSuite',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 148, name: 'NetSuite Create a Record' },
                        { id: 149, name: 'NetSuite Update a Record' },
                        { id: 150, name: 'NetSuite Delete a Record' },
                    ],
                },
                // E-Commerce s
                {
                    id: 51,
                    name: 'Magento ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 154, name: 'Magento Sync Products' },
                        { id: 155, name: 'Magento Sync Orders' },
                        { id: 156, name: 'Magento Sync Customers' },
                    ],
                },
                {
                    id: 52,
                    name: 'Shopify',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 157, name: 'Shopify Sync Products' },
                        { id: 158, name: 'Shopify Sync Orders' },
                        { id: 159, name: 'Shopify Sync Customers' },
                    ],
                },
                {
                    id: 53,
                    name: 'WooCommerce ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 160, name: 'WooCommerce Sync Products' },
                        { id: 161, name: 'WooCommerce Sync Orders' },
                        { id: 162, name: 'WooCommerce Sync Customers' },
                    ],
                },
                // Database s
                {
                    id: 54,
                    name: 'MySQL',
                    type: 'connector',
                    icon: AiFillDatabase,
                    operations: [
                        { id: 163, name: 'MySQL Create Record' },
                        { id: 164, name: 'MySQL Update Record' },
                        { id: 165, name: 'MySQL Delete Record' },
                    ],
                },
                {
                    id: 55,
                    name: 'Microsoft SQL Server ',
                    type: 'connector',
                    icon: AiFillDatabase,
                    operations: [
                        { id: 166, name: 'SQL Server Create Record' },
                        { id: 167, name: 'SQL Server Update Record' },
                        { id: 168, name: 'SQL Server Delete Record' },
                    ],
                },
                {
                    id: 56,
                    name: 'Oracle Database ',
                    type: 'connector',
                    icon: AiFillDatabase,
                    operations: [
                        { id: 169, name: 'Oracle DB Create Record' },
                        { id: 170, name: 'Oracle DB Update Record' },
                        { id: 171, name: 'Oracle DB Delete Record' },
                    ],
                },
                // Cloud Services s
                {
                    id: 57,
                    name: 'AWS ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 172, name: 'AWS S3 Upload' },
                        { id: 300, name: 'AWS Redshift' },
                        { id: 173, name: 'AWS Lambda Invoke' },
                        { id: 174, name: 'AWS EC2 Manage' },
                    ],
                },
                {
                    id: 58,
                    name: 'Microsoft Azure ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 175, name: 'Azure Blob Storage' },
                        { id: 176, name: 'Azure Logic Apps' },
                        { id: 177, name: 'Azure SQL Management' },
                    ],
                },
                {
                    id: 59,
                    name: 'Google Cloud Platform ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 178, name: 'GCP BigQuery' },
                        { id: 179, name: 'GCP Cloud Storage' },
                        { id: 180, name: 'GCP Pub/Sub' },
                    ],
                },
                // Marketing Automation s
                {
                    id: 61,
                    name: 'Marketo ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 184, name: 'Marketo Sync Leads' },
                        { id: 185, name: 'Marketo Sync Campaigns' },
                        { id: 186, name: 'Marketo Sync Contacts' },
                    ],
                },
                {
                    id: 62,
                    name: 'HubSpot ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 187, name: 'HubSpot Sync Marketing Data' },
                        { id: 188, name: 'HubSpot Sync Sales Data' },
                        { id: 189, name: 'HubSpot Sync CRM Data' },
                    ],
                },
                {
                    id: 63,
                    name: 'Mailchimp ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 190, name: 'Mailchimp Sync Emails' },
                        { id: 191, name: 'Mailchimp Sync Campaigns' },
                        { id: 192, name: 'Mailchimp Sync Subscribers' },
                    ],
                },
                // Social Media s
                {
                    id: 64,
                    name: 'Facebook ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 193, name: 'Facebook Sync Posts' },
                        { id: 194, name: 'Facebook Sync Ads' },
                        { id: 195, name: 'Facebook Sync Insights' },
                    ],
                },
                {
                    id: 65,
                    name: 'Twitter ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 196, name: 'Twitter Sync Tweets' },
                        { id: 197, name: 'Twitter Sync Ads' },
                        { id: 198, name: 'Twitter Sync Followers' },
                    ],
                },
                {
                    id: 66,
                    name: 'LinkedIn ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 199, name: 'LinkedIn Sync Profiles' },
                        { id: 200, name: 'LinkedIn Sync Jobs' },
                        { id: 201, name: 'LinkedIn Sync Companies' },
                    ],
                },
                // File and Document s
                {
                    id: 67,
                    name: 'Box ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 202, name: 'Box Upload File' },
                        { id: 203, name: 'Box Download File' },
                        { id: 204, name: 'Box Sync Folders' },
                    ],
                },
                {
                    id: 68,
                    name: 'Dropbox ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 205, name: 'Dropbox Upload File' },
                        { id: 206, name: 'Dropbox Download File' },
                        { id: 207, name: 'Dropbox Sync Folders' },
                    ],
                },
                // Collaboration and Productivity s
                {
                    id: 69,
                    name: 'Microsoft Office 365 ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 208, name: 'Office 365 Sync Outlook' },
                        { id: 209, name: 'Office 365 Sync SharePoint' },
                        { id: 210, name: 'Office 365 Sync OneDrive' },
                    ],
                },
                {
                    id: 70,
                    name: 'Google Workspace ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 211, name: 'Google Workspace Sync Gmail' },
                        { id: 212, name: 'Google Workspace Sync Drive' },
                        { id: 213, name: 'Google Workspace Sync Sheets' },
                    ],
                },
                {
                    id: 71,
                    name: 'Slack ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 214, name: 'Slack Send Message' },
                        { id: 215, name: 'Slack Update Message' },
                        { id: 216, name: 'Slack Delete Message' },
                    ],
                },
                // Financial Services s
                {
                    id: 72,
                    name: 'QuickBooks ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 217, name: 'QuickBooks Sync Invoices' },
                        { id: 218, name: 'QuickBooks Sync Payments' },
                        { id: 219, name: 'QuickBooks Sync Expenses' },
                    ],
                },
                {
                    id: 73,
                    name: 'Xero ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 220, name: 'Xero Sync Invoices' },
                        { id: 221, name: 'Xero Sync Payments' },
                        { id: 222, name: 'Xero Sync Expenses' },
                    ],
                },
                {
                    id: 74,
                    name: 'PayPal ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 223, name: 'PayPal Process Payment' },
                        { id: 224, name: 'PayPal Refund Payment' },
                        { id: 225, name: 'PayPal Sync Transactions' },
                    ],
                },
                // Analytics and BI s
                {
                    id: 76,
                    name: 'Tableau ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 229, name: 'Tableau Sync Dashboards' },
                        { id: 230, name: 'Tableau Sync Data Sources' },
                        { id: 231, name: 'Tableau Sync Reports' },
                    ],
                },
                {
                    id: 77,
                    name: 'Power BI ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 232, name: 'Power BI Sync Dashboards' },
                        { id: 233, name: 'Power BI Sync Data Sources' },
                        { id: 234, name: 'Power BI Sync Reports' },
                    ],
                },
                {
                    id: 78,
                    name: 'Looker ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 235, name: 'Looker Sync Dashboards' },
                        { id: 236, name: 'Looker Sync Data Sources' },
                        { id: 237, name: 'Looker Sync Reports' },
                    ],
                },
                // Additional s as Needed...
                // You can continue adding s following the same structure
            ],
        },
        {
            category: 'Data Processing',
            tools: [
                { id: 1, name: 'Data Mapping', type: 'dataProcessing', icon: MdTransform },
                { id: 7, name: 'Data Transformation', type: 'dataProcessing', icon: MdTransform },
                { id: 8, name: 'Data Validation', type: 'dataProcessing', icon: MdDataUsage },
                // **New Data Processing Tools Added Below for MuleSoft**
                {
                    id: 104,
                    name: 'Data Enrichment',
                    type: 'dataProcessing',
                    icon: FaSyncAlt,
                },
                {
                    id: 105,
                    name: 'Data Aggregation',
                    type: 'dataProcessing',
                    icon: FaExchangeAlt,
                },
                {
                    id: 106,
                    name: 'Data Splitting',
                    type: 'dataProcessing',
                    icon: MdTransform,
                },
                {
                    id: 107,
                    name: 'Data Routing',
                    type: 'dataProcessing',
                    icon: FaExchangeAlt,
                },
                {
                    id: 108,
                    name: 'Data Filtering',
                    type: 'dataProcessing',
                    icon: MdDataUsage,
                },
                // Add more Data Processing tools as needed
            ],
        },
        {
            category: 'Flow Control',
            tools: [
                { id: 24, name: 'Branch', type: 'flowControl', icon: AiOutlineBranches },
                { id: 25, name: 'Decision', type: 'flowControl', icon: RiFlowChart },
                { id: 26, name: 'Loop', type: 'flowControl', icon: MdLoop },
                // **New Flow Control Tools Added Below for MuleSoft**
                {
                    id: 109,
                    name: 'Choice',
                    type: 'flowControl',
                    icon: RiFlowChart,
                },
                {
                    id: 110,
                    name: 'Try-Catch',
                    type: 'flowControl',
                    icon: MdError,
                },
                {
                    id: 111,
                    name: 'For Each',
                    type: 'flowControl',
                    icon: MdLoop,
                },
                {
                    id: 112,
                    name: 'Stop',
                    type: 'flowControl',
                    icon: FaStop,
                },
                {
                    id: 114,
                    name: 'Parallel For Each',
                    type: 'flowControl',
                    icon: FaTasks, // Replaced GiParallelTasks with FaTasks
                },
                {
                    id: 115,
                    name: 'Scheduler',
                    type: 'flowControl',
                    icon: AiOutlineApi,
                },
                // Add more Flow Control tools as needed
            ],
        },
        {
            category: 'Utilities',
            tools: [
                { id: 27, name: 'Logger', type: 'utility', icon: FaCode },
                { id: 28, name: 'Email Notifier', type: 'utility', icon: FaEnvelope },
                { id: 29, name: 'SMS Notifier', type: 'utility', icon: FaSms },
            ],
        },
        {
            category: 'Notes',
            tools: [
                { id: 30, name: 'Sticky Note', type: 'note', icon: FaStickyNote },
            ],
        },
    ],
    MuleSoft: [
        {
            category: 'Connectors',
            tools: [
                {
                    id: 16,
                    name: 'Salesforce ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 113, name: 'Create a Record' },
                        { id: 114, name: 'Update a Record' },
                        { id: 115, name: 'Delete a Record' },
                    ],
                },
                {
                    id: 17,
                    name: 'Oracle ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 116, name: 'Create a Record' },
                        { id: 117, name: 'Update a Record' },
                        { id: 118, name: 'Delete a Record' },
                    ],
                },
                {
                    id: 18,
                    name: 'Database ',
                    type: 'connector',
                    icon: AiFillDatabase,
                    operations: [
                        { id: 119, name: 'Create a Record' },
                        { id: 120, name: 'Update a Record' },
                        { id: 121, name: 'Delete a Record' },
                    ],
                },
                {
                    id: 19,
                    name: 'HTTP Client',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 122, name: 'Send Request' },
                        { id: 123, name: 'Receive Response' },
                    ],
                },
                {
                    id: 20,
                    name: 'FTP Client',
                    type: 'connector',
                    icon: FaFileAlt,
                    operations: [
                        { id: 124, name: 'Upload File' },
                        { id: 125, name: 'Download File' },
                    ],
                },
                // Existing MuleSoft s up to id:103
                // ... (Assume previous s are listed here)
                // **New MuleSoft s Added Below**
                {
                    id: 79,
                    name: 'ServiceNow ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 238, name: 'ServiceNow Create Incident' },
                        { id: 239, name: 'ServiceNow Update Incident' },
                        { id: 240, name: 'ServiceNow Delete Incident' },
                    ],
                },
                {
                    id: 80,
                    name: 'Workday ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 241, name: 'Workday Create Employee' },
                        { id: 242, name: 'Workday Update Employee' },
                        { id: 243, name: 'Workday Delete Employee' },
                    ],
                },
                {
                    id: 81,
                    name: 'Zendesk ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 244, name: 'Zendesk Create Ticket' },
                        { id: 245, name: 'Zendesk Update Ticket' },
                        { id: 246, name: 'Zendesk Delete Ticket' },
                    ],
                },
                {
                    id: 82,
                    name: 'PostgreSQL ',
                    type: 'connector',
                    icon: AiFillDatabase,
                    operations: [
                        { id: 247, name: 'PostgreSQL Create Record' },
                        { id: 248, name: 'PostgreSQL Update Record' },
                        { id: 249, name: 'PostgreSQL Delete Record' },
                    ],
                },
                {
                    id: 83,
                    name: 'MongoDB ',
                    type: 'connector',
                    icon: AiFillDatabase,
                    operations: [
                        { id: 250, name: 'MongoDB Insert Document' },
                        { id: 251, name: 'MongoDB Update Document' },
                        { id: 252, name: 'MongoDB Delete Document' },
                    ],
                },
                {
                    id: 84,
                    name: 'JMS ',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 253, name: 'JMS Send Message' },
                        { id: 254, name: 'JMS Receive Message' },
                        { id: 255, name: 'JMS Delete Message' },
                    ],
                },
                {
                    id: 85,
                    name: 'AMQP ',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 256, name: 'AMQP Publish Message' },
                        { id: 257, name: 'AMQP Subscribe Message' },
                        { id: 258, name: 'AMQP Acknowledge Message' },
                    ],
                },
                {
                    id: 86,
                    name: 'SMTP ',
                    type: 'connector',
                    icon: FaEnvelope,
                    operations: [
                        { id: 259, name: 'SMTP Send Email' },
                        { id: 260, name: 'SMTP Receive Email' },
                        { id: 261, name: 'SMTP Delete Email' },
                    ],
                },
                {
                    id: 87,
                    name: 'IBM MQ ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 262, name: 'IBM MQ Send Message' },
                        { id: 263, name: 'IBM MQ Receive Message' },
                        { id: 264, name: 'IBM MQ Delete Message' },
                    ],
                },
                {
                    id: 88,
                    name: 'Microsoft Dynamics 365 ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 265, name: 'Dynamics 365 Create Record' },
                        { id: 266, name: 'Dynamics 365 Update Record' },
                        { id: 267, name: 'Dynamics 365 Delete Record' },
                    ],
                },
                {
                    id: 89,
                    name: 'NetSuite ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 268, name: 'NetSuite Create Record' },
                        { id: 269, name: 'NetSuite Update Record' },
                        { id: 270, name: 'NetSuite Delete Record' },
                    ],
                },
                {
                    id: 90,
                    name: 'Slack ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 271, name: 'Slack Send Message' },
                        { id: 272, name: 'Slack Update Message' },
                        { id: 273, name: 'Slack Delete Message' },
                    ],
                },
                {
                    id: 91,
                    name: 'Twitter ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 274, name: 'Twitter Post Tweet' },
                        { id: 275, name: 'Twitter Update Tweet' },
                        { id: 276, name: 'Twitter Delete Tweet' },
                    ],
                },
                {
                    id: 92,
                    name: 'Microsoft Teams ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 277, name: 'Teams Send Message' },
                        { id: 278, name: 'Teams Update Message' },
                        { id: 279, name: 'Teams Delete Message' },
                    ],
                },
                {
                    id: 93,
                    name: 'REST API ',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 280, name: 'REST API Call GET' },
                        { id: 281, name: 'REST API Call POST' },
                        { id: 282, name: 'REST API Call DELETE' },
                    ],
                },
                {
                    id: 94,
                    name: 'SOAP ',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 283, name: 'SOAP API Call GET' },
                        { id: 284, name: 'SOAP API Call POST' },
                        { id: 285, name: 'SOAP API Call DELETE' },
                    ],
                },
                {
                    id: 95,
                    name: 'GraphQL ',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 286, name: 'GraphQL Query' },
                        { id: 287, name: 'GraphQL Mutation' },
                        { id: 288, name: 'GraphQL Subscription' },
                    ],
                },
                {
                    id: 96,
                    name: 'MQTT ',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 289, name: 'MQTT Publish' },
                        { id: 290, name: 'MQTT Subscribe' },
                        { id: 291, name: 'MQTT Unsubscribe' },
                    ],
                },
                {
                    id: 97,
                    name: 'Kafka ',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 292, name: 'Kafka Produce Message' },
                        { id: 293, name: 'Kafka Consume Message' },
                        { id: 294, name: 'Kafka Delete Message' },
                    ],
                },
                {
                    id: 98,
                    name: 'Azure Event Hubs ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 295, name: 'Azure Event Hubs Send Event' },
                        { id: 296, name: 'Azure Event Hubs Receive Event' },
                        { id: 297, name: 'Azure Event Hubs Delete Event' },
                    ],
                },
                {
                    id: 99,
                    name: 'Google Services ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 298, name: 'Google Drive Upload' },
                        { id: 299, name: 'Google Sheets Update' },
                        { id: 300, name: 'Google Calendar Create Event' },
                    ],
                },
                {
                    id: 100,
                    name: 'AWS S3 ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 301, name: 'AWS S3 Upload File' },
                        { id: 302, name: 'AWS S3 Download File' },
                        { id: 303, name: 'AWS S3 Delete File' },
                    ],
                },
                {
                    id: 101,
                    name: 'AWS DynamoDB ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 304, name: 'DynamoDB Put Item' },
                        { id: 305, name: 'DynamoDB Update Item' },
                        { id: 306, name: 'DynamoDB Delete Item' },
                    ],
                },
                {
                    id: 102,
                    name: 'Dropbox ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 307, name: 'Dropbox Upload File' },
                        { id: 308, name: 'Dropbox Download File' },
                        { id: 309, name: 'Dropbox Sync Folders' },
                    ],
                },
                {
                    id: 103,
                    name: 'Box ',
                    type: 'connector',
                    icon: FaFileAlt, // Using FaFileAlt as a placeholder
                    operations: [
                        { id: 310, name: 'Box Upload File' },
                        { id: 311, name: 'Box Download File' },
                        { id: 312, name: 'Box Sync Folders' },
                    ],
                },
                // Continue adding more MuleSoft s as needed
            ],
        },
        {
            category: 'Data Processing',
            tools: [
                { id: 1, name: 'Data Mapping', type: 'dataProcessing', icon: MdTransform },
                { id: 22, name: 'Data Transformation', type: 'dataProcessing', icon: MdTransform },
                { id: 23, name: 'Data Validation', type: 'dataProcessing', icon: MdDataUsage },
                // **New Data Processing Tools Added Below for MuleSoft**
                {
                    id: 104,
                    name: 'Data Enrichment',
                    type: 'dataProcessing',
                    icon: FaSyncAlt,
                },
                {
                    id: 105,
                    name: 'Data Aggregation',
                    type: 'dataProcessing',
                    icon: FaExchangeAlt,
                },
                {
                    id: 106,
                    name: 'Data Splitting',
                    type: 'dataProcessing',
                    icon: MdTransform,
                },
                {
                    id: 107,
                    name: 'Data Routing',
                    type: 'dataProcessing',
                    icon: FaExchangeAlt,
                },
                {
                    id: 108,
                    name: 'Data Filtering',
                    type: 'dataProcessing',
                    icon: MdDataUsage,
                },
                // Add more Data Processing tools as needed
            ],
        },
        {
            category: 'Flow Control',
            tools: [
                { id: 24, name: 'Branch', type: 'flowControl', icon: AiOutlineBranches },
                { id: 25, name: 'Decision', type: 'flowControl', icon: RiFlowChart },
                { id: 26, name: 'Loop', type: 'flowControl', icon: MdLoop },
                // **New Flow Control Tools Added Below for MuleSoft**
                {
                    id: 109,
                    name: 'Choice',
                    type: 'flowControl',
                    icon: RiFlowChart,
                },
                {
                    id: 110,
                    name: 'Try-Catch',
                    type: 'flowControl',
                    icon: MdError,
                },
                {
                    id: 111,
                    name: 'For Each',
                    type: 'flowControl',
                    icon: MdLoop,
                },
                {
                    id: 112,
                    name: 'Scatter-Gather',
                    type: 'flowControl',
                    icon: AiOutlineBranches,
                },
                {
                    id: 113,
                    name: 'Until Successful',
                    type: 'flowControl',
                    icon: MdLoop,
                },
                {
                    id: 114,
                    name: 'Parallel For Each',
                    type: 'flowControl',
                    icon: FaTasks, // Replaced GiParallelTasks with FaTasks
                },
                {
                    id: 115,
                    name: 'Scheduler',
                    type: 'flowControl',
                    icon: AiOutlineApi,
                },
                // Add more Flow Control tools as needed
            ],
        },
        {
            category: 'Utilities',
            tools: [
                { id: 27, name: 'Logger', type: 'utility', icon: FaCode },
                { id: 28, name: 'Email Notifier', type: 'utility', icon: FaEnvelope },
                { id: 29, name: 'SMS Notifier', type: 'utility', icon: FaSms },
            ],
        },
        {
            category: 'Notes',
            tools: [
                { id: 30, name: 'Sticky Note', type: 'note', icon: FaStickyNote },
            ],
        },
    ],
    'Make.com': [
        {
            category: 'Connectors',
            tools: [
                {
                    id: 31,
                    name: 'Google Sheets ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 126, name: 'Create a Record' },
                        { id: 127, name: 'Update a Record' },
                        { id: 128, name: 'Delete a Record' },
                    ],
                },
                {
                    id: 32,
                    name: 'Slack ',
                    type: 'connector',
                    icon: AiOutlineCloudServer,
                    operations: [
                        { id: 129, name: 'Send Message' },
                        { id: 130, name: 'Update Message' },
                        { id: 131, name: 'Delete Message' },
                    ],
                },
                {
                    id: 33,
                    name: 'Twitter ',
                    type: 'connector',
                    icon: AiFillDatabase,
                    operations: [
                        { id: 132, name: 'Post Tweet' },
                        { id: 133, name: 'Update Tweet' },
                        { id: 134, name: 'Delete Tweet' },
                    ],
                },
                {
                    id: 34,
                    name: 'Webhook Client',
                    type: 'connector',
                    icon: AiOutlineApi,
                    operations: [
                        { id: 135, name: 'Send Webhook' },
                        { id: 136, name: 'Receive Webhook' },
                    ],
                },
                {
                    id: 35,
                    name: 'FTP Client',
                    type: 'connector',
                    icon: FaFileAlt,
                    operations: [
                        { id: 137, name: 'Upload File' },
                        { id: 138, name: 'Download File' },
                    ],
                },
                // Additional Make.com s as Needed...
            ],
        },
        {
            category: 'Data Processing',
            tools: [
                { id: 36, name: 'Data Mapping', type: 'dataProcessing', icon: MdTransform },
                { id: 37, name: 'Data Transformation', type: 'dataProcessing', icon: MdTransform },
                { id: 38, name: 'Data Validation', type: 'dataProcessing', icon: MdDataUsage },
                // Add more Make.com Data Processing tools if needed
            ],
        },
        {
            category: 'Flow Control',
            tools: [
                { id: 39, name: 'Branch', type: 'flowControl', icon: AiOutlineBranches },
                { id: 40, name: 'Decision', type: 'flowControl', icon: RiFlowChart },
                { id: 41, name: 'Loop', type: 'flowControl', icon: MdLoop },
                // Add more Make.com Flow Control tools if needed
            ],
        },
        {
            category: 'Utilities',
            tools: [
                { id: 42, name: 'Logger', type: 'utility', icon: FaCode },
                { id: 43, name: 'Email Notifier', type: 'utility', icon: FaEnvelope },
                { id: 44, name: 'SMS Notifier', type: 'utility', icon: FaSms },
            ],
        },
        {
            category: 'Notes',
            tools: [
                { id: 45, name: 'Sticky Note', type: 'note', icon: FaStickyNote },
            ],
        },
    ],
};

// Icons for platforms
export const platformIcons = {
    Boomi: FaBoxOpen,
    MuleSoft: FaCube,
    'Make.com': FaMagic, // Added Make.com icon
};
