import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Importing Components
import Dashboard from './components/Dashboard';
import Canvas from './components/Canvas';
import CreateProject from './components/CreateProject';
import ProjectDetail from './components/ProjectDetail';
import NewUserPage from './components/NewUserPage';
import DataEditorComponent from './components/DataEditorComponent';
import LoginPage from './components/LoginPage';
import LandingPage from './components/LandingPage';
import ProfilePage from './components/ProfilePage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage'; // Import the Privacy Policy Page

// Importing Context and Route Protection
import { AuthProvider, AuthContext } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';

// Importing UI Library
import { ChakraProvider, Spinner, Center } from '@chakra-ui/react';

// Importing Layout
import HeaderLayout from './components/HeaderLayout';

// Importing Cookie Consent
import CookieConsent from "react-cookie-consent";

function AppRoutes() {
  const { loading } = useContext(AuthContext);

  if (loading) {
    // Show a loading spinner while checking authentication
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Routes>
      {/* Public Routes without Header */}
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/new-user" element={<NewUserPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} /> {/* Privacy Policy Route */}

      {/* Protected Routes with Header */}
      <Route element={<HeaderLayout />}>
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/project/:projectId"
          element={
            <ProtectedRoute>
              <ProjectDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        {/* Add more routes that require Header here */}
      </Route>

      {/* Other Protected Routes without Header */}
      <Route
        path="/canvas/:userId/:canvasId"
        element={
          <ProtectedRoute>
            <Canvas />
          </ProtectedRoute>
        }
      />
      <Route
        path="/tool"
        element={
          <ProtectedRoute>
            <DataEditorComponent />
          </ProtectedRoute>
        }
      />
      <Route
        path="/create-project"
        element={
          <ProtectedRoute>
            <CreateProject />
          </ProtectedRoute>
        }
      />

      {/* Fallback Route */}
      <Route path="*" element={<LandingPage />} />
    </Routes>
  );
}

function App() {
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  return (
    <ChakraProvider>
      <Router>
        <AuthProvider>
          {/* Cookie Consent Banner */}
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            enableDeclineButton
            onAccept={() => setCookiesAccepted(true)}
            onDecline={() => setCookiesAccepted(false)}
            cookieName="nexusync-cookies"
            style={{ background: "#2B373B", color: "#fff" }}
            buttonStyle={{
              background: "#4CAF50",
              color: "#fff",
              fontSize: "14px",
            }}
            declineButtonStyle={{
              background: "#f44336",
              color: "#fff",
              fontSize: "14px",
            }}
          >
            This website uses cookies for security (Google reCAPTCHA) and performance (Google Fonts). You can accept or decline these cookies.{" "}
            <a
              href="/privacy-policy"
              style={{ color: "#4CAF50", textDecoration: "underline" }}
            >
              Learn more
            </a>.
          </CookieConsent>

          {/* Conditionally Load Content */}
          {cookiesAccepted && (
            <script src="https://www.google.com/recaptcha/api.js" async defer></script>
          )}

          {/* Application Routes */}
          <AppRoutes />
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
}

export default App;
