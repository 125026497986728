// src/components/LoginPage.js

import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../services/UserService';
import '../CSS/LoginPage.css'; // Import the CSS file
import { AuthContext } from '../context/AuthContext'; // Corrected path

const LoginPage = () => { // Removed onClose prop
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // State for loading indicator
  const navigate = useNavigate();

  const { login } = useContext(AuthContext); // Access login function from AuthContext

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    setMessage(''); // Reset message

    try {
      const response = await loginUser(email, password);
      
      // Assuming loginUser returns an object with a token property
      const { token } = response;

      if (token) {
        login(token); // Update AuthContext with the new token
        setMessage('Login successful!');
        
        navigate('/dashboard'); // Redirect to the dashboard upon successful login
      } else {
        setMessage('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      
      // Customize error message based on error response
      if (error.message === 'Invalid credentials') { // Adjust based on your backend's error message
        setMessage('Invalid email or password.');
      } else {
        setMessage('An error occurred during login. Please try again later.');
      }
    } finally {
      setLoading(false); // End loading
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit} aria-labelledby="login-heading">
        <h2 id="login-heading">Login</h2>
        <label htmlFor="email">Email:</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          aria-required="true"
          placeholder="Enter your email"
        />
        <label htmlFor="password">Password:</label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          aria-required="true"
          placeholder="Enter your password"
        />
        <button type="submit" disabled={loading} className="login-button">
          {loading ? 'Logging in...' : 'Login'}
        </button>
        {message && <p className="login-message">{message}</p>}
      </form>
    </div>
  );
};

export default LoginPage;
