// src/services/UserService.js

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const createUser = async (name, email, password, recaptchaToken) => {
  const userData = {
    name,
    email,
    password,
    recaptchaToken, // Ensure the token is included
  };

  const response = await fetch(`${API_URL}/user`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const errorText = await response.text(); // Get the error response text
    console.error('Error response:', errorText); // Log the error
    throw new Error('Failed to create user');
  }

  return await response.json();
};

export const getUserDetails = async (userId, token) => {
  const response = await fetch(`${API_URL}/user/${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`, // Include the token for authentication
      'Content-Type': 'application/json',  // Good practice
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(errorText || 'Failed to fetch user details');
  }

  return await response.json();
};

export const loginUser = async (email, password) => {
  const userData = { email, password };

  const response = await fetch(`${API_URL}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(errorText || 'Failed to login');
  }

  const data = await response.json();

  // Store the token in localStorage
  localStorage.setItem('token', data.token);

  return data;
};

export const updateUser = async (userId, updatedData, token) => {
  const response = await fetch(`${API_URL}/user/${userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`, // Include the token for authentication
    },
    body: JSON.stringify(updatedData),
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(errorText || 'Failed to update user');
  }

  return await response.json();
};

export const deleteUser = async (userId, token) => {
  const response = await fetch(`${API_URL}/user/${userId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`, // Include the token for authentication
    },
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(errorText || 'Failed to delete user');
  }

  return await response.json();
};

/**
 * Submits user feedback.
 */
export const submitFeedback = async (message, token) => {
  const feedbackData = {
    message,
  };

  try {
    const response = await fetch(`${API_URL}/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Authenticate the request
      },
      body: JSON.stringify(feedbackData),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Feedback Submission Error:', errorText);
      throw new Error(errorText || 'Failed to submit feedback');
    }

    return await response.json();
  } catch (error) {
    console.error('Error submitting feedback:', error);
    throw error;
  }
};