import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { createUser } from '../services/UserService';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie'; // Using js-cookie for safer cookie handling
import '../CSS/NewUserPage.css';

// Use different reCAPTCHA site keys based on the environment
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY 

const NewUserPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    // Check for cookie consent using js-cookie
    const consent = Cookies.get('nexusync-cookies');
    if (consent === 'true') {
      setCookiesAccepted(true);
    }
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Enhanced password regex: at least 8 characters, one letter, one number, and one special character
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

  const validateField = (name, value) => {
    switch (name) {
      case 'name':
        if (!value.trim()) {
          return 'Name is required.';
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
          return 'Name can only contain letters and spaces.';
        } else {
          return '';
        }
      case 'email':
        if (!value.trim()) {
          return 'Email is required.';
        } else if (!emailRegex.test(value)) {
          return 'Please enter a valid email address.';
        } else {
          return '';
        }
      case 'password':
        if (!value) {
          return 'Password is required.';
        } else if (!passwordRegex.test(value)) {
          return 'Password must be at least 8 characters long, include at least one letter, one number, and one special character.';
        } else {
          return '';
        }
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    Object.keys(formData).forEach((field) => {
      const error = validateField(field, formData[field]);
      if (error) {
        validationErrors[field] = error;
      }
    });

    if (!recaptchaToken) {
      validationErrors.recaptcha = 'Please complete the reCAPTCHA.';
    }

    if (!agreed) {
      validationErrors.privacy = 'You must agree to the Privacy Policy to create an account.';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSuccessMessage('');
      return;
    }

    try {
      const response = await createUser(
        formData.name,
        formData.email,
        formData.password,
        recaptchaToken
      );
      setSuccessMessage('User created successfully!');
      setErrors({});
      setFormData({
        name: '',
        email: '',
        password: '',
      });
      setAgreed(false);
      setRecaptchaToken('');
    } catch (error) {
      // Log the error for debugging (ensure this doesn't expose sensitive info in production)
      console.error('Error creating user:', error);
      setErrors({ server: 'Error creating user. Please try again later.' });
      setSuccessMessage('');
    }
  };

  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
    setErrors((prevErrors) => ({ ...prevErrors, recaptcha: '' }));
  };

  const handleAgreementChange = (e) => {
    setAgreed(e.target.checked);
    if (e.target.checked) {
      setErrors((prevErrors) => ({ ...prevErrors, privacy: '' }));
    }
  };

  return (
    <div className="new-user-container">
      <form className="new-user-form" onSubmit={handleSubmit} noValidate>
        <h2>Create New User</h2>

        {/* Name Field */}
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            id="name"
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            aria-describedby="nameError"
            aria-invalid={errors.name ? 'true' : 'false'}
          />
          {errors.name && (
            <p className="error-message" id="nameError" role="alert">
              {errors.name}
            </p>
          )}
        </div>

        {/* Email Field */}
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            aria-describedby="emailError"
            aria-invalid={errors.email ? 'true' : 'false'}
          />
          {errors.email && (
            <p className="error-message" id="emailError" role="alert">
              {errors.email}
            </p>
          )}
        </div>

        {/* Password Field */}
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            id="password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            aria-describedby="passwordError"
            aria-invalid={errors.password ? 'true' : 'false'}
          />
          {errors.password && (
            <p className="error-message" id="passwordError" role="alert">
              {errors.password}
            </p>
          )}
        </div>

        {/* Conditional reCAPTCHA */}
        {cookiesAccepted ? (
          <div className="form-group">
            <ReCAPTCHA
              sitekey={RECAPTCHA_SITE_KEY}
              onChange={handleRecaptcha}
            />
            {errors.recaptcha && (
              <p className="error-message" role="alert">
                {errors.recaptcha}
              </p>
            )}
          </div>
        ) : (
          <p className="error-message">
            You need to accept cookies to use reCAPTCHA and create an account.
          </p>
        )}

        {/* Privacy Policy Agreement */}
        <div className="form-group privacy-policy-agreement">
          <input
            type="checkbox"
            id="privacyPolicy"
            checked={agreed}
            onChange={handleAgreementChange}
            aria-describedby="privacyError"
          />
          <label htmlFor="privacyPolicy">
            I agree to the{' '}
            <Link
              to="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="privacy-link"
            >
              Privacy Policy
            </Link>
          </label>
          {errors.privacy && (
            <p className="error-message" id="privacyError" role="alert">
              {errors.privacy}
            </p>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="submit-button"
          disabled={!cookiesAccepted}
        >
          Create User
        </button>

        {/* Success and Server Error Messages */}
        {successMessage && (
          <p className="success-message" role="status">
            {successMessage}
          </p>
        )}
        {errors.server && (
          <p className="error-message" role="alert">
            {errors.server}
          </p>
        )}
      </form>
    </div>
  );
};

export default NewUserPage;
