// Canvas.js

import React, { useState, useRef, useEffect, useCallback, useContext } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import '../CSS/Canvas.css';

import DataMappingComponent from './DataMapping/DataMappingComponent';
import { saveCanvasState } from '../services/canvasService';
import FlowNameEditor from './FlowNameEditor';
import StickyNote from './StickyNote';
import {
  Box,
  Button,
  VStack,
  HStack,
  Input,
  IconButton,
  Textarea,
  useToast,
  FormControl,
  FormLabel,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
  Icon,
} from '@chakra-ui/react';
import ToolSidebar from './ToolSidebar';
import {
  SearchIcon,
  AddIcon,
  MinusIcon,
  LockIcon,
  UnlockIcon,
  DeleteIcon,
  CloseIcon,
  InfoIcon,
} from '@chakra-ui/icons';
import { FaStickyNote } from 'react-icons/fa';
import Draggable from 'react-draggable';
import html2canvas from 'html2canvas';
import logoImage from '../images/logo.png';
import { AuthContext } from '../context/AuthContext';
import { getUserDetails } from '../services/UserService';
import CanvasHeader from './CanvasHeader';

const GRID_SIZE = 50;

// Utility function to generate unique IDs
const generateUniqueId = () => {
  return `tool_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
};

// Utility function to validate email format
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
const API_URL = process.env.REACT_APP_API_BASE_URL;
// Context Menu Component
const ContextMenu = ({ x, y, options }) => {
  return (
    <Box
      position="absolute"
      top={y}
      left={x}
      bg="white"
      boxShadow="md"
      borderRadius="md"
      zIndex={1000}
      role="menu"
      aria-label="Context Menu"
    >
      <VStack align="stretch" spacing={0}>
        {options.map((option, index) => (
          <Box
            key={index}
            p={2}
            _hover={{ backgroundColor: 'gray.100', cursor: 'pointer' }}
            onClick={option.onClick}
            role="menuitem"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter') option.onClick();
            }}
          >
            {option.label}
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

// Tool Properties Panel Component
const ToolPropertiesPanel = ({ tool, onUpdate }) => {
  const [name, setName] = useState(tool.name || '');
  const [description, setDescription] = useState(tool.description || '');
  const [mappings, setMappings] = useState(tool.mappings || {});

  useEffect(() => {
    setName(tool.name || '');
    setDescription(tool.description || '');
    setMappings(tool.mappings || {});
  }, [tool]);

  const handleSave = () => {
    if (!name.trim()) {
      alert('Tool name cannot be empty.');
      return;
    }
    onUpdate({ ...tool, name: name.trim(), description: description.trim(), mappings });
  };

  return (
    <Box p={4} borderLeft="1px solid #ddd" width="250px">
      <VStack spacing={4} align="stretch">
        <FormControl id="tool-name" isRequired>
          <FormLabel>Tool Name</FormLabel>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter tool name"
            variant="filled"
            aria-label="Tool Name"
          />
        </FormControl>
        <FormControl id="tool-description">
          <FormLabel>Tool Description</FormLabel>
          
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter tool description"
            variant="filled"
            resize="vertical"
            aria-label="Tool Description"
          />
          
        </FormControl>
        {tool.id === 1 && (
          <Box width="100%">
            <FormLabel>Data Mappings</FormLabel>
            <DataMappingComponent mappings={mappings} setMappings={setMappings} />
          </Box>
        )}
        <HStack spacing={4}>
          <Button colorScheme="blue" onClick={handleSave}>
            Save
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

const Canvas = () => {
  const { userId: paramUserId, canvasId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [droppedTools, setDroppedTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [history, setHistory] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [sourceTool, setSourceTool] = useState(null);
  const [flowName, setFlowName] = useState('');
  const [activeTool, setActiveTool] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [mappings, setMappings] = useState([]);
  const [inputFiles, setInputFiles] = useState([
    { id: 1, content: '{}', name: 'input1.json' },
  ]);  const [outputFiles, setOutputFiles] = useState([
    { id: 1, content: '{}', name: 'output1.json' },
  ]);
  const [mappingId, setMappingId] = useState(null);

  const canvasRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [isLocked, setIsLocked] = useState(false);
  const [canvasOffset, setCanvasOffset] = useState({ x: 0, y: 0 });
  const [renamingToolId, setRenamingToolId] = useState(null);
  const [tempName, setTempName] = useState('');
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [tempDescription, setTempDescription] = useState('');
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);
  const [isPanning, setIsPanning] = useState(false);
  const [panStart, setPanStart] = useState({ x: 0, y: 0 });
  const [connectingLine, setConnectingLine] = useState(null);
  const [isMappingClicked, setIsMappingClicked] = useState(false);
  const [tempMappings, setTempMappings] = useState({});
  const [stickyNotes, setStickyNotes] = useState([]);
  const [isSpacePressed, setIsSpacePressed] = useState(false);

  const toast = useToast();
  const { colorMode } = useColorMode();

  const { auth, logout } = useContext(AuthContext);
  const { userId, token } = auth;

  const navigate = useNavigate();

  // State for Share Modal
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [shareEmail, setShareEmail] = useState('');

  // State for User Name
  const [userName, setUserName] = useState('');

  // Fetch user details to get userName
  useEffect(() => {
    const fetchUserName = async () => {
      if (auth?.isAuthenticated && auth?.userId && auth?.token) {
        try {
          const userData = await getUserDetails(auth.userId, auth.token);
          setUserName(userData?.name || 'User');
        } catch (error) {
          console.error('Error fetching user details:', error);
          setUserName('User');
        }
      }
    };

    fetchUserName();
  }, [auth]);

  // Fetch canvas data on load
  useEffect(() => {
    const fetchCanvasData = async () => {
      try {
        const response = await fetch(
          `${API_URL}/canvas/${userId}/${canvasId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error('Failed to fetch canvas data');
        }
        const data = await response.json();
        setFlowName(data.flowName);
        setDroppedTools(data.state.droppedTools || []);
        setStickyNotes(data.state.stickyNotes || []);
        setSourceTool(data.state.sourceTool || null);
        setSharedUsers(data.sharedWith || []);
        setLoading(false);

      } catch (err) {
        console.error('Error fetching canvas data:', err);
        setError('An error occurred while loading the canvas.');
        setLoading(false);
      }
    };
    if (token) {
      fetchCanvasData();
    } else {
      setError('Unauthorized access. Please log in.');
      setLoading(false);
    }
  }, [paramUserId, canvasId, token]);

  // Save history for undo/redo
  const saveHistory = (newState) => {
    const updatedHistory = history.slice(0, currentIndex + 1);
    setHistory([...updatedHistory, newState]);
    setCurrentIndex(currentIndex + 1);
  };
  const handleSaveMappings = (mappingData) => {
    setMappings(mappingData.mappings);
    setInputFiles(mappingData.inputFiles);
    setOutputFiles(mappingData.outputFiles);
    setMappingId(mappingData.mappingId);
  };

  // Handle undo
  const handleUndo = () => {
    if (currentIndex > 0) {
      const previousState = history[currentIndex - 1];
      setDroppedTools(previousState.droppedTools || []);
      setStickyNotes(previousState.stickyNotes || []);
      setSourceTool(previousState.sourceTool || null);
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Handle redo
  const handleRedo = () => {
    if (currentIndex < history.length - 1) {
      const nextState = history[currentIndex + 1];
      setDroppedTools(nextState.droppedTools || []);
      setStickyNotes(nextState.stickyNotes || []);
      setSourceTool(nextState.sourceTool || null);
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Handle drag start for toolbox items
  const onDragStart = (event, item) => {
    if (isLocked) return;
    event.dataTransfer.setData('tool', JSON.stringify(item));
    event.dataTransfer.setData('isCanvasTool', false);
  };

  // Handle drag start for tools on canvas
  const onToolDragStart = (event, toolId) => {
    if (isLocked) return;
    event.stopPropagation();
    event.dataTransfer.setData('draggedToolId', toolId);
    event.dataTransfer.setData('isCanvasTool', true);
  };

  // Handle drop on canvas
  const onDrop = (event) => {
    if (isLocked || isPopupOpen) return;
    event.preventDefault();

    const canvasRect = canvasRef.current.getBoundingClientRect();
    let left = (event.clientX - canvasRect.left - canvasOffset.x) / zoomLevel;
    let top = (event.clientY - canvasRect.top - canvasOffset.y) / zoomLevel;

    // Apply grid snapping
    left = Math.round(left / GRID_SIZE) * GRID_SIZE;
    top = Math.round(top / GRID_SIZE) * GRID_SIZE;

    const isCanvasTool = event.dataTransfer.getData('isCanvasTool') === 'true';

    if (isCanvasTool) {
      const toolId = event.dataTransfer.getData('draggedToolId');
      const updatedTools = droppedTools.map((tool) =>
        tool.uniqueId === toolId ? { ...tool, left, top } : tool
      );
      setDroppedTools(updatedTools);
      saveHistory({ droppedTools: updatedTools, stickyNotes, sourceTool });
    } else {
      const toolData = event.dataTransfer.getData('tool');
      if (!toolData) return;
      let tool;
      try {
        tool = JSON.parse(toolData);
      } catch (parseError) {
        console.error('Error parsing dragged tool data:', parseError);
        return;
      }
      const uniqueToolId = generateUniqueId();

      if (tool.type === 'note') {
        // Handle Sticky Note
        const newNote = {
          uniqueId: uniqueToolId,
          left,
          top,
          content: '',
          color: '#FFEB3B',
        };
        const newStickyNotes = [...stickyNotes, newNote];
        setStickyNotes(newStickyNotes);
        saveHistory({ droppedTools, stickyNotes: newStickyNotes, sourceTool });
      } else {
        // Handle other tools
        const newTool = {
          ...tool,
          left,
          top,
          uniqueId: uniqueToolId,
          connectedTo: [],
          name: tool.name || 'Unnamed Tool',
          id: tool.id,
          mappings: tool.mappings || {},
        };

        const newTools = [...droppedTools, newTool];
        setDroppedTools(newTools);
        saveHistory({ droppedTools: newTools, stickyNotes, sourceTool });
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.code === 'Space') {
        const target = e.target || e.srcElement;
        const isInputField =
          ['INPUT', 'TEXTAREA', 'SELECT'].includes(target.tagName) ||
          target.isContentEditable;

        if (isInputField) {
          // Allow default behavior in input fields
          return;
        }

        e.preventDefault();
        setIsSpacePressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.code === 'Space') {
        setIsSpacePressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const handleMouseDown = (e) => {
    if (isPopupOpen) return;

    if (e.button === 1 || isSpacePressed) {
      e.preventDefault();
      setIsPanning(true);
      setPanStart({ x: e.clientX - canvasOffset.x, y: e.clientY - canvasOffset.y });
    }
  };

  const handleMouseMove = (e) => {
    if (isPanning) {
      setCanvasOffset({
        x: e.clientX - panStart.x,
        y: e.clientY - panStart.y,
      });
    } else if (connectingLine) {
      const canvasRect = canvasRef.current.getBoundingClientRect();
      const x = (e.clientX - canvasRect.left - canvasOffset.x) / zoomLevel;
      const y = (e.clientY - canvasRect.top - canvasOffset.y) / zoomLevel;
      setConnectingLine((prev) => ({ ...prev, x2: x, y2: y }));
    }
  };

  // Handle pan end
  const handleMouseUp = (e) => {
    if (isPanning) {
      setIsPanning(false);
    }
    if (connectingLine) {
      // Check if mouse is over a tool to connect
      const canvasRect = canvasRef.current.getBoundingClientRect();
      const mouseX = (e.clientX - canvasRect.left - canvasOffset.x) / zoomLevel;
      const mouseY = (e.clientY - canvasRect.top - canvasOffset.y) / zoomLevel;

      const targetTool = droppedTools.find(
        (tool) =>
          mouseX >= tool.left &&
          mouseX <= tool.left + 100 &&
          mouseY >= tool.top &&
          mouseY <= tool.top + 50 &&
          tool.uniqueId !== connectingLine.from
      );

      if (targetTool) {
        const updatedTools = droppedTools.map((tool) => {
          if (tool.uniqueId === connectingLine.from) {
            // Prevent duplicate connections
            if (!tool.connectedTo.includes(targetTool.uniqueId)) {
              return {
                ...tool,
                connectedTo: [...tool.connectedTo, targetTool.uniqueId],
              };
            }
          }
          return tool;
        });
        setDroppedTools(updatedTools);
        saveHistory({ droppedTools: updatedTools, stickyNotes, sourceTool });
      }
      setConnectingLine(null);
    }
  };

  // Handle selection of tools
  const handleToolClick = (e, toolId) => {
    if (isPopupOpen) return;
    e.stopPropagation();
    if (e.shiftKey) {
      setSelectedTools((prev) =>
        prev.includes(toolId) ? prev.filter((id) => id !== toolId) : [...prev, toolId]
      );
    } else {
      setSelectedTools([toolId]);
    }
  };

  // Delete selected tools
  const deleteSelectedTools = () => {
    const newTools = droppedTools.filter((tool) => !selectedTools.includes(tool.uniqueId));
    setDroppedTools(newTools);
    setSelectedTools([]);
    saveHistory({ droppedTools: newTools, stickyNotes, sourceTool });
  };

  // Copy and paste functionality
  const [clipboard, setClipboard] = useState([]);

  const handleCopy = useCallback(() => {
    const toolsToCopy = droppedTools.filter((tool) => selectedTools.includes(tool.uniqueId));
    setClipboard(toolsToCopy);
    toast({
      title: 'Copied to clipboard.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  }, [selectedTools, droppedTools, toast]);

  const handlePaste = useCallback(() => {
    if (clipboard.length === 0) return;
    const newTools = clipboard.map((tool) => ({
      ...tool,
      uniqueId: generateUniqueId(),
      left: tool.left + 20,
      top: tool.top + 20,
    }));
    const updatedTools = [...droppedTools, ...newTools];
    setDroppedTools(updatedTools);
    saveHistory({ droppedTools: updatedTools, stickyNotes, sourceTool });
    toast({
      title: 'Pasted from clipboard.',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  }, [clipboard, droppedTools, stickyNotes, toast]);

  // Handle keydown events for copy/paste, undo/redo, and popup close
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.ctrlKey && e.key === 'c') {
        handleCopy();
      } else if (e.ctrlKey && e.key === 'v') {
        handlePaste();
      } else if (e.ctrlKey && e.key === 'z') {
        handleUndo();
      } else if (e.ctrlKey && e.key === 'y') {
        handleRedo();
      } else if (e.key === 'Delete') {
        deleteSelectedTools();
      } else if (e.key === 'Escape' && isPopupOpen) {
        handlePopupClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleCopy, handlePaste, handleUndo, handleRedo, deleteSelectedTools, isPopupOpen]);

  // Render connections between tools
  const renderConnections = () => {
    const connections = [];

    const getPathD = (x1, y1, x2, y2) => {
      const curveOffset = Math.abs(x2 - x1) / 2;
      return `M ${x1} ${y1} C ${x1 + curveOffset} ${y1}, ${x2 - curveOffset} ${y2}, ${x2} ${y2}`;
    };

    droppedTools.forEach((tool) => {
      tool.connectedTo.forEach((connectionId) => {
        const connectedTool = droppedTools.find((t) => t.uniqueId === connectionId);

        if (connectedTool) {
          const x1 = tool.left + 100;
          const y1 = tool.top + 25;
          const x2 = connectedTool.left;
          const y2 = connectedTool.top + 25;

          connections.push(
            <path
              key={`${tool.uniqueId}-${connectionId}`}
              d={getPathD(x1, y1, x2, y2)}
              stroke="black"
              strokeWidth="2"
              fill="none"
              markerEnd="url(#arrowhead)"
            />
          );
        }
      });
    });

    return connections;
  };

  // Handle arrow (line) dragging
  const handleArrowMouseDown = (e, toolId) => {
    if (isPopupOpen) return;
    e.stopPropagation();
    e.preventDefault();
    const canvasRect = canvasRef.current.getBoundingClientRect();
    const x1 = (e.clientX - canvasRect.left - canvasOffset.x) / zoomLevel;
    const y1 = (e.clientY - canvasRect.top - canvasOffset.y) / zoomLevel;

    setConnectingLine({
      from: toolId,
      x1,
      y1,
      x2: x1,
      y2: y1,
    });
  };

  // Handle export functionality - Export as JSON
  const handleExport = () => {
    // Prepare the data to be exported, including data mappings
    const exportData = {
      flowName,
      droppedTools: droppedTools.map((tool) => ({
        ...tool,
        mappings: tool.mappings || {},
      })),
      stickyNotes,
      sourceTool,
    };

    // Export as JSON
    const dataStr = JSON.stringify(exportData, null, 2);
    const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);

    const jsonLink = document.createElement('a');
    jsonLink.href = dataUri;
    jsonLink.download = 'canvas_project.json';
    jsonLink.click();

    toast({
      title: 'Exported as JSON',
      description: 'Canvas state has been exported as a JSON file.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  // Handle export functionality - Export as Image
  const handleExportAsImage = () => {
    html2canvas(canvasRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgLink = document.createElement('a');
      imgLink.href = imgData;
      imgLink.download = 'canvas_project.png';
      imgLink.click();

      toast({
        title: 'Exported as Image',
        description: 'Canvas has been exported as an image.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }).catch((error) => {
      console.error('Error exporting canvas as image:', error);
      toast({
        title: 'Export Failed',
        description: 'An error occurred while exporting the canvas as an image.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    });
  };

  // Handle import functionality
  const handleImport = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Validate file type
    if (file.type !== 'application/json') {
      toast({
        title: 'Invalid File Type',
        description: 'Please upload a valid JSON file.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Validate file size (e.g., max 1MB)
    const MAX_SIZE = 1 * 1024 * 1024; // 1MB
    if (file.size > MAX_SIZE) {
      toast({
        title: 'File Too Large',
        description: 'Please upload a file smaller than 1MB.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const data = JSON.parse(event.target.result);
        // Validate the structure of the imported data
        if (!validateImportedData(data)) {
          throw new Error('Invalid canvas data structure.');
        }
        setFlowName(data.flowName);
        setDroppedTools(
          data.droppedTools.map((tool) => ({
            ...tool,
            mappings: tool.mappings || {},
          })) || []
        );
        setStickyNotes(data.stickyNotes || []);
        setSourceTool(data.sourceTool || null);
        saveHistory({
          droppedTools: data.droppedTools || [],
          stickyNotes: data.stickyNotes || [],
          sourceTool: data.sourceTool || null,
        });
        toast({
          title: 'Canvas Imported Successfully',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      } catch (err) {
        console.error('Error parsing imported data:', err);
        toast({
          title: 'Failed to Import Canvas',
          description: 'The imported file is invalid or corrupted.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fileReader.onerror = () => {
      console.error('Error reading the file.');
      toast({
        title: 'File Read Error',
        description: 'An error occurred while reading the file.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    };
    fileReader.readAsText(file);
  };

  // Validation function for imported data
  const validateImportedData = (data) => {
    if (typeof data.flowName !== 'string') return false;
    if (!Array.isArray(data.droppedTools)) return false;
    if (!Array.isArray(data.stickyNotes)) return false;
    // Additional validations can be added here
    return true;
  };

  // Handle save canvas state
  const handleCanvasSave = async () => {
    if (!flowName) {
      toast({
        title: 'Flow Name Required',
        description: 'Please enter a flow name before saving.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const state = { droppedTools, stickyNotes, sourceTool };
      await saveCanvasState(userId, canvasId, flowName, state, token);
      toast({
        title: 'Canvas Saved',
        description: 'Your canvas has been saved successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving canvas state:', error);
      toast({
        title: 'Save Failed',
        description: 'An unexpected error occurred while saving the canvas.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle renaming of tools and opening DataMappingComponent
  const handleRenamePopupOpen = (e, toolId, toolName, toolDescription = '') => {
    e.stopPropagation();
    const tool = droppedTools.find((t) => t.uniqueId === toolId);

    if (tool && tool.id === 1) {
      setIsMappingClicked(true);
      setUniqueId(tool.uniqueId);
    } else {
      setIsMappingClicked(false);
    }

    const { clientX: x, clientY: y } = e;
    setPopupPosition({ x, y });
    setRenamingToolId(toolId);
    setTempName(toolName);
    setTempDescription(toolDescription);
    setTempMappings(tool.mappings || {});
    setIsPopupOpen(true);
  };

  const handleRenameChange = (e) => {
    setTempName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setTempDescription(e.target.value);
  };

  const handleSave = () => {
    if (!tempName.trim()) {
      toast({
        title: 'Invalid Name',
        description: 'Tool name cannot be empty.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const updatedTools = droppedTools.map((tool) =>
      tool.uniqueId === renamingToolId
        ? {
            ...tool,
            name: tempName.trim(),
            description: tempDescription.trim(),
            mappings: tempMappings,
          }
        : tool
    );

    setDroppedTools(updatedTools);
    saveHistory({ droppedTools: updatedTools, stickyNotes, sourceTool });
    setIsPopupOpen(false);
    setRenamingToolId(null);
    setTempName('');
    setTempDescription('');
    setTempMappings({});
    toast({
      title: 'Tool Updated',
      description: 'Tool details have been updated successfully.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  // Modify handlePopupClose to save changes before closing
  const handlePopupClose = () => {
    // Save changes before closing
    handleSave();
  };

  // Handle updating sticky note content and color
  const handleStickyNoteUpdate = (uniqueId, newContent, newColor, newPosition) => {
    const updatedNotes = stickyNotes.map((note) =>
      note.uniqueId === uniqueId
        ? {
            ...note,
            content: newContent,
            color: newColor || note.color,
            left: newPosition?.left ?? note.left,
            top: newPosition?.top ?? note.top,
          }
        : note
    );
    setStickyNotes(updatedNotes);
    saveHistory({ droppedTools, stickyNotes: updatedNotes, sourceTool });
  };
  
  // Handle deleting a sticky note
  const handleStickyNoteDelete = (uniqueId) => {
    const updatedNotes = stickyNotes.filter((note) => note.uniqueId !== uniqueId);
    setStickyNotes(updatedNotes);
    saveHistory({ droppedTools, stickyNotes: updatedNotes, sourceTool });
    toast({
      title: 'Sticky Note Deleted',
      status: 'info',
      duration: 2000,
      isClosable: true,
    });
  };

  // Function to add a new sticky note via the bottom icon
  const addNewStickyNote = () => {
    const uniqueToolId = generateUniqueId();
    const newNote = {
      uniqueId: uniqueToolId,
      left: canvasOffset.x / zoomLevel + 100,
      top: canvasOffset.y / zoomLevel + 100,
      content: '',
      color: '#FFEB3B',
    };
    const newStickyNotes = [...stickyNotes, newNote];
    setStickyNotes(newStickyNotes);
    saveHistory({ droppedTools, stickyNotes: newStickyNotes, sourceTool });
    toast({
      title: 'Sticky Note Added',
      status: 'success',
      duration: 2000,
      isClosable: true,
    });
  };

  // Handle right-click to show context menu
  const handleContextMenu = (e) => {
    const target = e.target.closest('.dropped-tool');
    if (target) {
      e.preventDefault();
      const toolId = target.getAttribute('data-tool-id');
      const tool = droppedTools.find((t) => t.uniqueId === toolId);
      if (tool) {
        setActiveTool(tool);
        setContextMenu({
          x: e.clientX,
          y: e.clientY,
          options: [
            {
              label: 'Delete',
              onClick: () => {
                const newTools = droppedTools.filter((t) => t.uniqueId !== toolId);
                setDroppedTools(newTools);
                setSelectedTools((prev) => prev.filter((id) => id !== toolId));
                saveHistory({ droppedTools: newTools, stickyNotes, sourceTool });
                setContextMenu(null);
                toast({
                  title: 'Tool Deleted',
                  status: 'info',
                  duration: 2000,
                  isClosable: true,
                });
              },
            },
            {
              label: 'Rename',
              onClick: () => {
                const tool = droppedTools.find((t) => t.uniqueId === toolId);
                if (tool) {
                  handleRenamePopupOpen(
                    e,
                    tool.uniqueId,
                    tool.name,
                    tool.description || ''
                  );
                }
                setContextMenu(null);
              },
            },
            {
              label: 'Unconnect',
              onClick: () => {
                const updatedTools = droppedTools.map((t) => {
                  if (t.uniqueId === toolId) {
                    return { ...t, connectedTo: [] };
                  }
                  return {
                    ...t,
                    connectedTo: t.connectedTo.filter((id) => id !== toolId),
                  };
                });
                setDroppedTools(updatedTools);
                saveHistory({ droppedTools: updatedTools, stickyNotes, sourceTool });
                setContextMenu(null);
                toast({
                  title: 'Unconnected Successfully',
                  description: 'All connections related to this tool have been removed.',
                  status: 'success',
                  duration: 2000,
                  isClosable: true,
                });
              },
            },
          ],
        });
      }
    }
  };

  // Close context menu on click outside
  useEffect(() => {
    const handleClick = () => {
      if (contextMenu) setContextMenu(null);
    };
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [contextMenu]);

  // Handle Share Modal Open
  const handleShareModalOpen = () => {
    setIsShareModalOpen(true);
  };

  // Handle Share Modal Close
  const handleShareModalClose = () => {
    setIsShareModalOpen(false);
    setShareEmail('');
  };

  // Handle Share Canvas
  const handleShareCanvas = async () => {
    if (!shareEmail.trim()) {
      toast({
        title: 'Email Required',
        description: 'Please enter an email address to share with.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!isValidEmail(shareEmail.trim())) {
      toast({
        title: 'Invalid Email',
        description: 'Please enter a valid email address.',
        status: 'warning',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await fetch(
        `${API_URL}/canvas/${canvasId}/share`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email: shareEmail.trim() }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to share canvas');
      }

      const data = await response.json();
      setSharedUsers(data.sharedWith);
      toast({
        title: 'Canvas Shared',
        description: `Canvas has been shared with ${shareEmail.trim()}.`,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      handleShareModalClose();
    } catch (error) {
      console.error('Error sharing canvas:', error);
      toast({
        title: 'Share Failed',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle Unshare User
  const handleUnshareUser = async (email) => {
    try {
      const response = await fetch(
        `${API_URL}/canvas/${canvasId}/unshare`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ email }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to unshare canvas');
      }

      const data = await response.json();
      setSharedUsers(data.sharedWith);
      toast({
        title: 'User Removed',
        description: `${email} no longer has access to this canvas.`,
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error unsharing canvas:', error);
      toast({
        title: 'Unshare Failed',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Conditional rendering based on loading or error
  if (loading) {
    return (
      <Box
        className="canvas-container"
        height="100vh"
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box>Loading...</Box>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        className="canvas-container"
        height="100vh"
        width="100vw"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box>Error: {error}</Box>
      </Box>
    );
  }

  // Calculate history length for redo button
  const historyLength = history.length;

  // Handler for Profile navigation
  const handleProfile = () => {
    navigate('/profile');
  };

  // Handler for Delete action (e.g., deleting the canvas)
  const handleDeleteCanvas = async () => {
    // Confirm deletion with the user
    const confirmDelete = window.confirm('Are you sure you want to delete this canvas? This action cannot be undone.');
    if (!confirmDelete) return;

    try {
      const response = await fetch(
        `${API_URL}/canvas/${canvasId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete canvas');
      }

      toast({
        title: 'Canvas Deleted',
        description: 'The canvas has been deleted successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Redirect to home or another page after deletion
      navigate('/');
    } catch (error) {
      console.error('Error deleting canvas:', error);
      toast({
        title: 'Delete Failed',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handler for Logout action
  const handleLogout = () => {
    logout();
    navigate('/login'); // Redirect to login page after logout
  };

  return (
    <Box
      className="canvas-container"
      height="100vh"
      position="relative"
      width="100vw"
      overflow="auto"
      onContextMenu={handleContextMenu}
      role="main"
      aria-label="Canvas Area"
    >
      {/* Canvas Header */}
      <CanvasHeader
        flowName={flowName}
        setFlowName={setFlowName}
        handleCanvasSave={handleCanvasSave}
        handleShareModalOpen={handleShareModalOpen}
        handleExport={handleExport}
        handleExportAsImage={handleExportAsImage}
        handleImport={handleImport}
        handleUndo={handleUndo}
        handleRedo={handleRedo}
        currentIndex={currentIndex}
        historyLength={historyLength}
        userName={userName}
        handleProfile={handleProfile}
        handleDeleteCanvas={handleDeleteCanvas}
        handleLogout={handleLogout}
      />

      {/* Main Content */}
      <Box className="main-content" display="flex" height="calc(100vh - 80px)">
        <ToolSidebar
          onDragStart={onDragStart}
          canvasId={canvasId}
          userId={userId}
          flowName={flowName}
          canvasRef={canvasRef}
          uniqueId={uniqueId}
        />
        <Box
          className="canvas"
          ref={canvasRef}
          onDrop={onDrop}
          onDragOver={(e) => e.preventDefault()}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          style={{
            position: 'relative',
            overflow: 'auto',
            flex: 1,
            cursor: isPanning || isSpacePressed ? 'grabbing' : 'default',
            pointerEvents: isPopupOpen ? 'none' : 'auto',
          }}
          onClick={() => setSelectedTools([])}
          role="region"
          aria-label="Canvas Workspace"
        >
          {/* Canvas Content */}
          <Box
            className="canvas-content"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              transform: `scale(${zoomLevel}) translate(${canvasOffset.x / zoomLevel}px, ${
                canvasOffset.y / zoomLevel
              }px)`,
              transformOrigin: '0 0',
              width: '5000px',
              height: '5000px',
              backgroundSize: `${GRID_SIZE}px ${GRID_SIZE}px`,
              backgroundImage:
                'linear-gradient(to right, #eee 1px, transparent 1px), linear-gradient(to bottom, #eee 1px, transparent 1px)',
            }}
          >
            {/* SVG for connections */}
            <svg
              className="connection-lines"
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 0,
                overflow: 'visible',
              }}
              width="5000px"
              height="5000px"
              aria-hidden="true"
            >
              <defs>
                <marker
                  id="arrowhead"
                  markerWidth="10"
                  markerHeight="7"
                  refX="10"
                  refY="3.5"
                  orient="auto"
                  markerUnits="strokeWidth"
                >
                  <polygon points="0 0, 10 3.5, 0 7" fill="gray" />
                </marker>
              </defs>
              {renderConnections()}

              {/* Temporary connection line */}
              {connectingLine && (
                <line
                  x1={connectingLine.x1}
                  y1={connectingLine.y1}
                  x2={connectingLine.x2}
                  y2={connectingLine.y2}
                  stroke="red"
                  strokeWidth="2"
                  markerEnd="url(#arrowhead)"
                />
              )}
            </svg>

            {/* Render tools */}
            {droppedTools.map((tool) => (
              <Box
                key={tool.uniqueId}
                data-tool-id={tool.uniqueId}
                className="dropped-tool"
                draggable={!isLocked}
                onDragStart={(event) => onToolDragStart(event, tool.uniqueId)}
                position="absolute"
                left={tool.left}
                top={tool.top}
                onClick={(e) => handleToolClick(e, tool.uniqueId)}
                onDoubleClick={(e) =>
                  handleRenamePopupOpen(e, tool.uniqueId, tool.name, tool.description)
                }
                border={selectedTools.includes(tool.uniqueId) ? '2px solid blue' : '1px solid gray'}
                p={2}
                bg={colorMode === 'dark' ? 'gray.700' : 'white'}
                cursor="move"
                zIndex={selectedTools.includes(tool.uniqueId) ? 100 : 1}
                minWidth="100px"
                height="50px"
                textAlign="center"
                borderRadius="md"
                boxShadow={selectedTools.includes(tool.uniqueId) ? 'lg' : 'sm'}
                role="button"
                aria-label={`Tool: ${tool.name}`}
              >
                <Box fontSize="14px" fontWeight="bold">
                  {tool.name}
                </Box>
                {tool.description && (
                  <Box fontSize="12px" color="gray.600">
                    {tool.description}
                  </Box>
                )}
                {/* Arrow for connection */}
                <Box
                  position="absolute"
                  right="-10px"
                  top="50%"
                  transform="translateY(-50%)"
                  width="20px"
                  height="20px"
                  bg="transparent"
                  cursor="pointer"
                  draggable={false}
                  onDragStart={(e) => e.stopPropagation()}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleArrowMouseDown(e, tool.uniqueId);
                  }}
                  aria-label="Connect Tool"
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleArrowMouseDown(e, tool.uniqueId);
                    }
                  }}
                >
                  <Box
                    width="0"
                    height="0"
                    borderTop="5px solid transparent"
                    borderBottom="5px solid transparent"
                    borderLeft="10px solid black"
                    margin="auto"
                  />
                </Box>
              </Box>
            ))}

            {/* Render Sticky Notes */}
            {stickyNotes.map((note) => (
              <StickyNote
                key={note.uniqueId}
                note={note}
                onUpdate={handleStickyNoteUpdate}
                onDelete={handleStickyNoteDelete}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {/* Draggable Popup for Tool Renaming and Data Mapping */}
      {isPopupOpen && (
  <Draggable
    handle=".popup-handle"
    bounds="parent"
    defaultPosition={{ x: popupPosition.x, y: popupPosition.y }}
  >
    <Box
      position="absolute"
      bg="white"
      boxShadow="lg"
      borderRadius="md"
      zIndex={2000}
      width={isMappingClicked ? '80vw' : '400px'}
      maxWidth="90vw"
      maxHeight={isMappingClicked ? '90vh' : '80vh'}
      overflow="auto"
      role="dialog"
      aria-modal="true"
      aria-labelledby="popup-header"
    >
            {/* Header (Draggable Area) */}
            <HStack
              className="popup-handle"
              justifyContent="space-between"
              p={2}
              bg="gray.100"
              cursor="grab"
              borderTopRadius="md"
              id="popup-header"
            >
              <Box fontSize="lg" fontWeight="bold">
                {isMappingClicked ? 'Data Mapping' : 'Edit Tool'}
              </Box>
              <IconButton
                icon={<CloseIcon />}
                size="sm"
                variant="ghost"
                onClick={handlePopupClose} // Now saves changes before closing
                aria-label="Close Popup"
              />
            </HStack>

            {/* Content Area */}
            <VStack spacing={4} p={4} align="stretch">
              {/* Always display rename inputs */}
              
              <FormControl id="tool-name" isRequired>
                <FormLabel>Tool Name</FormLabel>
                <Input
                  value={tempName}
                  onChange={handleRenameChange}
                  placeholder="Enter new tool name"
                  variant="filled"
                  focusBorderColor="blue.500"
                  aria-label="Tool Name"
                />
              </FormControl>
              <FormControl id="tool-description">
                <FormLabel>Tool Description</FormLabel>
                <Textarea
                  value={tempDescription}
                  onChange={handleDescriptionChange}
                  placeholder="Enter tool description"
                  variant="filled"
                  focusBorderColor="blue.500"
                  resize="vertical"
                  aria-label="Tool Description"
                />
              </FormControl>
              
              {!isMappingClicked && (
          <Text fontSize="sm" color="gray.500" mt={2}>
            Changes are saved automatically.
          </Text>
        )}              {/* Data Mapping Component */}
              {isMappingClicked && (
    <Box flex="1" width="100%" height="100%">
      <DataMappingComponent
                    mappings={tempMappings}
                    setMappings={setTempMappings}
                    userId={userId}
                    onSave={handleSaveMappings}

                    canvasId={canvasId}
                    flowName={flowName}
                    uniqueId={uniqueId}
                  />
                </Box>
              )}
            </VStack>

            {/* Optionally, remove the Save and Cancel buttons if you prefer saving only via the Close (X) button */}
            {/* 
            <HStack spacing={4} p={4} justifyContent="flex-end" bg="gray.50">
              <Button colorScheme="blue" onClick={handleSave}>
                Save
              </Button>
              <Button variant="outline" onClick={handlePopupClose}>
                Cancel
              </Button>
            </HStack>
            */}
          </Box>
        </Draggable>
      )}

      {/* Context Menu */}
      {contextMenu && (
        <ContextMenu x={contextMenu.x} y={contextMenu.y} options={contextMenu.options} />
      )}
  <HStack spacing={2}>
          <Icon as={InfoIcon} color="yellow.500" boxSize={5} />
          <Text fontSize="sm">
            <strong>Please note:</strong> Selecting a tool is for planning purposes only. Our application is not affiliated with, endorsed by, or sponsored by the mentioned platforms.
          </Text>
        </HStack>
      {/* Zoom and Lock Controls */}
      <VStack
        position="absolute"
        bottom="10px"
        left="50%"
        transform="translateX(-50%)"
        spacing={2}
        display="block"
        pointerEvents={isPopupOpen ? 'none' : 'auto'}
      >
        <IconButton
          icon={<AddIcon />}
          onClick={() => setZoomLevel((z) => Math.min(z + 0.1, 2))}
          aria-label="Zoom In"
          variant="solid"
          colorScheme="teal"
        />
        <IconButton
          icon={<MinusIcon />}
          onClick={() => setZoomLevel((z) => Math.max(z - 0.1, 0.5))}
          aria-label="Zoom Out"
          variant="solid"
          colorScheme="teal"
        />
        <IconButton
          icon={<SearchIcon />}
          onClick={() => setZoomLevel(1)}
          aria-label="Reset Zoom"
          variant="solid"
          colorScheme="teal"
        />
        <IconButton
          icon={isLocked ? <LockIcon /> : <UnlockIcon />}
          onClick={() => setIsLocked((prev) => !prev)}
          aria-label={isLocked ? 'Unlock Shapes' : 'Lock Shapes'}
          variant="solid"
          colorScheme={isLocked ? 'red' : 'green'}
        />
        <IconButton
          icon={<DeleteIcon />}
          onClick={deleteSelectedTools}
          aria-label="Delete Selected"
          isDisabled={selectedTools.length === 0}
          variant="solid"
          colorScheme="red"
        />
        {/* Sticky Note Icon Button */}
        <IconButton
          icon={<FaStickyNote />}
          onClick={addNewStickyNote}
          aria-label="Add Sticky Note"
          variant="solid"
          colorScheme="yellow"
        />
      </VStack>

      {/* Share Modal */}
      <Modal isOpen={isShareModalOpen} onClose={handleShareModalClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share Canvas</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="share-email" isRequired>
              <FormLabel>Enter email to share with</FormLabel>
              <Input
                type="email"
                value={shareEmail}
                onChange={(e) => setShareEmail(e.target.value)}
                placeholder="user@example.com"
                aria-label="Share Email"
              />
            </FormControl>
            {/* Display list of users the canvas is shared with */}
            <Box mt={4}>
              <FormLabel>Shared With:</FormLabel>
              {sharedUsers.length > 0 ? (
                <VStack align="start">
                  {sharedUsers.map((email) => (
                    <HStack key={email}>
                      <Box>{email}</Box>
                      <IconButton
                        icon={<DeleteIcon />}
                        size="sm"
                        variant="ghost"
                        onClick={() => handleUnshareUser(email)}
                        aria-label={`Remove ${email}`}
                      />
                    </HStack>
                  ))}
                </VStack>
              ) : (
                <Box>No users have access to this canvas.</Box>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleShareCanvas}>
              Share
            </Button>
            <Button variant="ghost" onClick={handleShareModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Canvas;
