// src/components/Footer.js

import React from 'react';
import '../CSS/Footer.css';

const Footer = () => {
  return (
    <footer className="dashboard-footer">
      <div className="footer-content">
        <p>&copy; 2024 Plantegrate.co. All rights reserved.</p>
        <nav className="footer-nav">
          <a href="/privacy-policy">Privacy Policy</a>
          <a href="/contact">Contact</a>
          <a href="/help">Help</a>
        </nav>
        {/* Disclaimer */}
        <p className="footer-disclaimer">
          *Please note: Selecting a tool is for planning purposes only. Our application is not affiliated with, endorsed by, or sponsored by the mentioned platforms.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
