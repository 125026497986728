// src/components/CanvasHeader.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Flex,
  HStack,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Spacer,
  Input,
  Text,
  Editable,
  EditableInput,
  EditablePreview,
  useEditableControls,
  IconButton,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Textarea,
  useDisclosure,
  useToast,
  Badge
} from '@chakra-ui/react';
import {
  DownloadIcon,
  AttachmentIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  ChevronDownIcon,
  ChatIcon, // Icon for Feedback
} from '@chakra-ui/icons';
import logoImage from '../images/logo.png'; // Ensure the correct path
import { submitFeedback } from '../services/UserService'; // Import the submitFeedback function

const CanvasHeader = ({
  flowName = 'Untitled Flow',
  setFlowName,
  handleCanvasSave,
  handleShareModalOpen,
  handleExport,
  handleExportAsImage,
  handleImport,
  handleUndo,
  handleRedo,
  currentIndex,
  historyLength,
  userName,
  handleProfile,
  handleDeleteCanvas,
  handleLogout,
}) => {
  // Handler for when the flow name is submitted
  const handleFlowNameSubmit = (nextValue) => {
    const trimmedName = nextValue.trim();
    if (trimmedName) {
      setFlowName(trimmedName);
    } else {
      setFlowName('Untitled Flow');
    }
  };

  // Feedback Modal Control
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const toast = useToast();

  // Feedback Form State
  const [feedback, setFeedback] = useState({
    message: '',
    honeypot: '', // Honeypot field for spam prevention
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // To manage loading state

  // Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check honeypot field
    if (feedback.honeypot) {
      console.warn('Spam submission detected.');
      return;
    }

    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');

    if (!token) {
      toast({
        title: 'Authentication Required.',
        description: 'Please sign in to submit feedback.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Validate the feedback message
    if (!feedback.message || feedback.message.trim().length === 0) {
      toast({
        title: 'Invalid Feedback.',
        description: 'Please enter a valid feedback message.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true); // Start loading

    try {
      // Call the submitFeedback service
      await submitFeedback(feedback.message, token);

      // Handle successful submission
      toast({
        title: 'Feedback Submitted.',
        description: 'Thank you for your feedback!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Reset the form
      setFeedback({
        message: '',
        honeypot: '',
      });

      onClose(); // Close the modal
    } catch (error) {
      // Handle errors during submission
      toast({
        title: 'Submission Failed.',
        description: error.message || 'There was an issue submitting your feedback. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false); // End loading
    }
  };

  // Optionally, pre-fill user information if needed (name and email can be fetched from user data)
  // For this implementation, since the form is for signed-in users, name and email are not required in the form

  return (
    <Flex
      className="toolbar"
      p={4}
      bg="gray.50"
      boxShadow="md"
      alignItems="center"
      justifyContent="space-between"
    >
      {/* Logo */}
      <Link to="/">
  <Box display="flex" alignItems="center">
    <img src={logoImage} alt="Logo" className="canvas-logo" />
    <Badge colorScheme="red" ml={2}>Alpha</Badge> {/* Add this line */}
  </Box>
</Link>

      <HStack spacing={2} align="center" mx={4} paddingLeft={"70px"}>
        <Text fontSize="lg" fontWeight="bold">
          Flow Name:
        </Text>
        {/* Flow Name Editor */}
        <Editable
          defaultValue={flowName}
          onSubmit={handleFlowNameSubmit}
          placeholder="Enter flow name"
          fontSize="lg"
          fontWeight="bold"
          
          width={{ base: '150px', md: '250px' }}
          isPreviewFocusable={true}
        >
          <HStack>
            <EditablePreview
              px={2}
              py={1}
              borderRadius="md"
              _hover={{ bg: 'gray.200', cursor: 'pointer' }}
              bg="transparent"
              width="100%"
            />
            <EditableInput
              px={2}
              py={1}
              borderRadius="md"
              width="100%"
              border="1px solid"
              borderColor="gray.300"
            />
          </HStack>
        </Editable>
      </HStack>

      {/* Spacer to push the toolbar buttons to the right */}
      <Spacer />

      {/* Toolbar Buttons */}
      <HStack spacing={4}>
        <Button onClick={handleCanvasSave}>
          Save
        </Button>

        {/* Share Button */}
        <Button onClick={handleShareModalOpen}>
          Share
        </Button>

        {/* Export Menu */}
        <Menu>
          <MenuButton as={Button} leftIcon={<DownloadIcon />} variant="outline">
            Export
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleExport}>Export as JSON File</MenuItem>
            <MenuItem onClick={handleExportAsImage}>Export as Image</MenuItem>
          </MenuList>
        </Menu>

        {/* Import Button */}
        <Input
          type="file"
          onChange={handleImport}
          accept=".json"
          display="none"
          id="file-input"
        />
        <label htmlFor="file-input">
          <Button as="span" leftIcon={<AttachmentIcon />} variant="outline">
            Import
          </Button>
        </label>

        {/* Undo and Redo Buttons */}
        <Button
          onClick={handleUndo}
          leftIcon={<ArrowBackIcon />}
          disabled={currentIndex <= 0}
          variant="ghost"
        >
          Undo
        </Button>
        <Button
          onClick={handleRedo}
          leftIcon={<ArrowForwardIcon />}
          disabled={currentIndex >= historyLength - 1}
          variant="ghost"
        >
          Redo
        </Button>

        {/* User Menu */}
        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            rightIcon={<ChevronDownIcon />}
            leftIcon={<Avatar size="sm" name={userName} />}
          >
            <Text ml={2}>{userName}</Text>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={handleDeleteCanvas}>Delete Canvas</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
            <MenuItem onClick={onOpen} icon={<ChatIcon />}>
              Feedback
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>

      {/* Feedback Modal */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feedback</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6}>
              {/* Feedback Message */}
              <FormControl id="message" isRequired>
                <FormLabel>Feedback</FormLabel>
                <Textarea
                  placeholder="Your feedback..."
                  name="message"
                  value={feedback.message}
                  onChange={handleChange}
                />
              </FormControl>

              {/* Honeypot Field (Invisible to users) */}
              <FormControl style={{ display: 'none' }}>
                <FormLabel>Leave this field empty</FormLabel>
                <Input
                  type="text"
                  name="honeypot"
                  value={feedback.honeypot}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                type="submit"
                isLoading={isSubmitting} // Loading state
                loadingText="Submitting"
              >
                Submit
              </Button>
              <Button onClick={onClose} variant="ghost">
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
    
  );
};

export default CanvasHeader;
