// src/components/LandingPage.js

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'; // For logo link
import { Link as ScrollLink } from 'react-scroll'; // For smooth scrolling
import '../CSS/LandingPage.css'; // Include your CSS styles

import logoImage from '../images/logo.png'; // Adjust the path and filename as needed
import headerImage from '../images/header.jpg';
import feature1Image from '../images/visual.png';
import feature2Image from '../images/collab.png';
import feature3Image from '../images/integration.png';
import feature4Image from '../images/mapping.png';
import traditionalMethodsImage from '../images/4.webp';
import ctaImage from '../images/header.jpg';
import canvasScreenshot from '../images/header2.jpg';
import { Badge } from '@chakra-ui/react'; // Add Badge import

// Import AOS for animations
import AOS from 'aos';
import 'aos/dist/aos.css';

// Import Modal and Authentication Components
import Modal from './Modal'; // Assuming Modal is in the same directory
import LoginPage from './LoginPage';
import NewUserPage from './NewUserPage';

// Import AuthContext for authentication state
import { AuthContext } from '../context/AuthContext';

// Import react-helmet
import { Helmet } from 'react-helmet';

function LandingPage() {
  const { auth, logout } = useContext(AuthContext); // Access authentication state and logout function

  // State to manage the visibility of Login and Signup modals
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);

  // State to manage the mobile menu
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Functions to open and close the Login modal
  const openLoginModal = () => setIsLoginOpen(true);
  const closeLoginModal = () => setIsLoginOpen(false);

  // Functions to open and close the Signup modal
  const openSignupModal = () => setIsSignupOpen(true);
  const closeSignupModal = () => setIsSignupOpen(false);

  // Functions to toggle and close the mobile menu
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const closeMenu = () => setIsMenuOpen(false);

  // Close the mobile menu when a link is clicked
  const handleLinkClick = () => {
    if (isMenuOpen) {
      closeMenu();
    }
  };

  // Initialize AOS animations on component mount
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);

  return (
    <div className="LandingPage">
      {/* React Helmet for SEO */}
      <Helmet>
        <title>Plantegrate - Efficient Data Integration Planning Tool</title>
        <meta
          name="description"
          content="Plantegrate is a dedicated tool built for efficient data integration planning. Whether you're technical or non-technical, plan your workflows and save valuable time and resources."
        />
        <meta
          name="keywords"
          content="Data Integration, Planning Tool, Workflow Management, Collaboration Tools, Visual Data Mapping, Plantegrate"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* Open Graph Tags for Social Sharing */}
        <meta property="og:title" content="Plantegrate - Efficient Data Integration Planning Tool" />
        <meta
          property="og:description"
          content="Use Plantegrate to streamline your data integration planning. Plan workflows, collaborate seamlessly, and avoid costly mistakes with our dedicated tools."
        />
        <meta property="og:image" content={headerImage} />
        <meta property="og:url" content="https://www.plantegrate.co/" />
        <meta property="og:type" content="website" />
        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Plantegrate - Efficient Data Integration Planning Tool" />
        <meta
          name="twitter:description"
          content="Streamline your data integration planning with Plantegrate. Collaborate, plan workflows, and save time and resources effectively."
        />
        <meta name="twitter:image" content={headerImage} />
        {/* Canonical Link */}
        <link rel="canonical" href="https://www.plantegrate.co/" />
      </Helmet>

      {/* Navbar */}
      <header className="navbar">
      <div className="navbar-logo">
  <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
    <img src={logoImage} alt="Plantegrate Logo" className="logo-image" />
    <Badge colorScheme="red" ml={2} style={{ marginLeft: '10px' }}>Alpha</Badge>
  </Link>
</div>

        {/* Hamburger Menu Button */}
        <button
          className={`hamburger ${isMenuOpen ? 'active' : ''}`}
          onClick={toggleMenu}
          aria-label="Toggle navigation menu"
          aria-expanded={isMenuOpen}
        >
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>
        <nav className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <ul>
            <li>
              <ScrollLink 
                to="features" 
                smooth={true} 
                duration={500} 
                offset={-80} // Adjust based on navbar height
                onClick={handleLinkClick}
                activeClass="active"
                spy={true}
              >
                Features
              </ScrollLink>
            </li>
            <li>
              <ScrollLink 
                to="canvas" 
                smooth={true} 
                duration={500} 
                offset={-80}
                onClick={handleLinkClick}
                activeClass="active"
                spy={true}
              >
                Canvas
              </ScrollLink>
            </li>
            <li>
              <ScrollLink 
                to="benefits" 
                smooth={true} 
                duration={500} 
                offset={-80}
                onClick={handleLinkClick}
                activeClass="active"
                spy={true}
              >
                Benefits
              </ScrollLink>
            </li>
            <li>
              <ScrollLink 
                to="how-it-works" 
                smooth={true} 
                duration={500} 
                offset={-80}
                onClick={handleLinkClick}
                activeClass="active"
                spy={true}
              >
                How It Works
              </ScrollLink>
            </li>
            {auth.isAuthenticated ? (
              <li>
                <Link to="/dashboard" onClick={handleLinkClick}>Dashboard</Link>
              </li>
            ) : (
              <>
                <li>
                  <button className="navbar-button" onClick={() => { openSignupModal(); handleLinkClick(); }}>Sign Up</button>
                </li>
                <li>
                  <button className="navbar-button" onClick={() => { openLoginModal(); handleLinkClick(); }}>Login</button>
                </li>
              </>
            )}
            {auth.isAuthenticated && (
              <li>
                <button className="navbar-button" onClick={() => { logout(); handleLinkClick(); }}>Logout</button>
              </li>
            )}
          </ul>
        </nav>
      </header>

      {/* Hero Section */}
      <main>
        <section className="hero-section" aria-labelledby="hero-heading">
          <div className="hero-content">
            <h1 id="hero-heading">
              Avoid Wasting Time & Money on Messy Data Integration Planning 
            </h1>
            <p>
              Use Plantegrate, a dedicated tool built for efficient data integration planning. Whether you're technical or non-technical, plan your workflows and save valuable time and resources.
            </p>
            <button className="cta-button" onClick={openSignupModal}>Start Plantegrating</button>
          </div>
          <div className="hero-image">
            <img src={headerImage} alt="Data Integration Planning Illustration" loading="lazy" />
          </div>
        </section>

        {/* Features Section */}
        <section className="features-section" id="features" aria-labelledby="features-heading">
          <h2 id="features-heading">Why Choose Plantegrate?</h2>
          <div className="features-container">
            {/* Feature 1 */}
            <div className="feature" data-aos="fade-up">
              <img src={feature1Image} alt="Visual Integration Language" loading="lazy" />
              <h3>
                Visual Integration Language 
              </h3>
              <p>
                A simple, visual language that lets both technical and non-technical users convey their integration ideas clearly, reducing misunderstandings and rework.
              </p>
            </div>
            {/* Feature 2 */}
            <div className="feature" data-aos="fade-up" data-aos-delay="100">
              <img src={feature2Image} alt="Collaboration Tools" loading="lazy" />
              <h3>
                Collaboration Tools 
              </h3>
              <p>
                Seamlessly collaborate across departments, ensuring everyone is aligned and reducing the time spent on back-and-forth communications.
              </p>
            </div>
            {/* Feature 3 */}
            <div className="feature" data-aos="fade-up" data-aos-delay="200">
              <img src={feature3Image} alt="Integration Planning" loading="lazy" />
              <h3>
                Integration Planning 
              </h3>
              <p>
                Plan and structure your data integrations with ease, ensuring all stakeholders are aligned and projects stay within budget and timelines.
              </p>
            </div>
            {/* Feature 4 */}
            <div className="feature" data-aos="fade-up" data-aos-delay="300">
              <img src={feature4Image} alt="Visual Data Mapping Tool" loading="lazy" />
              <h3>
                Visual Data Mapping Tool 
              </h3>
              <p>
                Streamline your data integration strategy with our dedicated Data Mapping Planning Tool. Designed for meticulous planning, this tool allows you to design, visualize, and organize your data mappings effectively. Plan your data flows with precision, ensuring seamless and accurate integrations without the need for complex coding.
              </p>
            </div>
          </div>
        </section>

        {/* New Benefits Section */}
        <section className="benefits-section" id="benefits" aria-labelledby="benefits-heading">
          <h2 id="benefits-heading">Avoid Costly Mistakes with Dedicated Planning Tools</h2>
          <div className="benefits-container">
            <div className="benefits-image" data-aos="fade-right">
              <img src={traditionalMethodsImage} alt="Traditional Methods: Excel and PowerPoint" loading="lazy" />
            </div>
            <div className="benefits-content" data-aos="fade-left">
              <p>
                Tired of using cluttered Excel sheets and lengthy PowerPoint presentations to plan your data integrations? These traditional methods often lead to miscommunication, inefficiencies, and increased costs.
              </p>
              <ul>
                <li>📉 Reduce errors and misalignments in your integration plans.</li>
                <li>⏱ Save valuable time by streamlining the planning process.</li>
                <li>💰 Cut down on costs associated with manual documentation and revisions.</li>
                <li>📈 Enhance collaboration and transparency across all teams.</li>
              </ul>
              <button className="cta-button" onClick={openSignupModal}>Start Streamlining Today</button>
            </div>
          </div>
        </section>

        {/* Canvas Showcase Section */}
        <section className="canvas-showcase" id="canvas" aria-labelledby="canvas-heading">
          <h2 id="canvas-heading">Experience Plantegrate in Action</h2>
          <div>
            <div className="canvas-image" data-aos="zoom-in">
              <img src={canvasScreenshot} alt="Plantegrate Planning Tool Canvas Interface" loading="lazy" />
            </div>
            <div className="canvas-description" data-aos="fade-left">
              <p>
                Get a firsthand look at our intuitive canvas interface. Design, visualize, and manage
                your data integration workflows with ease. Drag and drop components, customize settings,
                and see real-time updates—all in one place.
              </p>
              <button className="cta-button" onClick={openSignupModal}>Try It Now</button>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="how-it-works-section" id="how-it-works" aria-labelledby="how-it-works-heading">
          <h2 id="how-it-works-heading">How Plantegrate Works</h2>
          <div className="steps-container">
            {/* Step 1 */}
            <div className="step" data-aos="fade-right">
              <h3>Create Integration Ideas</h3>
              <p>
                Use our intuitive interface to outline your integration concepts without any coding.
              </p>
            </div>
            {/* Step 2 */}
            <div className="step" data-aos="fade-right" data-aos-delay="100">
              <h3>Collaborate and Refine</h3>
              <p>
                Work with your team to refine integration plans, ensuring everyone is on the same page.
              </p>
            </div>
            {/* Step 3 */}
            <div className="step" data-aos="fade-right" data-aos-delay="200">
              <h3>Hand Off to Developers</h3>
              <p>
                Export detailed plans to developers, facilitating smooth and efficient implementation.
              </p>
            </div>
          </div>
        </section>

        {/* Call-to-Action Banner */}
        <section className="cta-banner" id="signup" aria-labelledby="cta-heading">
          <div className="cta-content" data-aos="fade-up">
            <h2 id="cta-heading">Start Planning Your Integrations Today</h2>
            <button className="cta-button" onClick={openSignupModal}>Get Started with Plantegrate Alpha</button>
          </div>
          <div className="cta-image" data-aos="fade-up" data-aos-delay="100">
            <img src={ctaImage} alt="Start Planning Illustration" loading="lazy" />
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <p>&copy; {new Date().getFullYear()} Plantegrate Alpha. All rights reserved.</p>
          <div className="footer-links">
            <a href="#about">About Us</a>
            <a href="#contact">Contact</a>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
      </footer>

      {/* Login Modal */}
      <Modal isOpen={isLoginOpen} onClose={closeLoginModal} >
        <LoginPage onClose={closeLoginModal} />
      </Modal>

      {/* Sign Up Modal */}
      <Modal isOpen={isSignupOpen} onClose={closeSignupModal}>
        <NewUserPage onClose={closeSignupModal} />
      </Modal>
    </div>
  );
}

export default LandingPage;
