// src/components/Header.js

import React, { useState, useEffect, useContext } from 'react';
import {
  FaHome,
  FaUserCircle,
  FaCog,
  FaSignOutAlt,
  FaBars,
  FaCaretDown,
  FaPuzzlePiece,
  FaCommentDots, // Icon for Feedback
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../CSS/Header.css';
import { AuthContext } from '../context/AuthContext';
import { getUserDetails, submitFeedback } from '../services/UserService';
import logoImage from '../images/logo.png';
import { Badge } from '@chakra-ui/react'; // Add Badge import

// Import Chakra UI components
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  useToast,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  HStack,
  Box,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Header = () => {
  const navigate = useNavigate();
  const { auth, logout } = useContext(AuthContext);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const fetchUserName = async () => {
      if (auth?.isAuthenticated && auth?.userId && auth?.token) {
        try {
          const userData = await getUserDetails(auth.userId, auth.token);
          setUserName(userData?.name || 'User');
        } catch (error) {
          console.error('Error fetching user details:', error);
          setUserName('User');
        }
      }
    };

    fetchUserName();
  }, [auth]);

  // Feedback Modal Control
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef();
  const toast = useToast();

  // Feedback Form State
  const [feedback, setFeedback] = useState({
    message: '',
    honeypot: '', // Honeypot field for spam prevention
  });

  const [isSubmitting, setIsSubmitting] = useState(false); // To manage loading state

  // Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedback((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check honeypot field
    if (feedback.honeypot) {
      console.warn('Spam submission detected.');
      return;
    }

    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');

    if (!token) {
      toast({
        title: 'Authentication Required.',
        description: 'Please sign in to submit feedback.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Validate the feedback message
    if (!feedback.message || feedback.message.trim().length === 0) {
      toast({
        title: 'Invalid Feedback.',
        description: 'Please enter a valid feedback message.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true); // Start loading

    try {
      // Call the submitFeedback service
      await submitFeedback(feedback.message, token);

      // Handle successful submission
      toast({
        title: 'Feedback Submitted.',
        description: 'Thank you for your feedback!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Reset the form
      setFeedback({
        message: '',
        honeypot: '',
      });

      onClose(); // Close the modal
    } catch (error) {
      // Handle errors during submission
      toast({
        title: 'Submission Failed.',
        description:
          error.message ||
          'There was an issue submitting your feedback. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false); // End loading
    }
  };

  return (
    <header className="header">
    <div className="logo-container" onClick={() => navigate('/')}>
  <img src={logoImage} alt="Logo" className="logo-image" />
  <Badge colorScheme="red" ml={2}>Alpha</Badge> {/* Add Alpha label next to the logo */}
</div>
      <HStack spacing={4} alignItems="center">
        {/* Additional navigation items can be added here */}
      </HStack>
      <Box ml="auto">
        {/* User Menu */}
        <Menu>
          <MenuButton
            as={Button}
            variant="ghost"
            rightIcon={<ChevronDownIcon />}
            leftIcon={<Avatar size="sm" name={userName} />}
            _hover={{ bg: 'gray.100' }}
            _active={{ bg: 'gray.200' }}
          >
            {userName}
          </MenuButton>
          <MenuList>
            <MenuItem icon={<FaUserCircle />} onClick={() => navigate('/profile')}>
              Profile
            </MenuItem>
            <MenuDivider />
            <MenuItem icon={<FaCommentDots />} onClick={onOpen}>
              Feedback
            </MenuItem>
            <MenuItem icon={<FaSignOutAlt />} onClick={logout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>

      {/* Feedback Modal */}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRef}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Feedback</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={handleSubmit}>
            <ModalBody pb={6}>
              {/* Feedback Message */}
              <FormControl id="message" isRequired>
                <FormLabel>Feedback</FormLabel>
                <Textarea
                  placeholder="Your feedback..."
                  name="message"
                  value={feedback.message}
                  onChange={handleChange}
                  ref={initialRef}
                />
              </FormControl>

              {/* Honeypot Field (Invisible to users) */}
              <FormControl style={{ display: 'none' }}>
                <FormLabel>Leave this field empty</FormLabel>
                <Input
                  type="text"
                  name="honeypot"
                  value={feedback.honeypot}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                mr={3}
                type="submit"
                isLoading={isSubmitting} // Loading state
                loadingText="Submitting"
              >
                Submit
              </Button>
              <Button onClick={onClose} variant="ghost">
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </header>
  );
};

export default Header;
