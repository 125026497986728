// StickyNote.js
import React, { useState, useRef } from 'react';
import {
  Box,
  Textarea,
  IconButton,
  VStack,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';
import { SketchPicker } from 'react-color';
import Draggable from 'react-draggable';

// Helper function to truncate text by words
const truncateText = (text, wordLimit) => {
  const words = text.split(' ');
  if (words.length <= wordLimit) {
    return text;
  }
  return words.slice(0, wordLimit).join(' ') + '...';
};

const StickyNote = ({ note, onUpdate, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [content, setContent] = useState(note.content || '');
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const [selectedColor, setSelectedColor] = useState(note.color || '#FFEB3B');

  const nodeRef = useRef(null); // Reference for Draggable

  const handleSave = () => {
    onUpdate(note.uniqueId, content, selectedColor);
    setIsEditing(false);
    setIsColorPickerVisible(false);
  };

  const handleCancel = () => {
    setContent(note.content || '');
    setSelectedColor(note.color || '#FFEB3B');
    setIsEditing(false);
    setIsColorPickerVisible(false);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    onUpdate(note.uniqueId, content, color.hex);
  };

  const handleDrag = (e, data) => {
    onUpdate(note.uniqueId, content, selectedColor, {
      left: data.x,
      top: data.y,
    });
  };

  const handleStart = () => {
    // Optional: Add any logic needed when drag starts
  };

  const handleStop = (e, data) => {
    onUpdate(note.uniqueId, content, selectedColor, {
      left: data.x,
      top: data.y,
    });
  };

  return (
    <Draggable
      nodeRef={nodeRef}
      onStart={handleStart}
      onDrag={handleDrag}
      onStop={handleStop}
      defaultPosition={{ x: note.left, y: note.top }}
      cancel="textarea, .color-picker" // Adjusted cancel prop
      enableUserSelectHack={false} // Optional: Prevent interference with text input
    >
      <Box
        ref={nodeRef}
        className="sticky-note"
        position="absolute"
        left={note.left}
        top={note.top}
        p={1}
        borderRadius="md"
        boxShadow="lg"
        minWidth="120px"
        maxWidth="200px"
        cursor="move"
        zIndex={note.isSelected ? 100 : 1}
        bg={selectedColor}
      >
        {/* Header */}
        <HStack
          className="sticky-note-header"
          justifyContent="space-between"
          mb={1}
          cursor="grab"
        >
          <Box fontWeight="bold" fontSize="xs">
            Sticky Note
          </Box>
          <IconButton
            icon={<CloseIcon />}
            size="xs"
            variant="ghost"
            onClick={() => onDelete(note.uniqueId)}
            aria-label="Delete Sticky Note"
          />
        </HStack>

        {/* Content */}
        {!isEditing ? (
          <Tooltip
            label={content}
            isDisabled={content.split(' ').length <= 10}
            placement="top"
          >
            <Box
              whiteSpace="pre-wrap"
              onDoubleClick={() => setIsEditing(true)}
              onClick={() => setIsEditing(true)}
              cursor="text"
              className="sticky-note-content"
              fontSize="sm"
            >
              {truncateText(content || 'Double-click to add content...', 10)}
            </Box>
          </Tooltip>
        ) : (
          <VStack spacing={2} align="stretch">
            <Textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="Type your note here..."
              size="sm"
              resize="vertical"
              fontSize="sm"
              // Remove className if it's not effective
              // className="sticky-note-textarea"
            />
            {/* Color Picker Toggle Button */}
            <IconButton
              icon={
                <Box
                  w="16px"
                  h="16px"
                  bg={selectedColor}
                  borderRadius="full"
                />
              }
              size="sm"
              variant="outline"
              aria-label="Change Color"
              onClick={() =>
                setIsColorPickerVisible(!isColorPickerVisible)
              }
            />

            {/* Color Picker */}
            {isColorPickerVisible && (
              <Box mt={2} className="color-picker">
                <SketchPicker
                  color={selectedColor}
                  onChangeComplete={handleColorChange}
                />
              </Box>
            )}

            {/* Action Buttons */}
            <HStack justifyContent="flex-end">
              <IconButton
                icon={<CloseIcon />}
                size="sm"
                variant="outline"
                onClick={handleCancel}
                aria-label="Cancel Editing"
              />
              <IconButton
                icon={<CheckIcon />}
                size="sm"
                colorScheme="blue"
                onClick={handleSave}
                aria-label="Save Note"
              />
            </HStack>
          </VStack>
        )}
      </Box>
    </Draggable>
  );
};

export default StickyNote;
