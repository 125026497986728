import React from 'react';
import '../CSS/PrivacyPolicyPage.css'; // Import corresponding CSS for styling

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy for Plantegrate</h1>
      <p><strong>Effective Date:</strong> October 29, 2024</p>
      <p><strong>Last Updated:</strong> November 21, 2024</p>

      <p>
        Welcome to Plantegrate (“we,” “our,” “us”). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website <a href="https://www.plantegrate.co/" target="_blank" rel="noopener noreferrer">https://www.plantegrate.co/</a> (“Site”) and use our services.
      </p>

      <p>
        By accessing or using our Site and services, you agree to the terms of this Privacy Policy. If you do not agree with our policies and practices, please do not use our Site.
      </p>

      <h2>1. Information We Collect</h2>

      <h3>Personal Information</h3>
      <p>When you sign up for Plantegrate, we may collect the following personal information:</p>
      <ul>
        <li><strong>Contact Information:</strong> Name, email address, phone number.</li>
        <li><strong>Account Credentials:</strong> Username and password.</li>
        <li><strong>Business Information:</strong> Company name, job title, industry, and business address.</li>
      </ul>

      <h3>Non-Personal Information</h3>
      <p>We may also collect non-personal information such as:</p>
      <ul>
        <li><strong>Usage Data:</strong> Information about how you use our Site and services.</li>
        <li><strong>Device Information:</strong> IP address, browser type, operating system, and device identifiers.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> To enhance your user experience and analyze site traffic.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <ul>
        <li><strong>Account Management:</strong> To create and manage your account, provide customer support, and communicate with you.</li>
        <li><strong>Service Delivery:</strong> To provide, maintain, and improve our data integration planning tools and features.</li>
        <li><strong>Personalization:</strong> To customize your experience on our Site based on your preferences and usage.</li>
        <li><strong>Communication:</strong> To send you updates, newsletters, marketing materials, and important notices related to Plantegrate. You can opt out of marketing communications at any time.</li>
        <li><strong>Security:</strong> To monitor and protect the security of our services and users.</li>
        <li><strong>Analytics:</strong> To understand how users interact with our Site and services, enabling us to improve functionality and performance.</li>
      </ul>

      <h2>3. Cookies and Tracking Technologies</h2>
      <p>
        Plantegrate uses cookies and similar tracking technologies for the following purposes:
      </p>
      <ul>
        <li>
          <strong>Google Fonts:</strong> We use Google Fonts to enhance the visual appearance of our Site. Google may collect your IP address when serving fonts, but no cookies are stored on your device for this purpose.
        </li>
        <li>
          <strong>Google reCAPTCHA:</strong> We use Google reCAPTCHA to protect our Site from spam and bots. Google reCAPTCHA may set cookies and collect information such as IP address, user behavior, and browser details to determine whether a visitor is human.
        </li>
      </ul>
      <p>
        By accepting cookies through our consent banner, you allow us to load Google reCAPTCHA. You can manage your cookie preferences through your browser settings. Declining cookies may limit certain features, such as reCAPTCHA functionality.
      </p>

      <h2>4. Information Sharing and Disclosure</h2>
      <p>We may share your information in the following circumstances:</p>
      <ul>
        <li><strong>Service Providers:</strong> With third-party vendors who perform services on our behalf, such as payment processing, data storage, and customer support.</li>
        <li><strong>Legal Requirements:</strong> If required by law, regulation, or legal process, or to protect the rights, property, and safety of Plantegrate, our users, or others.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
        <li><strong>With Your Consent:</strong> We may share your information with third parties when you have provided your explicit consent to do so.</li>
      </ul>
      <p><strong>Note:</strong> We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties without your consent, except as described above.</p>

      <h2>5. Data Security</h2>
      <p>
        We implement a variety of security measures to maintain the safety of your personal information, including:
      </p>
      <ul>
        <li><strong>Encryption:</strong> SSL/TLS encryption for data transmission.</li>
        <li><strong>Access Controls:</strong> Restricting access to personal data to authorized personnel only.</li>
        <li><strong>Regular Audits:</strong> Conducting regular security assessments and audits to identify and address vulnerabilities.</li>
      </ul>
      <p>
        Despite our efforts, no method of transmission over the internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security of your information.
      </p>

      <h2>6. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </p>
      <p>
        <strong>Email:</strong> <a href="mailto:asad.marak@gmail.com">asad.marak@gmail.com</a><br />
      </p>

      <hr />
      <p><em>Disclaimer:</em> This Privacy Policy is provided for informational purposes only and does not constitute legal advice. We recommend consulting with a legal professional to ensure that your Privacy Policy complies with all applicable laws and regulations.</p>
    </div>
  );
};

export default PrivacyPolicyPage;
