// src/components/ToolSidebar.js

import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
  Icon,
  Text,
  HStack,
  VStack,
  Tooltip,
  IconButton,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  useDisclosure,
  Collapse,
} from '@chakra-ui/react';
import { SearchIcon, ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { MdTransform, } from 'react-icons/md';
// Import toolCategories and platformIcons from the data file
import { toolCategories, platformIcons } from './toolCategories'; // Adjust the path as needed

const ToolSidebar = ({ onDragStart }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [toolboxWidth, setToolboxWidth] = useState(300);
  const [selectedPlatform, setSelectedPlatform] = useState('');
  const toolboxRef = useRef(null);

  // State to manage expanded connectors
  const [expandedConnectors, setExpandedConnectors] = useState(new Set());

  // Modal controls
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Load selectedPlatform from localStorage if exists
  useEffect(() => {
    const savedPlatform = localStorage.getItem('selectedPlatform');
    if (
      savedPlatform &&
      (savedPlatform === 'Boomi' || savedPlatform === 'MuleSoft' || savedPlatform === 'Make.com')
    ) {
      setSelectedPlatform(savedPlatform);
    } else {
      onOpen();
    }
  }, [onOpen]);

  // Function to handle iPaaS selection
  const handlePlatformSelect = (platform) => {
    setSelectedPlatform(platform);
    localStorage.setItem('selectedPlatform', platform);
    onClose();
  };

  // Function to allow user to change iPaaS selection
  const handleChangePlatform = () => {
    onOpen();
  };

  const toggleToolbox = () => {
    setIsCollapsed(!isCollapsed);
    setToolboxWidth(isCollapsed ? 300 : 60); // Toggle width based on current state
  };

  // Function to handle the dragging to resize the toolbox
  const handleMouseDown = (e) => {
    if (isCollapsed) return; // Prevent dragging when collapsed

    const startX = e.clientX;
    const startWidth = toolboxRef.current.offsetWidth;

    const onMouseMove = (e) => {
      const newWidth = startWidth + (e.clientX - startX);
      setToolboxWidth(Math.max(200, Math.min(newWidth, 500))); // Keep width within bounds
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  // Function to toggle connector expansion
  const toggleConnector = (toolId) => {
    setExpandedConnectors((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(toolId)) {
        newSet.delete(toolId);
      } else {
        newSet.add(toolId);
      }
      return newSet;
    });
  };

  // Filter tools based on search query within the selected platform
  const filteredCategories = selectedPlatform
    ? toolCategories[selectedPlatform]
        .map((subcategory) => ({
          ...subcategory,
          tools: subcategory.tools.filter((tool) =>
            tool.name.toLowerCase().includes(searchQuery.toLowerCase())
          ),
        }))
        .filter((subcategory) => subcategory.tools.length > 0)
    : [];

  // Color scheme adjustments based on color mode
  const bgColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBg = useColorModeValue('gray.300', 'gray.600');
  const activeBg = useColorModeValue('gray.400', 'gray.500');
  const toggleBg = useColorModeValue('gray.300', 'gray.600');
  const operationBg = useColorModeValue('gray.100', 'gray.600');

  return (
    <>
      {/* iPaaS Selection Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false} size="lg">
      <ModalOverlay />
      <ModalContent>
        {/* Updated Header */}
        <ModalHeader>Select the Data Integration Tool You Are Planning For</ModalHeader>
        
        {/* Optional Description */}
        <ModalBody>
          <VStack spacing={6}>
            {/* Brief Instructions */}
            <Text fontSize="md" color="gray.600">
              Please select one of the following data integration tools to proceed with your workflow.
            </Text>
            
            {/* Integration Tool Buttons */}
            <HStack spacing={1} justify="center">
              <Button
                leftIcon={<Icon as={platformIcons.Boomi} />}
                colorScheme="blue"
                variant="outline"
                onClick={() => handlePlatformSelect('Boomi')}
                height="60px"
                borderRadius="md"
                _hover={{ bg: 'blue.50' }}
                aria-label="Select Boomi Integration Tool"
              >
                Boomi
              </Button>
              
              <Button
                leftIcon={<Icon as={platformIcons.MuleSoft} />}
                colorScheme="green"
                variant="outline"
                onClick={() => handlePlatformSelect('MuleSoft')}
                height="60px"
                borderRadius="md"
                _hover={{ bg: 'green.50' }}
                aria-label="Select MuleSoft Integration Tool"
              >
                MuleSoft
              </Button>
              
              <Button
                leftIcon={<Icon as={platformIcons['Make.com']} />}
                colorScheme="purple"
                variant="outline"
                onClick={() => handlePlatformSelect('Make.com')}
                height="60px"
                borderRadius="md"
                _hover={{ bg: 'purple.50' }}
                aria-label="Select Make.com Integration Tool"
              >
                Make.com
              </Button>
            </HStack>

            {/* Disclaimer */}
            <Text fontSize="sm" color="gray.500" textAlign="center" mt={4}>
              *Please note: Selecting a tool is for planning purposes only. Our application is not affiliated with, endorsed by, or sponsored by the mentioned platforms.
            </Text>
          </VStack>
        </ModalBody>
        
        {/* Optional Footer */}
        <ModalFooter>
          {/* Cancel Button */}
          <Button variant="ghost" onClick={onClose} aria-label="Cancel Selection">
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

      {/* Sidebar */}
      <Box
        ref={toolboxRef}
        bg={bgColor}
        width={`${toolboxWidth}px`}
        height="100vh"
        position="relative"
        boxShadow="md"
        transition="width 0.3s ease"
        display="flex"
        flexDirection="column"
        overflow="hidden" // Prevent content overflow outside the sidebar
      >
        {/* Resize Handle */}
        {!isCollapsed && selectedPlatform && (
          <Box
            onMouseDown={handleMouseDown}
            position="absolute"
            top="0"
            right="0"
            width="10px" // Increased width for better drag area
            height="100%"
            cursor="ew-resize"
            zIndex="10"
            bg={toggleBg}
            _hover={{ bg: activeBg }}
          />
        )}

        {/* Toggle Button */}
        <Tooltip label={isCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'} placement="right">
          <IconButton
            onClick={toggleToolbox}
            icon={isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            size="md" // Increased size from 'sm' to 'md'
            position="absolute"
            top="50%" // Center vertically
            right={isCollapsed ? '-20px' : '-20px'} // Adjusted for larger button
            transform="translateY(-50%)"
            bg={toggleBg}
            border="1px solid"
            borderColor="gray.400"
            borderRadius="md" // Changed from 'full' to 'md' for better aesthetics
            boxShadow="sm"
            _hover={{ bg: activeBg }}
            _active={{ bg: activeBg }}
            aria-label={isCollapsed ? 'Expand Sidebar' : 'Collapse Sidebar'}
            width="40px" // Explicit width
            height="40px" // Explicit height
          />
        </Tooltip>

        {/* Scrollable Content */}
        {!isCollapsed && selectedPlatform && (
          <Box
            mt={6} // Increased top margin to prevent overlap with toggle button
            mb={6} // Increased bottom margin to prevent content from being hidden
            flex="1" // Allow this box to grow and fill available space
            overflowY="auto" // Enable vertical scrolling
            pr={4} // Increased right padding to prevent content from being hidden behind the resize handle
            pb={10} // Increased padding-bottom to ensure last items are visible
          >
            {/* Header with Platform Name and Change Option */}
            <HStack mb={4} justify="space-between">
              <HStack>
                <Icon as={platformIcons[selectedPlatform]} boxSize={6} color="blue.500" />
                <Text fontWeight="bold" fontSize="md">
                  {selectedPlatform}
                </Text>
              </HStack>
              <Button size="xs" onClick={handleChangePlatform}>
                Change
              </Button>
            </HStack>

            {/* Search Input with Icon */}
            <InputGroup mb={4}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.500" />
              </InputLeftElement>
              <Input
                placeholder="Search tools..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                size="sm"
                bg="white"
                borderRadius="md"
                borderColor="gray.300"
                _focus={{ borderColor: 'blue.400', boxShadow: 'outline' }}
              />
            </InputGroup>

            {/* Tool Categories Accordion */}
            <Accordion allowMultiple defaultIndex={[0]}>
              {filteredCategories.map((subcategory) => (
                <AccordionItem key={subcategory.category} border="none">
                  <AccordionButton
                    bg="#4A5568"
                    _hover={{ bg: "#2D3748" }}
                    borderRadius="md"
                    mb={2}
                  >
                    <Box flex="1" textAlign="left" fontWeight="bold" fontSize="sm">
                      {subcategory.category}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4} px={0}>
                    <List spacing={2}>
                      {subcategory.tools.map((tool) => (
                        <Box key={tool.id}>
                          <ListItem
                            draggable
                            onDragStart={(event) => onDragStart(event, tool)}
                            cursor="grab"
                            _active={{ cursor: 'grabbing' }}
                            _hover={{ bg: hoverBg }}
                            p={2}
                            borderRadius="md"
                            transition="background-color 0.2s"
                          >
                            <HStack spacing={3} justify="space-between">
                              <HStack spacing={3}>
                                <Icon as={tool.icon} boxSize={4} color="blue.500" />
                                <Text fontSize="sm" color="gray.700">
                                  {tool.name}
                                </Text>
                              </HStack>
                              {tool.type === 'connector' && tool.operations && (
                                <IconButton
                                  size="xs"
                                  icon={expandedConnectors.has(tool.id) ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                  onClick={() => toggleConnector(tool.id)}
                                  aria-label="Toggle Operations"
                                />
                              )}
                            </HStack>
                          </ListItem>

                          {/* Operations List */}
                          {tool.type === 'connector' && tool.operations && (
                            <Collapse in={expandedConnectors.has(tool.id)} animateOpacity>
                              <List spacing={1} pl={6} mt={1} bg={operationBg} borderRadius="md">
                                {tool.operations.map((operation) => (
                                  <ListItem
                                    key={operation.id}
                                    draggable
                                    onDragStart={(event) => onDragStart(event, operation)}
                                    cursor="grab"
                                    _active={{ cursor: 'grabbing' }}
                                    _hover={{ bg: hoverBg }}
                                    p={1}
                                    borderRadius="md"
                                    transition="background-color 0.2s"
                                  >
                                    <HStack spacing={3}>
                                      {/* You can use a different icon for operations if desired */}
                                      <Icon as={MdTransform} boxSize={3} color="green.500" />
                                      <Text fontSize="xs" color="gray.600">
                                        {operation.name}
                                      </Text>
                                    </HStack>
                                  </ListItem>
                                ))}
                              </List>
                            </Collapse>
                          )}
                        </Box>
                      ))}
                    </List>
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>

            {/* Spacer to ensure the last item is fully visible */}
            <Box height="20px" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ToolSidebar;
