// src/components/ProjectDetail.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  fetchProjectById,
  deleteProjectById,
  updateProject,
} from '../services/dashboardService';
import {
  saveCanvasState,
  createCanvas,
  deleteCanvasById,
} from '../services/canvasService';
import { FaEye, FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import '../CSS/ProjectDetail.css';
import Footer from './Footer';

const ProjectDetail = () => {
  const [project, setProject] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editProjectData, setEditProjectData] = useState({
    name: '',
    description: '',
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteCanvasId, setDeleteCanvasId] = useState(null);

  const { projectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getProjectDetails = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('User is not authenticated');
        setLoading(false);
        return;
      }

      try {
        const data = await fetchProjectById(projectId, token);
        setProject(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching project:', err);
        setError('Failed to fetch project details');
        setLoading(false);
      }
    };

    getProjectDetails();
  }, [projectId]);

  const handleCreateNewCanvas = async () => {
    if (!project) return;

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('User is not authenticated');
        return;
      }

      const userId = project.userId; // Ensure this is correctly set
      const platform = 'web'; // Define a default platform or obtain it from user input if necessary

      const newCanvas = await createCanvas(userId, projectId, platform, token); // Pass all required parameters

      // Optionally, initialize the empty canvas state
      await saveCanvasState(userId, newCanvas._id, 'Untitled Canvas', {}, token); // Pass the token

      // Navigate to the newly created canvas
      navigate(`/canvas/${userId}/${newCanvas._id}`);
    } catch (err) {
      console.error('Error creating canvas:', err);
      setError(err.message || 'Failed to create new canvas');
    }
  };

  // Handlers for Edit Project
  const openEditModal = () => {
    if (!project) return;
    setEditProjectData({
      name: project.name || '',
      description: project.description || '',
    });
    setIsEditOpen(true);
  };

  const closeEditModal = () => {
    setIsEditOpen(false);
    setEditProjectData({ name: '', description: '' });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditProjectData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSaveEdit = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    try {
      // Prepare the updated project data
      const updatedProjectData = {
        name: editProjectData.name,
        description: editProjectData.description,
      };

      // Save the updated project to the backend
      await updateProject(projectId, updatedProjectData, token);

      // Update the project state locally
      setProject((prevProject) => ({
        ...prevProject,
        name: updatedProjectData.name,
        description: updatedProjectData.description,
      }));

      // Close the edit modal after a successful update
      closeEditModal();
    } catch (err) {
      console.error('Error updating project:', err);
      alert(err.message || 'Failed to update project.');
    }
  };

  // Handlers for Delete Confirmation
  const openDeleteModal = (canvasId = null) => {
    setDeleteCanvasId(canvasId); // If canvasId is null, we're deleting the project
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setDeleteCanvasId(null);
  };

  const handleDeleteCanvas = async () => {
    const token = localStorage.getItem('token');
    if (!token || !deleteCanvasId) {
      setError('User is not authenticated or canvas ID missing');
      return;
    }

    try {
      await deleteCanvasById(deleteCanvasId, token);
      // Update the local state to remove the deleted canvas from the list
      setProject((prevProject) => ({
        ...prevProject,
        canvases: prevProject.canvases.filter((canvas) => canvas._id !== deleteCanvasId),
      }));
      // Reset the deletion states to close the confirmation dialog
      setDeleteCanvasId(null);
      setIsDeleteModalOpen(false);
    } catch (err) {
      console.error('Error deleting canvas:', err);
      setError('Failed to delete canvas');
    }
  };

  const handleDeleteProject = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('User is not authenticated');
      return;
    }

    try {
      await deleteProjectById(projectId, token);
      navigate('/dashboard');
    } catch (err) {
      console.error('Error deleting project:', err);
      setError('Failed to delete project');
    }
  };

  if (loading) {
    return (
      <div className="project-detail-container">
        <div className="spinner"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="project-detail-container">
        <div className="error-message">{error}</div>
      </div>
    );
  }

  return (
    <>
      {/* Main Content */}
      <div className="project-detail-container">
        <div className="project-header">
          <div className="project-title-actions">
            <h1>{project.name}</h1>
            <div className="project-actions">
              <button className="action-button edit-project-button" onClick={openEditModal}>
                <FaEdit /> Edit
              </button>
              <button
                className="action-button delete-project-button"
                onClick={() => openDeleteModal()}
              >
                <FaTrash /> Delete
              </button>
            </div>
          </div>
          <p className="project-description">{project.description}</p>
        </div>

        <div className="canvas-section">
          <div className="canvas-header">
            <h2>Canvases</h2>
            <button className="new-canvas-button" onClick={handleCreateNewCanvas}>
              <FaPlus /> New Canvas
            </button>
          </div>

          {project.canvases && project.canvases.length > 0 ? (
            <div className="canvas-grid">
              {project.canvases.map((canvas) => (
                <div className="canvas-card" key={canvas._id}>
                  <h3>{canvas.flowName || 'Untitled Canvas'}</h3>
                  <div className="canvas-actions">
                    <button
                      className="icon-button view"
                      onClick={() => navigate(`/canvas/${project.userId}/${canvas._id}`)}
                      aria-label="View Canvas"
                    >
                      <FaEye />
                    </button>
                    <button
                      className="icon-button edit"
                      onClick={() => navigate(`/edit-canvas/${canvas._id}`)}
                      aria-label="Edit Canvas"
                    >
                      <FaEdit />
                    </button>
                    <button
                      className="icon-button delete"
                      onClick={() => openDeleteModal(canvas._id)}
                      aria-label="Delete Canvas"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="no-canvases-message">No canvases available for this project.</p>
          )}
        </div>
      </div>

      {/* Edit Project Modal */}
      {isEditOpen && (
        <div className="modal-overlay" onClick={closeEditModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="edit-project-title"
          >
            <h2 id="edit-project-title">Edit Project</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSaveEdit();
              }}
            >
              <label>
                Project Name:
                <input
                  type="text"
                  name="name"
                  value={editProjectData.name}
                  onChange={handleEditInputChange}
                  required
                />
              </label>
              <label>
                Project Description:
                <textarea
                  name="description"
                  value={editProjectData.description}
                  onChange={handleEditInputChange}
                  required
                ></textarea>
              </label>
              <div className="modal-actions">
                <button type="button" className="cancel-button" onClick={closeEditModal}>
                  Cancel
                </button>
                <button type="submit" className="confirm-button">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <div className="modal-overlay" onClick={closeDeleteModal}>
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
            role="dialog"
            aria-modal="true"
            aria-labelledby="delete-confirmation-title"
          >
            <h2 id="delete-confirmation-title">Confirm Deletion</h2>
            <p>
              Are you sure you want to delete{' '}
              <strong>{deleteCanvasId ? 'this canvas' : 'this project'}</strong>? This action cannot
              be undone.
            </p>
            <div className="modal-actions">
              <button type="button" className="cancel-button" onClick={closeDeleteModal}>
                Cancel
              </button>
              <button
                type="button"
                className="confirm-button"
                onClick={deleteCanvasId ? handleDeleteCanvas : handleDeleteProject}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </>
  );
};

export default ProjectDetail;
