// src/context/AuthContext.js

import React, { createContext, useState, useEffect, useRef } from 'react';
import { jwtDecode } from 'jwt-decode'; // Keeping your original import
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const [auth, setAuth] = useState({
    isAuthenticated: false,
    userId: null,
    token: null,
  });

  const [loading, setLoading] = useState(true); // Added loading state
  const logoutTimer = useRef(null); // Using useRef to persist timer across re-renders

  const logout = () => {
    localStorage.removeItem('token');
    setAuth({
      isAuthenticated: false,
      userId: null,
      token: null,
    });
    navigate('/login');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId; // Ensure your token includes userId

        const currentTime = Date.now() / 1000; // Current time in seconds
        if (decodedToken.exp < currentTime) {
          logout();
        } else {
          setAuth({
            isAuthenticated: true,
            userId: userId,
            token: token,
          });

          // Set a timeout to automatically logout when token expires
          const timeout = (decodedToken.exp - currentTime) * 1000; // in milliseconds
          logoutTimer.current = setTimeout(() => {
            logout();
          }, timeout);
        }
      } catch (error) {
        console.error('Invalid token:', error);
        logout();
      }
    } else {
    }
    setLoading(false); // Authentication check is complete

    // Cleanup on unmount
    return () => {
      if (logoutTimer.current) clearTimeout(logoutTimer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const userId = decodedToken.userId; // Ensure your token includes userId
      localStorage.setItem('token', token);
      setAuth({
        isAuthenticated: true,
        userId: userId,
        token: token,
      });

      // Clear any existing logout timer
      if (logoutTimer.current) clearTimeout(logoutTimer.current);

      // Set a timeout to automatically logout when token expires
      const currentTime = Date.now() / 1000; // Current time in seconds
      const timeout = (decodedToken.exp - currentTime) * 1000; // in milliseconds
      logoutTimer.current = setTimeout(() => {
        logout();
      }, timeout);
    } catch (error) {
      console.error('Invalid token during login:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};
