// mappingService.js

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const saveMapping = async (dataToSave) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_URL}/saveMapping`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`, // Include the token for authentication
    },
    body: JSON.stringify(dataToSave),
  });
  console.log(token)
  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(`Failed to save mappings and files: ${errorText}`);
  }

  return await response.json();
};

export const loadMappingByCanvasId = async (canvasId, uniqueId) => {
  const token = localStorage.getItem('token');

  try {
    const response = await fetch(`${API_URL}/loadMappingByCanvasId/${canvasId}/${uniqueId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Include the token for authentication
      },
    });
    console.log(response)
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Failed to load mapping: ${errorText}`);
    }

    const data = await response.json();
    return data; // data now includes operations, history, and redoStack
  } catch (error) {
    console.error('Error in loadMappingByCanvasId:', error);
    throw error;
  }
};
