// src/services/canvasService.js

const API_URL = process.env.REACT_APP_API_BASE_URL;

// Fetch all canvases for a user (owned and shared)
export const fetchCanvasesByUserId = async (userId, token) => {
  const response = await fetch(`${API_URL}/canvas/${userId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Type' is not necessary for GET requests
    },
  });

  if (!response.ok) {
    let errorMessage = 'Failed to fetch canvases';
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch (error) {
      console.error('Error parsing error response:', error);
    }
    throw new Error(errorMessage);
  }

  return await response.json(); // Should be an array
};

// Fetch canvases by project ID
export const fetchCanvasesByProjectId = async (projectId, token) => {
  const response = await fetch(`${API_URL}/canvas/project/${projectId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Type' is not necessary for GET requests
    },
  });

  if (!response.ok) {
    let errorMessage = 'Failed to fetch canvases';
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch (error) {
      console.error('Error parsing error response:', error);
    }
    throw new Error(errorMessage);
  }

  return await response.json(); // Should be an array
};

// Save the state of a canvas (including sticky notes)
export const saveCanvasState = async (userId, canvasId, flowName, state, token) => {
  const dataToSave = {
    flowName,
    state: {
      ...state,
      stickyNotes: state.stickyNotes || [],
    },
  };

  const response = await fetch(`${API_URL}/canvas/${userId}/${canvasId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include token
    },
    body: JSON.stringify(dataToSave),
  });

  if (!response.ok) {
    let errorMessage = 'Failed to save canvas state';
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch (error) {
      console.error('Error parsing error response:', error);
    }
    throw new Error(errorMessage);
  }

  return await response.json();
};

// Create a new canvas
export const createCanvas = async (userId, projectId, platform, token) => {
  const response = await fetch(`${API_URL}/canvas`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`, // Include token
    },
    body: JSON.stringify({ userId, projectId, platform }),
  });

  if (!response.ok) {
    let errorMessage = 'Failed to create canvas';
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch (error) {
      console.error('Error parsing error response:', error);
    }
    throw new Error(errorMessage);
  }

  return await response.json();
};

// Load the canvas state for a specific user
export const loadCanvasState = async (userId, canvasId, token) => {
  const response = await fetch(`${API_URL}/canvas/${userId}/${canvasId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`, // Include token
      // 'Content-Type' is not necessary for GET requests
    },
  });

  if (!response.ok) {
    let errorMessage = 'Failed to load canvas state';
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch (error) {
      console.error('Error parsing error response:', error);
    }
    throw new Error(errorMessage);
  }

  return await response.json();
};

// Update the platform for a given canvas
export const updateCanvasPlatform = async (canvasId, platform, token) => {
  const response = await fetch(`${API_URL}/canvas/${canvasId}/platform`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ platform }),
  });

  if (!response.ok) {
    let errorMessage = 'Failed to update canvas platform';
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch (error) {
      console.error('Error parsing error response:', error);
    }
    throw new Error(errorMessage);
  }

  return await response.json();
};

// Delete a canvas by its ID
export const deleteCanvasById = async (canvasId, token) => {
  const response = await fetch(`${API_URL}/canvas/${canvasId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      // 'Content-Type' is not necessary for DELETE requests unless there's a body
    },
  });

  if (!response.ok) {
    let errorMessage = 'Failed to delete canvas';
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch (error) {
      console.error('Error parsing error response:', error);
    }
    throw new Error(errorMessage);
  }

  return await response.json();
};
